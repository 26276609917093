import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { CurrentUser } from '../Models/types';
import useAuthAPI from '../Data/useAuthAPI';
import { useCookies } from 'react-cookie';
import { toast } from 'react-toastify';
import { COOKIE_KEY_NAME, DEMO_COOKIE_KEY_NAME } from '../Helpers/constants';
import useUsersAPI from '../Data/useUsersAPI';

type ContextProps = {
  currentUser?: CurrentUser;
  isSettingCurrentUser: boolean;
  invalidateSession: Function;
  signIn: (email: string, password: string) => void;
  signOut: Function;
  acceptTerms: Function;
  updateAILSAPreferences: Function;
};

export const UserContext = React.createContext<ContextProps>({
  currentUser: undefined,
  isSettingCurrentUser: true,
  invalidateSession: () => {},
  signIn: () => {},
  signOut: () => {},
  acceptTerms: () => {},
  updateAILSAPreferences: () => {},
});

const UserProvider: React.FC = ({ children }) => {
  const [cookies, setCookie, removeCookie] = useCookies([COOKIE_KEY_NAME]);
  const [demoCookies, setDemoCookie] = useCookies([DEMO_COOKIE_KEY_NAME]);
  const { signinWithEmailAndPassword, acceptTermsWithUserId } = useAuthAPI();
  const { updatePreferences } = useUsersAPI();
  const history = useHistory();
  const [isSettingCurrentUser, setIsSettingCurrentUser] = useState<boolean>(true);
  const [currentUser, setCurrentUser] = useState<CurrentUser | undefined>(undefined);

  async function signInAsDemoUser() {
    const demoUser: CurrentUser = {
      email: 'demo@forestreet.com',
      id: 0,
      studyGroupId: 11,
      studyGroupName: 'Forestreet Demo',
      lastName: 'Demo',
      firstName: 'Demo',
      isNewUser: false,
      isTermsAgreed: true,
      preferences: {
        isWidgetsHidden: false,
        activeWidgets: ['FOAM_TREE', 'MARKET_REFERENCE_ARCHITECTURE', 'MAP'],
      },
    };
    setDemoCookie(DEMO_COOKIE_KEY_NAME, demoUser, { path: '/', domain: process.env.REACT_APP_DOMAIN });
  }

  async function checkForSignedInUser() {
    setIsSettingCurrentUser(true);
    // Check if there is a cookie that exists for either the demo user or a real signed in user and set the current user accordingly.
    // If the demo cookie exists and the current domain is demo.forestreet.com, then log in.
    const foundCookieUser = cookies[COOKIE_KEY_NAME] || (process.env.REACT_APP_IS_DEMO === 'true' && demoCookies[DEMO_COOKIE_KEY_NAME]);
    if (foundCookieUser) {
      const foundUser: CurrentUser = {
        id: foundCookieUser.id,
        email: foundCookieUser.email,
        firstName: foundCookieUser.firstName,
        lastName: foundCookieUser.lastName,
        isNewUser: foundCookieUser.isNewUser,
        isTermsAgreed: foundCookieUser.isTermsAgreed,
        studyGroupName: foundCookieUser.studyGroupName,
        studyGroupId: foundCookieUser.studyGroupId,
        preferences: foundCookieUser.preferences,
      };
      setCurrentUser(foundUser);
      // Check if the path is /login. If it is, and the user is logged in, redirect them to studies
      if (history.location.pathname === '/login') {
        history.push('/studies');
      }
    } else {
      setCurrentUser(undefined);
      history.push('/login');
    }
    setIsSettingCurrentUser(false);
  }

  function validateForm(email: string, password: string): boolean {
    if (!email || !password) {
      toast.error('Please enter an email and password.');
      return false;
    }
    return true;
  }

  async function signIn(email: string, password: string) {
    if (!validateForm(email, password)) return;
    try {
      const signinResult = await signinWithEmailAndPassword(email, password);
      setCookie(COOKIE_KEY_NAME, signinResult, { path: '/', domain: process.env.REACT_APP_DOMAIN });
      setCurrentUser(signinResult);
      history.push('/studies');
    } catch (e) {
      toast.error(e.message);
    }
  }

  async function acceptTerms() {
    try {
      await acceptTermsWithUserId(currentUser!.id);
      // Update the isTermsAgreed property inside of the currentUser state without having to log them out and in again
      // Also the cookie
      const currentUserCopy = currentUser;
      currentUserCopy!.isTermsAgreed = true;
      setCookie(COOKIE_KEY_NAME, currentUserCopy, { path: '/', domain: process.env.REACT_APP_DOMAIN });
      setCurrentUser(currentUserCopy);
    } catch (e) {
      console.log(e);
    }
  }

  // This function is good for forcing the user to log back in so they can get access to newer features that
  // may be given once they log in.
  async function invalidateSession() {
    await signOut();
  }

  async function updateAILSAPreferences() {
    await updatePreferences(currentUser!.id, currentUser!.preferences!);
  }

  async function signOut() {
    removeCookie(COOKIE_KEY_NAME, { path: '/', domain: process.env.REACT_APP_DOMAIN });
    toast('🔒 Securely signed out of AILSA');
    window.location.reload();
  }

  useEffect(() => {
    checkForSignedInUser();
  }, [cookies[COOKIE_KEY_NAME], demoCookies[DEMO_COOKIE_KEY_NAME]]);

  useEffect(() => {
    if (process.env.REACT_APP_IS_DEMO === 'true' && !demoCookies[DEMO_COOKIE_KEY_NAME]) {
      signInAsDemoUser();
    }
  }, []);

  return (
    <UserContext.Provider
      value={{
        currentUser,
        isSettingCurrentUser,
        invalidateSession,
        signIn,
        signOut,
        acceptTerms,
        updateAILSAPreferences,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export default UserProvider;
