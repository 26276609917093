import React from 'react';
import './competing-reference-architecture-legend.scss';

interface Props {
  primaryCompanyName?: string;
  competitorCompanyName?: string;
}

const CompetingReferenceArchitectureLegend: React.FC<Props> = ({ primaryCompanyName, competitorCompanyName }) => {
  return (
    <div className="competing-reference-architecture-legend">
      <div className="legend-item">
        <Circle color="#337cff" /> {primaryCompanyName} offers service
      </div>
      <div className="legend-item">
        <Circle color="#ffe347" /> {competitorCompanyName} offers service
      </div>
      <div className="legend-item">
        <Circle color="#808080" /> Both offer
      </div>
      <div className="legend-item">
        <Circle color="#d4d4d4" /> No company offers
      </div>
    </div>
  );
};

export default CompetingReferenceArchitectureLegend;

const Circle = ({ color }) => <div className="legend-item-circle" style={{ background: color }} />;
