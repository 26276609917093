import axios from 'axios';
import {
  StudyMetadataResult,
  NewsEventArticleResult,
  NewsEventResult,
  StudiesResult,
  UserMetadataResult,
  TagResult,
  AssignedTagResult,
  LatestCompanyCommentResult,
  CommentResult,
  FavouriteResult,
} from '../Models/dataTypes';
import { AILSAPreferences } from '../Models/types';

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT!;
const config = {
  headers: {
    'Content-Type': 'application/json',
    'x-api-key': process.env.REACT_APP_API_KEY!,
  },
};

const handlePostRequestResponse = async (url: string, data: object): Promise<any | Error> => {
  const requestResult = await (await axios.post(`${API_ENDPOINT}${url}`, data, config)).data;
  if (requestResult.error) {
    throw new Error(requestResult.error);
  } else return requestResult;
};

const handleGetRequestResponse = async (url: string): Promise<any | Error> => {
  const requestResult = await (await axios.get(`${API_ENDPOINT}${url}`, config)).data;
  if (requestResult.error) {
    throw new Error(requestResult.error);
  } else return requestResult;
};

// Authentication
export const signin = async (email: string, password: string): Promise<UserMetadataResult> => await handlePostRequestResponse('/auth/signin', { email, password });
export const acceptTermsInDB = async (userId: number): Promise<UserMetadataResult> => await handlePostRequestResponse('/auth/acceptTerms', { userId });
export const updateAILSAPreferencesInDB = async (userId: number, preferences: AILSAPreferences) => await handlePostRequestResponse('/users/updateAILSAPreferences', { userId, preferences });

// Studies
export const getExtractedStudiesFromBucket = async (studyUserGroupName: string): Promise<StudiesResult[]> =>
  await handleGetRequestResponse(`/studies/getExtractedStudies?studyUserGroupName=${encodeURIComponent(studyUserGroupName)}`);
export const getExtractedStudyFromBucket = async (studyName: string): Promise<StudyMetadataResult> =>
  handleGetRequestResponse(`/studies/getExtractedStudy?studyName=${encodeURIComponent(studyName.split('/').pop()!)}`);

// News / Events
export const getEventsFromDB = async (companyIds: string[]): Promise<NewsEventResult[]> => await (await axios.post(`${API_ENDPOINT}/news/getNews`, { companyIds: companyIds }, config)).data;
export const getArticlesFromDB = async (articleIds: number[]): Promise<NewsEventArticleResult[]> =>
  await (await axios.post(`${API_ENDPOINT}/news/getArticles`, { articleIds: articleIds }, config)).data;

// Reference Architecture
export const getReferenceArchitectureFromDB = async (companyId: string, rootReferenceArchitectureId: number) =>
  await handleGetRequestResponse(`/referenceArchitecture/getCompanyReferenceArchitecture?companyId=${companyId}&rootReferenceArchitectureId=${rootReferenceArchitectureId}`);

export const getCompetingReferenceArchitectureFromDB = async (primaryCompanyId: string, competingCompanyId: string, rootReferenceArchitectureId: number) =>
  await handleGetRequestResponse(
    `/referenceArchitecture/getCompetingReferenceArchitecture?primaryCompanyId=${primaryCompanyId}&competingCompanyId=${competingCompanyId}&rootReferenceArchitectureId=${rootReferenceArchitectureId}`
  );

// User Tags
export const createTagInDB = async (studyId: number, studyUserGroupId: number, tagLabel: string, hexColor: string) =>
  await handlePostRequestResponse('/tags/createTag', { studyId, studyUserGroupId, tagLabel, hexColor });

export const getAllTagsInDB = async (studyId: number, studyUserGroupId: number): Promise<TagResult[]> =>
  await handleGetRequestResponse(`/tags/getAllTags?studyId=${studyId}&studyUserGroupId=${studyUserGroupId}`);

export const assignTagToCompanyInDB = async (tagId: number, companyId: string) => await handlePostRequestResponse('/tags/assignTagToCompany', { tagId, companyId });
export const unassignTagFromCompanyInDB = async (tagId: number, companyId: string) => await handlePostRequestResponse('/tags/unassignTagFromCompany', { tagId, companyId });

export const getAllAssignedTagsInDB = async (studyId: number, studyUserGroupId: number): Promise<AssignedTagResult[]> =>
  await handleGetRequestResponse(`/tags/getAllAssignedTags?studyId=${studyId}&studyUserGroupId=${studyUserGroupId}`);

export const deleteTagFromDB = async (tagId: number) => await handlePostRequestResponse('/tags/deleteTag', { tagId });

// Comments
export const getLatestCommentsFromDB = async (studyId: number, studyUserGroupId: number): Promise<LatestCompanyCommentResult[]> =>
  await handleGetRequestResponse(`/comments/getLatestComments?studyId=${studyId}&studyUserGroupId=${studyUserGroupId}`);

export const getCommentsFromDB = async (studyId: number, studyUserGroupId: number, companyId: string): Promise<CommentResult[]> =>
  await handleGetRequestResponse(`/comments/getComments?studyId=${studyId}&studyUserGroupId=${studyUserGroupId}&companyId=${companyId}`);

export const createCommentInDB = async (userId: number, text: string, studyId: number, studyUserGroupId: number, companyId: string) =>
  await handlePostRequestResponse('/comments/createComment', { userId, text, studyId, studyUserGroupId, companyId });

// Favourites
export const getFavouritesFromDB = async (studyId: number, studyUserGroupId: number): Promise<FavouriteResult[]> =>
  await handleGetRequestResponse(`/favourites/getFavourites?studyId=${studyId}&studyUserGroupId=${studyUserGroupId}`);

export const createFavouriteInDB = async (userId: number, studyId: number, studyUserGroupId: number, companyId: string) =>
  await handlePostRequestResponse('/favourites/createFavourite', { userId, studyId, studyUserGroupId, companyId });

export const deleteFavouriteFromDB = async (companyId: string, studyId: number, studyUserGroupId: number) =>
  await handlePostRequestResponse('/favourites/deleteFavourite', { companyId, studyId, studyUserGroupId });
