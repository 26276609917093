import React, { useState } from 'react';
import { Button, ButtonSet, TextArea } from 'carbon-components-react';
import './new-comment-input.scss';

interface Props {
  handleSave: (text) => void;
}

const NewCommentInput: React.FC<Props> = ({ handleSave }) => {
  const [comment, setComment] = useState<string | undefined>(undefined);
  return (
    <div className="new-comment-input">
      <TextArea value={comment} onChange={(e) => setComment(e.target.value)} />
      <ButtonSet>
        <Button disabled={!comment} kind="primary" onClick={() => handleSave(comment)}>
          Save
        </Button>
        {comment && (
          <Button kind="secondary" onClick={() => setComment('')}>
            Cancel
          </Button>
        )}
      </ButtonSet>
    </div>
  );
};

export default NewCommentInput;
