import { createTagInDB, getAllTagsInDB, assignTagToCompanyInDB, unassignTagFromCompanyInDB, getAllAssignedTagsInDB } from './api';
import { AssignedTag, Tag } from '../Models/types';

const useTagsAPI = () => {
  const createNewUserTag = async (studyId: number, studyUserGroupId: number, tagLabel: string, hexColor: string) => await createTagInDB(studyId, studyUserGroupId, tagLabel, hexColor);

  const getAllTags = async (studyId: number, studyUserGroupId: number): Promise<Tag[]> => {
    try {
      const tags: Tag[] = [];
      const tagsResult = await getAllTagsInDB(studyId, studyUserGroupId);
      tagsResult.forEach((tag) => {
        const newTag: Tag = {
          id: tag.ID,
          hexColor: tag.hex_color,
          label: tag.label,
        };
        tags.push(newTag);
      });
      return tags;
    } catch (e) {
      return [];
    }
  };

  const assignTagToCompany = async (tagId: number, companyId: string) => {
    try {
      await assignTagToCompanyInDB(tagId, companyId);
    } catch (e) {
      console.log(e);
    }
  };

  const unassignTagFromCompany = async (tagId: number, companyId: string) => {
    try {
      await unassignTagFromCompanyInDB(tagId, companyId);
    } catch (e) {
      console.log(e);
    }
  };

  const getAllAssignedTags = async (studyId: number, studyUserGroupId: number): Promise<AssignedTag[]> => {
    try {
      const assignedTags: AssignedTag[] = [];
      const tagsResult = await getAllAssignedTagsInDB(studyId, studyUserGroupId);
      tagsResult.forEach((tag) => {
        const newAssignedTag: AssignedTag = {
          companyId: tag.company_ID,
          companyTagId: tag.company_tag_ID,
          hexColor: tag.hex_color,
          label: tag.label,
        };
        assignedTags.push(newAssignedTag);
      });
      return assignedTags;
    } catch (e) {
      return [];
    }
  };

  return {
    createNewUserTag,
    getAllTags,
    assignTagToCompany,
    getAllAssignedTags,
    unassignTagFromCompany,
  };
};

export default useTagsAPI;
