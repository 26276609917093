import { ReferenceArchitectureItem } from '../Components/ReferenceArchitecture/MarketReferenceArchitecture/MarketReferenceArchitectureViewItem';
import { CompanyMetadata } from '../Models/types';
import { StudyReferenceArchitecture } from '../Models/dataTypes';

export function getAggregatedRefArchData(data: CompanyMetadata[], filteredData: CompanyMetadata[], architectureView: StudyReferenceArchitecture[]): ReferenceArchitectureItem[] {
  const result = [];
  const architectureViewSumMap = new Map<number, number>();
  const architectureViewMap = new Map<number, number>();

  data.forEach((item) => {
    item.referenceArchitectureIds.forEach((item) => {
      if (architectureViewSumMap.get(item)) {
        architectureViewSumMap.set(item, (architectureViewSumMap.get(item) || 0) + 1);
      } else {
        architectureViewSumMap.set(item, 1);
      }
    });
  });

  filteredData.forEach((item) => {
    item.referenceArchitectureIds.forEach((item) => {
      if (architectureViewMap.get(item)) {
        architectureViewMap.set(item, (architectureViewMap.get(item) || 0) + 1);
      } else {
        architectureViewMap.set(item, 1);
      }
    });
  });

  architectureView.forEach((item) => {
    const newChildrenArchitectureItemArray = [];
    item.children.forEach((item) => {
      const parentItem = { ...item };
      const children = item.children;
      const newChildren = [];
      let sumCount = 0;

      children.forEach((item) => {
        const resItem = { ...item, count: architectureViewMap.get(item.id) || 0, initialCount: architectureViewSumMap.get(item.id) || 0 };
        sumCount += resItem.count;
        newChildren.push(resItem as never);
      });
      parentItem.children = newChildren;
      parentItem.count = sumCount;
      newChildrenArchitectureItemArray.push(parentItem as never);
    });

    // @ts-ignore
    result.push({ ...item, children: newChildrenArchitectureItemArray });
  });

  return result;
}
