import React from 'react';
import './app.scss';

import { ToastContainer, Slide } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import UserProvider, { UserContext } from '../../Contexts/UserProvider';
import AuthenticatedRoutes from '../../Routes/AuthenticatedRoutes';
import UnauthenticatedRoutes from '../../Routes/UnauthenticatedRoutes';
import { BrowserRouter as Router, useHistory } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import { COOKIE_KEY_NAME, DEMO_COOKIE_KEY_NAME } from '../../Helpers/constants';
import DemoRoutes from '../../Routes/DemoRoutes';

function App() {
  return (
    <Router>
      <UserProvider>{process.env.REACT_APP_IS_DEMO === 'true' ? <DemoApp /> : <MainApp />}</UserProvider>
      <ToastContainer transition={Slide} position="top-center" autoClose={5000} hideProgressBar newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
    </Router>
  );
}

const MainApp = () => {
  const [cookies] = useCookies([COOKIE_KEY_NAME]);
  return (
    <UserContext.Consumer>
      {({ currentUser, isSettingCurrentUser }) => <>{isSettingCurrentUser ? 'Loading...' : <>{cookies[COOKIE_KEY_NAME] && currentUser ? <AuthenticatedRoutes /> : <UnauthenticatedRoutes />}</>}</>}
    </UserContext.Consumer>
  );
};

const DemoApp = () => {
  const [demoCookies] = useCookies([DEMO_COOKIE_KEY_NAME]);
  return (
    <UserContext.Consumer>
      {({ currentUser, isSettingCurrentUser }) => <>{isSettingCurrentUser ? 'Loading...' : <>{demoCookies[DEMO_COOKIE_KEY_NAME] && currentUser && <DemoRoutes />}</>}</>}
    </UserContext.Consumer>
  );
};

export default App;
