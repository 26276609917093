import moment from 'moment';
import { getArticlesFromDB, getEventsFromDB } from './api';
import { NewsEvent, NewsEventArticle } from '../Models/types';

const useNewsAPI = () => {
  const getEventsForCompaniesWithIds = async (companyIds: string[]): Promise<NewsEvent[]> => {
    const eventsResult = await getEventsFromDB(companyIds);
    const newsEvents: NewsEvent[] = [];

    eventsResult.forEach((newsEventResult) => {
      const newNewsEvent: NewsEvent = {
        articles: [], //The actual article objects after returning them from the API
        articleIds: newsEventResult.articles.split(',').map((articleId) => parseInt(articleId)), //The articleIds
        category: newsEventResult.category ? newsEventResult.category : undefined,
        companies: newsEventResult.company_list.split(','),
        countries: newsEventResult.countries ? newsEventResult.countries.split(',') : [],
        description: newsEventResult.event_description,
        endDate: moment(newsEventResult.event_end_date).toString(),
        id: newsEventResult.ID,
        impact: newsEventResult.event_impact,
        source: 'forestreet',
        starRating: newsEventResult.event_star_rating,
        startDate: moment(newsEventResult.event_start_date).toString(),
        title: newsEventResult.event_name,
      };
      newsEvents.push(newNewsEvent);
    });

    return newsEvents;
  };

  const getArticlesWithArticleIds = async (articleIds: number[]): Promise<NewsEventArticle[]> => {
    try {
      const articlesResult = await getArticlesFromDB(articleIds);
      const articles: NewsEventArticle[] = [];
      if (articlesResult.length > 0) {
        for (const article of articlesResult) {
          const newArticle: NewsEventArticle = {
            datePublished: moment(article.article_published_date).toString(),
            id: article.ID,
            language: article.article_language,
            publication: article.article_publisher ? article.article_publisher : 'N/A',
            source: 'forestreet',
            summary: article.article_summary_english,
            title: article.article_title,
            url: article.article_url,
          };
          articles.push(newArticle);
        }
      }
      return articles;
    } catch (e) {
      return [];
    }
  };

  return {
    getEventsForCompaniesWithIds,
    getArticlesWithArticleIds,
  };
};

export default useNewsAPI;
