import React, { useRef } from 'react';
import { Stage, Layer, Text, Circle } from 'react-konva';
import { getDomain } from '../../../Helpers/legend.helper';
import { LEGEND_CIRCLE_COLOR } from '../../../Constants/color.constants';
import { StudyFilterId } from '../../../Models/types';

const LegendSize = ({ code, data, filteredData, sizeFunc }) => {
  const textRef = useRef(null);

  if (!code || data.length === 0 || !data) return null;

  const domain = getDomain(code.attr, filteredData);
  const count = domain ? domain.length : 0;
  if (count === 0) return null;
  const maxRadius = domain && sizeFunc(domain[count - 1]) + 1;
  const maxDiameter = maxRadius * 2;
  const width = code.attr === StudyFilterId.EMPLOYEES ? 90 + maxDiameter : 30 + maxDiameter;

  return (
    <Stage width={width} height={maxDiameter * count} style={{ position: 'relative' }}>
      <Layer>
        {domain &&
          (domain as Array<string | number>).map((d, i) => (
            <Circle strokeWidth={0.5} radius={sizeFunc(d)} x={maxRadius} y={i * maxDiameter + maxRadius} stroke={LEGEND_CIRCLE_COLOR} key={i + '_circle_size'} />
          ))}
        {domain &&
          (domain as Array<string | number>).map((d, i) => (
            <Text ref={textRef} text={domain[i]} x={0} y={i * maxDiameter + maxRadius - 3.5} align={'right'} width={width - 5} key={i + '_text_size'} />
          ))}
      </Layer>
    </Stage>
  );
};
export default LegendSize;
