import React, { useContext, useState } from 'react';
import './market-explorer-view.scss';

import { Breadcrumb, BreadcrumbItem, Button, ContentSwitcher, Switch } from 'carbon-components-react';
import FilterBar from '../../Components/FilterBar/FilterBar';
import TableMarketExplorer from '../../Components/Tables/TableMarketExplorer';
import StudyProvider, { StudyContext } from '../../Contexts/StudyProvider';
import MarketExplorerEvents from '../../Components/MarketExplorerEvents/MarketExplorerEvents';
import BubbleChart from '../../Components/Charts/BubbleChart/BubbleChart';
import WidgetDrawer from '../../Components/Widgets/WidgetDrawer/WidgetDrawer';
import LineUpWidget from '../../Components/LineUpWidget/LineUpWidget';
import FilterProvider, { FilterContext } from '../../Contexts/FilterProvider';
import { Link } from 'react-router-dom';
import LoadingSkeletonView from '../../Components/LoadingSkeleton/LoadingSkeletonView';
import useExportData from '../../Hooks/useExportData';
import TagsProvider from '../../Contexts/TagsProvider';
import { UserContext } from '../../Contexts/UserProvider';
import CommentsProvider, { CommentsContext } from '../../Contexts/CommentsProvider';
import CompanyCommentsModal from '../../Components/Comments/CompanyCommentsModal/CompanyCommentsModal';
import useAnalytics, { ANALYTICS_ACTION, ANALYTICS_CATEGORY } from '../../Hooks/useAnalytics';
import usePreferences, { PREFERENCE_KEY } from '../../Hooks/usePreferences';

const MarketExplorerView = () => {
  return (
    <StudyProvider>
      <StudyContext.Consumer>
        {({ isLoading }) => (
          <>
            {isLoading ? (
              <div className="bx--grid bx--grid--full-width no-gutter main-view-layout">
                <LoadingSkeletonView />
              </div>
            ) : (
              <LoadedView />
            )}
          </>
        )}
      </StudyContext.Consumer>
    </StudyProvider>
  );
};

export default MarketExplorerView;

const LoadedView = () => {
  const { selectedStudy, filteredStudyCompanies } = useContext(StudyContext);
  const { preferences, setPreference } = usePreferences();
  const [currentView, setCurrentView] = useState('table');
  const { exportAsCSV } = useExportData(selectedStudy?.name, filteredStudyCompanies);
  const { currentUser } = useContext(UserContext);
  const { trackEvent } = useAnalytics();

  return (
    <StudyContext.Consumer>
      {({ isLoading, selectedStudy, filteredStudyCompanies, updateCompaniesWithFilteredCompanies, studyCompanies }) => (
        <FilterProvider studyCompanies={studyCompanies} updateCompaniesWithFilteredCompanies={updateCompaniesWithFilteredCompanies}>
          <TagsProvider studyId={selectedStudy?.id!} studyUserGroupId={currentUser?.studyGroupId!}>
            <CommentsProvider studyId={selectedStudy?.id!} studyUserGroupId={currentUser?.studyGroupId!}>
              <FilterContext.Consumer>
                {({ resetAllFilters, numberOfFiltersActive }) => (
                  <>
                    <div className="bx--grid bx--grid--full-width no-gutter main-view-layout">
                      <div className="market-explorer-view__navigation-bar">
                        <div className="bx--row">
                          <Breadcrumb noTrailingSlash iscurrentpage="true">
                            <BreadcrumbItem>
                              <Link
                                to={{
                                  pathname: `/studies`,
                                }}
                              >
                                Studies
                              </Link>
                            </BreadcrumbItem>
                            <BreadcrumbItem>
                              <>{selectedStudy?.name}</>
                            </BreadcrumbItem>
                          </Breadcrumb>
                          <div>
                            {filteredStudyCompanies.length} out of {studyCompanies.length}
                          </div>
                          <div>
                            <Button onClick={exportAsCSV} size="sm" kind="ghost" disabled={filteredStudyCompanies.length === 0}>
                              Export to CSV
                            </Button>
                            <Button onClick={resetAllFilters} size="sm" kind={numberOfFiltersActive > 0 ? 'danger' : 'ghost'} disabled={numberOfFiltersActive === 0}>
                              Reset Filters ({numberOfFiltersActive})
                            </Button>
                            <Button
                              onClick={() => {
                                setPreference(PREFERENCE_KEY.isWidgetsHidden, !preferences?.isWidgetsHidden);
                                trackEvent(ANALYTICS_CATEGORY.STUDY, `${!preferences?.isWidgetsHidden ? 'Show' : 'Hide'} Widgets`, ANALYTICS_ACTION.CLICKED);
                              }}
                              size="sm"
                              kind="ghost"
                            >
                              {preferences?.isWidgetsHidden ? 'Show' : 'Hide'} Widgets
                            </Button>
                          </div>
                        </div>
                      </div>
                      <div className="bx--row margin-top-large">
                        <div className="bx--col-lg-16">
                          <div className="bx--row">
                            <div className="bx--col-lg-3 no-gutter-right">
                              <div className="filter-bar-wrapper">
                                <FilterBar isLoadingData={isLoading} dataToFilter={studyCompanies} filteredCompanies={filteredStudyCompanies} />
                              </div>
                            </div>
                            <div className="bx--col-lg-13" style={{ display: 'flex' }}>
                              <div
                                className="content panel"
                                style={{
                                  overflowY: process.env.REACT_APP_IS_DEMO === 'true' ? 'hidden' : 'auto',
                                }}
                              >
                                <div
                                  style={{
                                    width: '100%',
                                  }}
                                >
                                  <ContentSwitcher
                                    onChange={(e) => {
                                      trackEvent(ANALYTICS_CATEGORY.STUDY, `Changing view: ${e.name}`, ANALYTICS_ACTION.CLICKED);
                                      setCurrentView(e.name);
                                    }}
                                  >
                                    <Switch name="table" text="Table" />
                                    <Switch name="lineup" text="Advanced Table" />
                                    <Switch name="bubble" text="Bubbles" />
                                    <Switch name="events" text="News Events" disabled={process.env.REACT_APP_IS_DEMO === 'true'} />
                                  </ContentSwitcher>
                                </div>
                                <div className="market-explorer-view">
                                  <div className="market-explorer-view__tab-content">
                                    <div className="market-explorer-view__main-tab-content">
                                      {currentView === 'table' && <TableMarketExplorer data={filteredStudyCompanies} />}
                                      {currentView === 'lineup' && <LineUpWidget rows={filteredStudyCompanies} />}
                                      {currentView === 'bubble' && <BubbleChart filteredData={filteredStudyCompanies} data={filteredStudyCompanies} imagesChecked={true} />}
                                      {currentView === 'events' && <MarketExplorerEvents data={filteredStudyCompanies} />}
                                    </div>
                                  </div>
                                </div>
                              </div>
                              {!preferences?.isWidgetsHidden && <WidgetDrawer isLoading={isLoading} />}
                              <CommentsContext.Consumer>
                                {({ isCompanyCommentsModalOpenWithCompanyId, hideCompanyCommentsModal }) => (
                                  <CompanyCommentsModal isOpenWithCompanyId={isCompanyCommentsModalOpenWithCompanyId} handleClose={hideCompanyCommentsModal} />
                                )}
                              </CommentsContext.Consumer>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </FilterContext.Consumer>
            </CommentsProvider>
          </TagsProvider>
        </FilterProvider>
      )}
    </StudyContext.Consumer>
  );
};
