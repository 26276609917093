import { IGroupCellRenderer, IOrderedGroup, rendererClasses, LinkColumn, IRenderContext, IDataRow, ILink, ICellRendererFactory, Column, ERenderMode } from 'lineupjs';
import type { ISequence } from 'lineupjs/build/src/internal';

export default class CustomImageCellRenderer implements ICellRendererFactory {
  readonly title: string = 'Image';

  canRender(col: Column, mode: ERenderMode): boolean {
    return col instanceof LinkColumn && mode === ERenderMode.CELL;
  }

  create(col: LinkColumn) {
    return rendererClasses.ImageCellRenderer.prototype.create.call(this, col);
  }

  private static sampleGroup(col: LinkColumn, rows: ISequence<IDataRow>) {
    const numExampleRows = 6;
    const examples = [] as ILink[];
    rows.every((row) => {
      const v = col.getLink(row);
      if (!v) {
        return true;
      }
      examples.push(v);
      return examples.length < numExampleRows;
    });
    return examples;
  }

  createGroup(col: LinkColumn, context: IRenderContext): IGroupCellRenderer {
    return {
      template: `<div></div>`,
      update: (n: HTMLElement, group: IOrderedGroup) => {
        n.classList.add('lu-renderer-images');
        return context.tasks
          .groupExampleRows(col, group, 'link', (rows) => CustomImageCellRenderer.sampleGroup(col, rows))
          .then((samples) => {
            if (typeof samples === 'symbol') {
              return;
            }
            n.dataset.count = samples.length.toString();
            n.classList.toggle('lu-missing', samples.length === 0);
            n.innerText = '';
            for (const sample of samples) {
              const s = n.ownerDocument.createElement('div');
              s.title = sample.alt;
              s.classList.add('lu-renderer-image');
              s.style.backgroundImage = `url('${sample.href}')`;
              n.appendChild(s);
            }
          });
      },
    };
  }

  createSummary() {
    return rendererClasses.ImageCellRenderer.prototype.createSummary.call(this);
  }
}
