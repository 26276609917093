import React from 'react';
import { SkeletonPlaceholder } from 'carbon-components-react';

const LoadingSkeletonView = () => {
  return (
    <div className="bx--row">
      <div className="bx--col-lg-16">
        <SkeletonPlaceholder style={{ width: '100%' }} />
        <SkeletonPlaceholder style={{ width: '80%', marginTop: '20px' }} />
      </div>
    </div>
  );
};

export default LoadingSkeletonView;
