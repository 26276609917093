import React from 'react';
import { Stage, Layer, Text, Rect } from 'react-konva';
import { getColorFunction, getDomain } from '../../../Helpers/legend.helper';
import { StudyFilterId } from '../../../Models/types';

const LegendColor = ({ code, data, filteredData }) => {
  if (!code || data.length == 0 || !data) return null;

  const domain = getDomain(code.attr, filteredData);
  if (domain?.length === 0) return null;
  const colorFunc = getColorFunction(code.attr, filteredData);
  const lineHeight = 16;
  const width = code.attr === StudyFilterId.EMPLOYEES ? 90 : 50;
  const height = domain ? domain.length * lineHeight + 2 : 0;

  return (
    <Stage width={width} height={height} style={{ position: 'relative' }}>
      <Layer>
        {domain && (domain as Array<string | number>).map((d, i) => <Rect width={width} height={lineHeight} x={0} y={i * lineHeight} fill={colorFunc(d)} key={i + '_rect_color'} />)}
        {domain && (domain as Array<string | number>).map((d, i) => <Text text={domain[i]} x={0} y={i * lineHeight + 3.5} align={'right'} width={width - 10} key={i + '_text_color'} />)}
      </Layer>
    </Stage>
  );
};
export default LegendColor;
