import { json2csv } from 'json-2-csv';
import FileSaver from 'file-saver';
import { toast } from 'react-toastify';
import moment from 'moment';
import { CompanyMetadata } from '../Models/types';
import useAnalytics, { ANALYTICS_ACTION, ANALYTICS_CATEGORY } from './useAnalytics';

const useExportData = (studyName?: string, filteredStudyCompanies?: CompanyMetadata[]) => {
  const { trackEvent } = useAnalytics();
  const generateFileName = () => `${studyName?.replace(/[^a-z0-9]/gi, '-').toLowerCase()}-${filteredStudyCompanies?.length}-companies-${moment().format('DD-MM-YYYY')}.csv`;

  function exportAsCSV() {
    trackEvent(ANALYTICS_CATEGORY.STUDY, 'Export to CSV', ANALYTICS_ACTION.CLICKED, filteredStudyCompanies?.length);
    // First check if you are in demo mode. If so, show an alert.
    if (process.env.REACT_APP_IS_DEMO === 'true') {
      toast.dark('⚠️ CSV export is disabled in demo mode.');
      return;
    }
    const options = {
      delimiter: {
        wrap: '', // Double Quote (") character
        field: ',', // Comma field delimiter
        eol: '\n', // Newline delimiter
      },
      prependHeader: true,
      sortHeader: false,
      excelBOM: true,
      trimHeaderValues: true,
      trimFieldValues: true,
      keys: [
        {
          field: 'name',
          title: 'Name',
        },
        {
          field: 'id',
          title: 'Website',
        },
        {
          field: 'description',
          title: 'Description',
        },
        {
          field: 'userTags',
          title: 'User Tags',
        },
        {
          field: 'hqCountry',
          title: 'HQ Located',
        },
        {
          field: 'foundedYear',
          title: 'Founded Year',
        },
        {
          field: 'companySize',
          title: 'Size',
        },
        {
          field: 'revenue',
          title: 'Revenue',
        },
        {
          field: 'IPODate',
          title: 'IPO Date',
        },
        {
          field: 'totalFundingAmount',
          title: 'Total Funding Amount',
        },
        {
          field: 'fundingRounds',
          title: 'Funding Rounds',
        },
        {
          field: 'overallMomentum',
          title: 'Overall Momentum',
        },
        {
          field: 'ngrams',
          title: 'N-Grams',
        },
        {
          field: 'tags',
          title: 'Tags',
        },
        {
          field: 'cluster',
          title: 'Cluster',
        },
      ],
    };

    // Please Read! I don't know why but this library won't detect a field name of 'size'.
    // Therefore, I'm manually remapping the company size key to be companySize.
    // I lost about an hour on a Wednesday to that sh*te.

    json2csv(
      filteredStudyCompanies!.map((company) => {
        return {
          ...company,
          companySize: company.size,
          description: company.description.replace(/(\r\n|\n|\r)/gm, ''),
          userTags: company.userTags.map((userTag) => userTag.label).toString(),
          tags: company.tags.toString(),
          ngrams: company.ngrams.toString(),
        };
      }),
      (err, csv) => {
        if (err) {
          console.log(err);
        }
        const csvData = new Blob([csv!], { type: 'text/csv;charset=utf-8;' });
        FileSaver.saveAs(csvData, generateFileName());
        toast.success(`📁 Exported and downloaded list as ${generateFileName()}`);
      },
      options
    );
  }

  return {
    exportAsCSV,
  };
};

export default useExportData;
