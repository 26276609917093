import React from 'react';
import Hyphenated from 'react-hyphen';
import './company-reference-architecture.scss';

interface Props {
  data?: object;
}

const CompanyReferenceArchitectureWrapper: React.FC<Props> = ({ data }) => {
  // @ts-ignore
  return data ? <CompanyReferenceArchitecture referenceArchitectureChildren={data.children} /> : <p>Services not available.</p>;
};

export default CompanyReferenceArchitectureWrapper;

const CompanyReferenceArchitecture = ({ referenceArchitectureChildren }) => {
  return (
    <div className="ux-architecture-view__item-container">
      {referenceArchitectureChildren.map((item, index) => (
        <CompanyReferenceArchitectureViewSection {...item} key={index} />
      ))}
    </div>
  );
};

const CompanyReferenceArchitectureViewSection = ({ name, children }) => (
  <div className="ux-architecture-view__item">
    <div className="ux-architecture-view__item-title-wrapper">
      <div className="ux-architecture-view__item-title">
        <Hyphenated>{name}</Hyphenated>
      </div>
    </div>
    <table className="ux-architecture-view__row-container">
      <tbody>
        {children.map((item, index) => (
          <tr className="ux-architecture-view__cell-container" key={index}>
            <td className="ux-architecture-view__cell _head">
              <Hyphenated>{item.name}</Hyphenated>
            </td>
            {item.children.map((item, index) => (
              <CompanyReferenceArchitectureViewItem index={index} item={item} />
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  </div>
);

const CompanyReferenceArchitectureViewItem = ({ index, item }) => {
  return (
    <td key={index} className={'ux-architecture-view__cell'} style={{ background: item.color }}>
      <Hyphenated>{item.name}</Hyphenated>
    </td>
  );
};
