import { AvailableFilter } from '../../Models/types';

const useFilterSorting = () => {
  function sortRevenueFilters(availableRevenueFilters: AvailableFilter[] | undefined) {
    if (!availableRevenueFilters) {
      return [];
    }
    const sortedAvailableFilters = [...availableRevenueFilters];
    sortedAvailableFilters.forEach((revenueFilter) => {
      if (revenueFilter.label === '$0-$1M') {
        revenueFilter['order'] = 1;
      }
      if (revenueFilter.label === '$0-$1M') {
        revenueFilter['order'] = 2;
      }
      if (revenueFilter.label === '$1M-$10M') {
        revenueFilter['order'] = 3;
      }
      if (revenueFilter.label === '$10M-$50M') {
        revenueFilter['order'] = 4;
      }
      if (revenueFilter.label === '$50M-$100M') {
        revenueFilter['order'] = 5;
      }
      if (revenueFilter.label === '$100M-$250M') {
        revenueFilter['order'] = 6;
      }
      if (revenueFilter.label === '$1B-$10B') {
        revenueFilter['order'] = 7;
      }
      if (revenueFilter.label === '$10B+') {
        revenueFilter['order'] = 8;
      }
      if (revenueFilter.label === 'N/A') {
        revenueFilter['order'] = 9;
      }
    });
    return sortedAvailableFilters;
  }

  function sortSizeFilters(availableSizeFilters: AvailableFilter[] | undefined) {
    if (!availableSizeFilters) {
      return [];
    }
    const sortedAvailableFilters = [...availableSizeFilters];
    sortedAvailableFilters.forEach((sizeFilter) => {
      if (sizeFilter.label === '1-10') {
        sizeFilter['order'] = 1;
      }
      if (sizeFilter.label === '10-50') {
        sizeFilter['order'] = 2;
      }
      if (sizeFilter.label === '50-250') {
        sizeFilter['order'] = 3;
      }
      if (sizeFilter.label === '250-1,000') {
        sizeFilter['order'] = 4;
      }
      if (sizeFilter.label === '1,000-10,000') {
        sizeFilter['order'] = 5;
      }
      if (sizeFilter.label === '10,000+') {
        sizeFilter['order'] = 6;
      }
      if (sizeFilter.label === 'N/A') {
        sizeFilter['order'] = 7;
      }
    });
    return sortedAvailableFilters;
  }

  return {
    sortRevenueFilters,
    sortSizeFilters,
  };
};

export default useFilterSorting;
