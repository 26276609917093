import { ClusteredNGram } from './dataTypes';

export interface CurrentUser {
  id: number;
  email: string;
  firstName: string;
  lastName: string;
  studyGroupName: string;
  studyGroupId: number;
  isNewUser: boolean;
  isTermsAgreed: boolean;
  preferences: AILSAPreferences | null;
}

export interface AILSAPreferences {
  activeWidgets: string[];
  isWidgetsHidden: boolean;
}

// Describes a top-level study, inside of the StudyView
export interface Study {
  id: number;
  name: string;
  subtitle: string;
  description?: string;
  headerImageURL: string;
  latestCompanyOutput: string;
  companyCount: number;
  dateCreated: string;
}

// A detailed view of a study, e.g when you click into a study
export interface StudyDetails {
  clusteredNGrams: ClusteredNGram[];
  referenceArchitecture?: RefArchitecture;
  companies: CompanyMetadata[];
}

export interface RefArchitecture {
  id: number;
  name: string;
  children: RefArchitecture[];
}

// A detailed view of a company, e.g when you click into a company
export interface CompanyDetails {
  referenceArchitecture: {
    id: number;
    name: string;
  };
  company: CompanyMetadata;
}

export interface CompanyMetadata {
  id: string;
  description: string;
  name: string;
  foundedYear: number | string;
  logoURL: string;
  size: string;
  fundingRounds: number | string;
  totalFundingAmount: number | string;
  competitors: Competitor[];
  revenue: string;
  IPODate: string;
  hqCountry: string;
  cluster: string;
  tags: string[];
  ngrams: string[];
  referenceArchitectureIds: number[];
  overallMomentum?: number;
  userTags: Tag[];
  latestComment?: LatestCompanyComment;
  isStarredByForestreet: boolean;
  isFavourite: boolean;
  professionalEmployeeEstimate?: number;
  momentum: {
    webTraffic: number;
    mediaAnalysis: number;
    funding: number;
    revenueEstimates: number;
  };
}

export interface Competitor {
  score: number;
  id: string;
  description: string;
  website: string;
  name: string;
  foundedYear: number | string;
  logoURL: string;
  size: string;
  revenue: string;
  hqCountry: string;
  overallMomentum?: number;
  isStarred?: boolean;
}

export interface CompanySearchListing {
  id: string;
  name: string;
  description: string;
}

export interface ReferenceArchitecture {
  name: string;
  value: number;
  children: ReferenceArchitectureChild[];
}

export interface ReferenceArchitectureChild {
  name: string;
  id: number;
  color?: string;
  children: ReferenceArchitectureChild[];
}

export interface NewsEvent {
  id: number;
  startDate: string;
  endDate: string;
  impact: number;
  title: string;
  description: string;
  starRating: number;
  articleIds: number[];
  articles: NewsEventArticle[];
  companies: string[];
  countries: string[];
  category?: string;
  source: string;
}

export interface NewsEventArticle {
  id: number;
  title: string;
  summary: string;
  datePublished: string;
  language: string;
  url: string;
  publication: string;
  source: string;
}

export enum StudyFilterId {
  MOMENTUM = 'MOMENTUM',
  EMPLOYEES = 'EMPLOYEES',
  REVENUE = 'REVENUE',
  NGRAMS = 'NGRAMS',
}

export interface StudyFilterValue {
  id: StudyFilterId;
  values: string[] | number[];
}

export enum FILTER_NAME {
  USER_TAGS = 'USER_TAGS',
  TAGS = 'TAGS',
  HEADQUARTERS = 'HEADQUARTERS',
  SIZE = 'SIZE',
  REVENUE = 'REVENUE',
  CLUSTER = 'CLUSTER',
  FOUNDED_DATE = 'FOUNDED_DATE',
  MOMENTUM_SCORE = 'MOMENTUM_SCORE',
  FOAMTREE = 'FOAMTREE',
  REFERENCE_ARCHITECTURE = 'REFERENCE_ARCHITECTURE',
  FAVOURITES = 'FAVOURITES',
  EMPLOYEE_ESTIMATE = 'EMPLOYEE_ESTIMATE',
}

export enum WidgetId {
  MAP = 'MAP',
  MARKET_REFERENCE_ARCHITECTURE = 'MARKET_REFERENCE_ARCHITECTURE',
  FOAM_TREE = 'FOAM_TREE',
  SCATTER_CHART = 'SCATTER_CHART',
}

export interface ColorCodeModel {
  name: string;
  attr: StudyFilterId | null;
}

export interface SizeCodeModel {
  name: string;
  attr: StudyFilterId | null;
}

export interface AvailableCheckboxFilter {
  benchmark: number;
  allFilters: AvailableFilter[];
  currentFilterValues: AvailableFilter[];
}

export interface AvailableFilter {
  count: number;
  label: string;
  order: number;
}

export interface Tag {
  id: number;
  label: string;
  hexColor: string;
}

export interface AssignedTag {
  companyTagId: number;
  companyId: string;
  label: string;
  hexColor: string;
}

export interface LatestCompanyComment {
  companyId: string;
  text: string;
}

export interface Comment {
  id: number;
  userId: number;
  text: string;
  createdDate: string;
  userFirstName: string;
  userLastName: string;
  email: string;
}

export interface Favourite {
  id: number;
  companyId: string;
  userFirstName: string;
  userLastName: string;
  createdDate: string;
}
