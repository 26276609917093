import React, { useContext, useEffect, useState } from 'react';
import { SkeletonPlaceholder } from 'carbon-components-react';
import Slider from 'rc-slider';
import { AvailableCheckboxFilter, CompanyMetadata, FILTER_NAME } from '../../Models/types';
import FilterCheckbox from './FilterCheckbox';
import './filter-bar.scss';
import 'rc-slider/assets/index.css';
import useFilterSorting from './useFilterSorting';
import { FilterContext } from '../../Contexts/FilterProvider';
import CreateTagModal from '../Tags/CreateTagModal/CreateTagModal';
import DeleteTagModal from '../Tags/DeleteTagModal/DeleteTagModal';
import useAnalytics, { ANALYTICS_ACTION, ANALYTICS_CATEGORY } from '../../Hooks/useAnalytics';

const { createSliderWithTooltip } = Slider;
const Range = createSliderWithTooltip(Slider.Range);

interface Props {
  isLoadingData: boolean;
  dataToFilter: CompanyMetadata[];
  filteredCompanies: CompanyMetadata[];
}

const FilterBar: React.FC<Props> = ({ isLoadingData, dataToFilter, filteredCompanies }) => {
  const { trackEvent } = useAnalytics();
  const { setFilterValuesWithFilterId, getFilterValuesWithFilterId, setAvailableCountryFilters, availableCountryFilters } = useContext(FilterContext);
  const [isCreateTagModalOpen, setIsCreateTagModalOpen] = useState<boolean>(false);
  const [isDeleteTagModalOpenWithTagLabel, setIsDeleteTagModalOpenWithTagLabel] = useState<string | undefined>(undefined);
  const [availableUserTagFilters, setAvailableUserTagFilters] = useState<AvailableCheckboxFilter | undefined>(undefined);
  const [availableTagFilters, setAvailableTagFilters] = useState<AvailableCheckboxFilter | undefined>(undefined);
  const [availableSizeFilters, setAvailableSizeFilters] = useState<AvailableCheckboxFilter | undefined>(undefined);
  const [availableClusterFilters, setAvailableClusterFilters] = useState<AvailableCheckboxFilter | undefined>(undefined);
  const [availableRevenueFilters, setAvailableRevenueFilters] = useState<AvailableCheckboxFilter | undefined>(undefined);
  const [availableFavouritesFilters, setAvailableFavouritesFilters] = useState<AvailableCheckboxFilter | undefined>(undefined);
  const [isAllCountryFiltersShown, setIsAllCountryFiltersShown] = useState(false);
  const [minimumAndMaximumDateFilterValue, setMinimumAndMaximumDateFilterValue] = useState<number[]>([]);
  const [minimumAndMaximumEmployeeEstimateFilterValue, setMinimumAndEmployeeEstimateFilterValue] = useState<number[]>([]);
  const [yearFilter, setYearFilter] = useState<number[]>([minimumAndMaximumDateFilterValue[0], minimumAndMaximumDateFilterValue[1]]);
  const [momentumScoreFilter, setMomentumScoreFilter] = useState<number[]>([0, 100]);
  const [employeeEstimateFilter, setEmployeeEstimateFilter] = useState<number[]>([]);
  const { sortRevenueFilters, sortSizeFilters } = useFilterSorting();

  function constructFilter(baseFilterValues: any[], availableFilterValues: any[], stateToSet: any) {
    const calculatedFilterValues: { count: number; label: string }[] = baseFilterValues
      .reduce(
        // @ts-ignore
        (b, c) => ((b[b.findIndex((d) => d.label === c)] || b[b.push({ label: c, count: 0 }) - 1]).count++, b),
        []
      )
      .sort((a, b) => b.count - a.count);
    const calculatedAvailableFilterValues: { count: number; label: string }[] = availableFilterValues
      .reduce(
        // @ts-ignore
        (b, c) => ((b[b.findIndex((d) => d.label === c)] || b[b.push({ label: c, count: 0 }) - 1]).count++, b),
        []
      )
      .sort((a, b) => b.count - a.count);
    stateToSet({
      benchmark: Math.max(...calculatedFilterValues.map((filter) => filter.count)),
      allFilters: calculatedFilterValues,
      currentFilterValues: calculatedAvailableFilterValues,
    });
  }

  function constructAvailableFilters() {
    // For each of the filters, loop through all the values for that filter,
    // and find the unique values, also find the number for that value

    constructFilter(
      dataToFilter
        .map((company) => company.userTags)
        .flat()
        .map((tag) => tag!.label),
      filteredCompanies
        .map((company) => company.userTags)
        .flat()
        .map((tag) => tag!.label),
      setAvailableUserTagFilters
    );
    constructFilter(dataToFilter.map((company) => company.tags).flat(), filteredCompanies.map((company) => company.tags).flat(), setAvailableTagFilters);
    constructFilter(
      dataToFilter.map((company) => company.hqCountry),
      filteredCompanies.map((company) => company.hqCountry),
      setAvailableCountryFilters
    );
    constructFilter(
      dataToFilter.map((company) => company.size),
      filteredCompanies.map((company) => company.size),
      setAvailableSizeFilters
    );
    constructFilter(
      dataToFilter.map((company) => company.revenue),
      filteredCompanies.map((company) => company.revenue),
      setAvailableRevenueFilters
    );
    constructFilter(
      dataToFilter.map((company) => company.cluster),
      filteredCompanies.map((company) => company.cluster),
      setAvailableClusterFilters
    );
    // Here we combine the user favourites and Forestreet starred companies, as well as remapping them.
    constructFilter(
      [
        ...dataToFilter.map((company) => (company.isFavourite ? 'User Starred' : undefined)),
        ...dataToFilter.map((company) => (company.isStarredByForestreet ? 'Forestreet Starred' : undefined)),
      ].filter((value) => value !== undefined),
      [
        ...filteredCompanies.map((company) => (company.isFavourite ? 'User Starred' : undefined)),
        ...filteredCompanies.map((company) => (company.isStarredByForestreet ? 'Forestreet Starred' : undefined)),
      ].filter((value) => value !== undefined),
      setAvailableFavouritesFilters
    );
  }

  function changeFilters(value, id, event) {
    const filterParent = event.target.getAttribute('filterParent');
    const filterValue = event.target.getAttribute('filterValue');

    if (filterParent === 'hqCountry') {
      setFilterValuesWithFilterId(FILTER_NAME.HEADQUARTERS, [filterValue]);
    }

    if (filterParent === 'size') {
      setFilterValuesWithFilterId(FILTER_NAME.SIZE, [filterValue]);
    }

    if (filterParent === 'revenue') {
      setFilterValuesWithFilterId(FILTER_NAME.REVENUE, [filterValue]);
    }

    if (filterParent === 'cluster') {
      setFilterValuesWithFilterId(FILTER_NAME.CLUSTER, [filterValue]);
    }

    if (filterParent === 'tags') {
      setFilterValuesWithFilterId(FILTER_NAME.TAGS, [filterValue]);
    }

    if (filterParent === 'userTags') {
      setFilterValuesWithFilterId(FILTER_NAME.USER_TAGS, [filterValue]);
    }

    if (filterParent === 'favourites') {
      setFilterValuesWithFilterId(FILTER_NAME.FAVOURITES, [filterValue]);
    }
  }

  useEffect(() => {
    if (dataToFilter.length === 0 || filteredCompanies.length === 0) return;
    constructAvailableFilters();
  }, [dataToFilter, filteredCompanies]);

  // This onDidMount effect sets up the range filters
  useEffect(() => {
    const filteredDateValuesWithoutNA: CompanyMetadata[] = dataToFilter.filter((company) => company.foundedYear !== 'N/A');
    const foundedDateFilterValues: number[] = filteredDateValuesWithoutNA.map((company) => company.foundedYear).filter((value) => !Number.isNaN(value)) as number[];
    setYearFilter([Math.min(...foundedDateFilterValues), Math.max(...foundedDateFilterValues)]);
    setMinimumAndMaximumDateFilterValue([Math.min(...foundedDateFilterValues), Math.max(...foundedDateFilterValues)]);
    setFilterValuesWithFilterId(FILTER_NAME.FOUNDED_DATE, [Math.min(...foundedDateFilterValues), Math.max(...foundedDateFilterValues)]);
    // Set up employee estimate filters
    const employeeEstimateFilterValues: number[] = dataToFilter.filter((company) => company.professionalEmployeeEstimate).map((company) => company.professionalEmployeeEstimate) as number[];
    setMinimumAndEmployeeEstimateFilterValue([Math.min(...employeeEstimateFilterValues), Math.max(...employeeEstimateFilterValues)]);
    setEmployeeEstimateFilter([Math.min(...employeeEstimateFilterValues), Math.max(...employeeEstimateFilterValues)]);
  }, []);

  return (
    <>
      {isLoadingData ? (
        <>
          <div className="bx--row">
            <div className="bx--col-lg-16">
              <SkeletonPlaceholder style={{ width: '100%' }} />
            </div>
          </div>
        </>
      ) : (
        <div className="bx--row">
          <div className="bx--col-lg-16">
            <div className="filter-section">
              <fieldset className="bx--fieldset">
                <legend className="bx--label">Tags</legend>
                {availableTagFilters?.allFilters.map((tagFilter, index) => (
                  <FilterCheckbox
                    checked={getFilterValuesWithFilterId(FILTER_NAME.TAGS).includes(tagFilter.label)}
                    key={index}
                    filterParent="tags"
                    benchmark={availableTagFilters.benchmark}
                    baseFilterValue={tagFilter}
                    currentFilterValue={availableTagFilters?.currentFilterValues.find((currentFilter) => currentFilter.label === tagFilter.label)}
                    handleChange={changeFilters}
                  />
                ))}
              </fieldset>
            </div>
            <div className="filter-section">
              <fieldset className="bx--fieldset">
                <legend className="bx--label">Custom Tags</legend>
                <a style={{ fontSize: '10pt', marginBottom: '5px' }} href="#" onClick={() => setIsCreateTagModalOpen(!isCreateTagModalOpen)}>
                  Create Tag
                </a>
                {availableUserTagFilters?.allFilters.map((tagFilter, index) => (
                  <FilterCheckbox
                    checked={getFilterValuesWithFilterId(FILTER_NAME.USER_TAGS).includes(tagFilter.label)}
                    key={index}
                    filterParent="userTags"
                    benchmark={availableUserTagFilters.benchmark}
                    baseFilterValue={tagFilter}
                    currentFilterValue={availableUserTagFilters?.currentFilterValues.find((currentFilter) => currentFilter.label === tagFilter.label)}
                    handleChange={changeFilters}
                    options={[
                      {
                        label: 'Delete Tag',
                        clickHandler: (tagLabel) => setIsDeleteTagModalOpenWithTagLabel(tagLabel),
                        isDanger: true,
                      },
                    ]}
                  />
                ))}
              </fieldset>
            </div>
            <div className="filter-section">
              <fieldset className="bx--fieldset">
                <legend className="bx--label">Starred</legend>
                {availableFavouritesFilters?.allFilters.map((favouriteFilter, index) => (
                  <FilterCheckbox
                    checked={getFilterValuesWithFilterId(FILTER_NAME.FAVOURITES).includes(favouriteFilter.label)}
                    key={index}
                    filterParent="favourites"
                    benchmark={availableFavouritesFilters.benchmark}
                    baseFilterValue={favouriteFilter}
                    currentFilterValue={availableFavouritesFilters?.currentFilterValues.find((currentFilter) => currentFilter.label === favouriteFilter.label)}
                    handleChange={changeFilters}
                  />
                ))}
              </fieldset>
            </div>
            <div className="filter-section">
              <fieldset className="bx--fieldset">
                <legend className="bx--label">Headquarters</legend>
                <a style={{ fontSize: '10pt', marginBottom: '5px' }} href="#" onClick={() => setIsAllCountryFiltersShown(!isAllCountryFiltersShown)}>
                  {isAllCountryFiltersShown ? 'Hide' : 'Show'} All
                </a>
                {availableCountryFilters?.allFilters
                  .slice(0, availableCountryFilters?.allFilters.length > 10 && !isAllCountryFiltersShown ? 10 : availableCountryFilters?.allFilters.length)
                  .map((countryFilter) => (
                    <FilterCheckbox
                      checked={getFilterValuesWithFilterId(FILTER_NAME.HEADQUARTERS).includes(countryFilter.label)}
                      key={countryFilter.label}
                      filterParent="hqCountry"
                      benchmark={availableCountryFilters.benchmark}
                      baseFilterValue={countryFilter}
                      currentFilterValue={availableCountryFilters?.currentFilterValues.find((currentFilter) => currentFilter.label === countryFilter.label)}
                      handleChange={changeFilters}
                    />
                  ))}
              </fieldset>
            </div>
            <div className="filter-section">
              <fieldset className="bx--fieldset">
                <legend className="bx--label">Size</legend>
                {sortSizeFilters(availableSizeFilters?.allFilters)
                  .sort((a, b) => a.order - b.order)
                  .map((sizeFilter) => (
                    <FilterCheckbox
                      checked={getFilterValuesWithFilterId(FILTER_NAME.SIZE).includes(sizeFilter.label)}
                      key={sizeFilter.label}
                      filterParent="size"
                      benchmark={availableSizeFilters?.benchmark}
                      baseFilterValue={sizeFilter}
                      currentFilterValue={availableSizeFilters?.currentFilterValues.find((currentFilter) => currentFilter.label === sizeFilter.label)}
                      handleChange={changeFilters}
                    />
                  ))}
              </fieldset>
            </div>
            <div className="filter-section">
              <fieldset className="bx--fieldset">
                <legend className="bx--label">Estimated Employees</legend>
                <Range
                  min={minimumAndMaximumEmployeeEstimateFilterValue[0]}
                  max={1000}
                  tipFormatter={(value) => (value < 1000 ? value : `${value}+`)}
                  className="filter-slider"
                  allowCross={false}
                  value={employeeEstimateFilter}
                  onChange={(e) => setEmployeeEstimateFilter(e)}
                  onAfterChange={() => {
                    trackEvent(ANALYTICS_CATEGORY.STUDY, `Professional Employees Estimate - ${employeeEstimateFilter}`, ANALYTICS_ACTION.SET);
                    setFilterValuesWithFilterId(FILTER_NAME.EMPLOYEE_ESTIMATE, employeeEstimateFilter);
                  }}
                  trackStyle={[
                    {
                      background: '#0062ff',
                    },
                  ]}
                  handleStyle={[
                    {
                      background: '#0062ff',
                      border: '#0062ff',
                    },
                  ]}
                />
              </fieldset>
            </div>
            <div className="filter-section">
              <fieldset className="bx--fieldset">
                <legend className="bx--label">Revenue</legend>
                {sortRevenueFilters(availableRevenueFilters?.allFilters)
                  .sort((a, b) => a.order - b.order)
                  .map((revenueFilter) => (
                    <FilterCheckbox
                      filterParent="revenue"
                      key={revenueFilter.label}
                      checked={getFilterValuesWithFilterId(FILTER_NAME.REVENUE).includes(revenueFilter.label)}
                      benchmark={availableRevenueFilters?.benchmark}
                      baseFilterValue={revenueFilter}
                      currentFilterValue={availableRevenueFilters?.currentFilterValues.find((currentFilter) => currentFilter.label === revenueFilter.label)}
                      handleChange={changeFilters}
                    />
                  ))}
              </fieldset>
            </div>
            <div className="filter-section">
              <fieldset className="bx--fieldset">
                <legend className="bx--label">Cluster</legend>
                {availableClusterFilters?.allFilters.map((clusterFilter, index) => (
                  <FilterCheckbox
                    checked={getFilterValuesWithFilterId(FILTER_NAME.CLUSTER).includes(clusterFilter.label)}
                    key={index}
                    filterParent="cluster"
                    benchmark={availableClusterFilters.benchmark}
                    baseFilterValue={clusterFilter}
                    currentFilterValue={availableClusterFilters?.currentFilterValues.find((currentFilter) => currentFilter.label === clusterFilter.label)}
                    handleChange={changeFilters}
                  />
                ))}
              </fieldset>
            </div>
            <fieldset className="bx--fieldset">
              <legend className="bx--label">Founded Date</legend>
              <Range
                min={minimumAndMaximumDateFilterValue[0]}
                max={minimumAndMaximumDateFilterValue[1]}
                className="filter-slider"
                allowCross={false}
                value={yearFilter}
                onChange={(e) => setYearFilter(e)}
                onAfterChange={() => {
                  trackEvent(ANALYTICS_CATEGORY.STUDY, `Year - ${yearFilter}`, ANALYTICS_ACTION.SET);
                  setFilterValuesWithFilterId(FILTER_NAME.FOUNDED_DATE, yearFilter);
                }}
                trackStyle={[
                  {
                    background: '#0062ff',
                  },
                ]}
                handleStyle={[
                  {
                    background: '#0062ff',
                    border: '#0062ff',
                  },
                ]}
              />
            </fieldset>
            <fieldset className="bx--fieldset">
              <legend className="bx--label">Momentum Score</legend>
              <Range
                min={0}
                max={100}
                className="filter-slider"
                allowCross={false}
                value={momentumScoreFilter}
                onChange={(e) => setMomentumScoreFilter(e)}
                onAfterChange={() => {
                  trackEvent(ANALYTICS_CATEGORY.STUDY, `Momentum Score - ${momentumScoreFilter}`, ANALYTICS_ACTION.SET);
                  setFilterValuesWithFilterId(FILTER_NAME.MOMENTUM_SCORE, momentumScoreFilter);
                }}
                trackStyle={[
                  {
                    background: '#0062ff',
                  },
                ]}
                handleStyle={[
                  {
                    background: '#0062ff',
                    border: '#0062ff',
                  },
                ]}
              />
            </fieldset>
          </div>
          <CreateTagModal isOpen={isCreateTagModalOpen} handleClose={() => setIsCreateTagModalOpen(false)} />
          <DeleteTagModal tagLabel={isDeleteTagModalOpenWithTagLabel} isOpen={isDeleteTagModalOpenWithTagLabel !== undefined} handleClose={() => setIsDeleteTagModalOpenWithTagLabel(undefined)} />
        </div>
      )}
    </>
  );
};

export default FilterBar;
