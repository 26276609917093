import React, { useState, useEffect, useRef, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { CompanyMetadata, NewsEvent, ReferenceArchitecture } from '../Models/types';
import useCompanyAPI from '../Data/useCompanyAPI';
import useNewsAPI from '../Data/useNewsAPI';
import { UserContext } from './UserProvider';

type ContextProps = {
  isLoading: boolean;
  isLoadingEvents: boolean;
  company?: CompanyMetadata;
  referenceArchitecture?: ReferenceArchitecture;
  competingReferenceArchitecture?: ReferenceArchitecture;
  getCompetingReferenceArchitecture: (primaryCompanyId: string, competingCompanyId: string) => void;
  events: NewsEvent[];
  getArticlesWithIdForNewsEvent: (event: NewsEvent) => void;
};

export const CompanyContext = React.createContext<ContextProps>({
  isLoading: true,
  isLoadingEvents: true,
  company: undefined,
  referenceArchitecture: undefined,
  competingReferenceArchitecture: undefined,
  getCompetingReferenceArchitecture: () => {},
  events: [],
  getArticlesWithIdForNewsEvent: () => {},
});

const CompanyProvider: React.FC = ({ children }) => {
  const { currentUser } = useContext(UserContext);
  let { studyId, companyId } = useParams<{ studyId: string; companyId: string }>();
  const { getCompanyMetadataWithId, getReferenceArchitectureForCompanyWithId, getCompetingReferenceArchitectureForCompanyWithIdAndCompetingCompanyId } = useCompanyAPI(currentUser!);
  const { getEventsForCompaniesWithIds, getArticlesWithArticleIds } = useNewsAPI();
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingEvents, setIsLoadingEvents] = useState(true);
  const [company, setCompany] = useState<CompanyMetadata | undefined>(undefined);
  const [referenceArchitecture, setReferenceArchitecture] = useState(undefined);
  const [competingReferenceArchitecture, setCompetingReferenceArchitecture] = useState(undefined);
  const [events, setEvents] = useState<NewsEvent[]>([]);
  const rootReferenceArchitectureId = useRef<number | undefined>(undefined);

  async function getCompany() {
    setIsLoading(true);
    const companyDetailsResult = await getCompanyMetadataWithId(studyId, companyId);
    rootReferenceArchitectureId.current = companyDetailsResult?.referenceArchitecture.id;
    const [referenceArchitectureResult, _] = await Promise.all([
      getReferenceArchitectureForCompanyWithId(companyDetailsResult!.company.id, companyDetailsResult!.referenceArchitecture.id),
      getCompetingReferenceArchitecture(
        companyDetailsResult?.company.id,
        companyDetailsResult?.company.competitors && companyDetailsResult?.company.competitors.length > 0 ? companyDetailsResult?.company.competitors[0].id : undefined
      ),
    ]);
    setReferenceArchitecture(referenceArchitectureResult);
    setCompany(companyDetailsResult?.company);
    setIsLoading(false);

    // Load events separately as they're large
    const companyEventsResult = await getEventsForCompaniesWithIds([companyDetailsResult!.company.id]);
    setEvents(companyEventsResult);
    setIsLoadingEvents(false);
  }

  async function getCompetingReferenceArchitecture(primaryCompanyId?: string, competingCompanyId?: string) {
    if (competingCompanyId) {
      const referenceArchitectureResult = await getCompetingReferenceArchitectureForCompanyWithIdAndCompetingCompanyId(primaryCompanyId!, competingCompanyId, rootReferenceArchitectureId.current!);
      setCompetingReferenceArchitecture(referenceArchitectureResult);
    } else {
      setCompetingReferenceArchitecture(undefined);
    }
  }

  async function getArticlesWithIdForNewsEvent(newsEvent: NewsEvent) {
    const articlesResult = await getArticlesWithArticleIds(newsEvent.articleIds);
    // Find the event to update its articles
    const copyOfNewsEvents = [...events];
    const foundEventToUpdate = copyOfNewsEvents.find((event) => event.id === newsEvent.id);
    if (foundEventToUpdate) foundEventToUpdate.articles = articlesResult;
    setEvents(copyOfNewsEvents);
  }

  useEffect(() => {
    if (!companyId && !studyId) return;
    getCompany();
  }, [studyId, companyId]);

  return (
    <CompanyContext.Provider
      value={{
        isLoading,
        isLoadingEvents,
        company,
        referenceArchitecture,
        competingReferenceArchitecture,
        getCompetingReferenceArchitecture,
        events,
        getArticlesWithIdForNewsEvent,
      }}
    >
      {children}
    </CompanyContext.Provider>
  );
};

export default CompanyProvider;
