import { getCompetingReferenceArchitectureFromDB, getReferenceArchitectureFromDB, getExtractedStudyFromBucket } from './api';
import { CompanyDetails, CurrentUser } from '../Models/types';
import { mapCompanyObjectWithJSON } from './objectMapper';
import useStudyAPI from './useStudyAPI';

const useCompanyAPI = (currentUser: CurrentUser) => {
  const { getStudyWithId } = useStudyAPI(currentUser);

  const getCompanyMetadataWithId = async (studyId: string, id: string): Promise<CompanyDetails | undefined> => {
    const studyResult = await getStudyWithId(studyId);
    const studyMetadataResult = await getExtractedStudyFromBucket(studyResult!.latestCompanyOutput);
    const companiesResult = mapCompanyObjectWithJSON(studyMetadataResult.versions[0].elements);
    const selectedCompany = companiesResult.find((company) => company.name === id);
    if (selectedCompany) {
      return {
        referenceArchitecture: studyMetadataResult.reference_architecture,
        company: selectedCompany,
      };
    } else {
      return undefined;
    }
  };

  const getReferenceArchitectureForCompanyWithId = async (companyId: string, rootReferenceArchitectureId: number) => {
    try {
      return await getReferenceArchitectureFromDB(companyId, rootReferenceArchitectureId);
    } catch (e) {
      return undefined;
    }
  };

  const getCompetingReferenceArchitectureForCompanyWithIdAndCompetingCompanyId = async (primaryCompanyId: string, competingCompanyId: string, rootReferenceArchitectureId: number) => {
    try {
      return await getCompetingReferenceArchitectureFromDB(primaryCompanyId, competingCompanyId, rootReferenceArchitectureId);
    } catch (e) {
      return undefined;
    }
  };

  return {
    getCompanyMetadataWithId,
    getReferenceArchitectureForCompanyWithId,
    getCompetingReferenceArchitectureForCompanyWithIdAndCompetingCompanyId,
  };
};

export default useCompanyAPI;
