export const categoryColors = {
  Bribery: '#5a189a',
  Corruption: '#7b2cbf',
  'Ineffective relationship with regulators': '#F4E04D',
  'Insider trading': '#9DC0BC',
  'KYC non-compliance': '#A4303F',
  'Money laundering and terrorism financing Sanctions violation': '#88A0A8',
  'Prudential risk': '#FF7D00',
  'Regulatory changes': '#AFBFC0',
  Scandal: '#847577',
  'Transaction monitoring control failure': '#50514F',
  Whistleblowing: '#3185FC',
};
