import {
  IGroup,
  defaultGroup,
  ICellRendererFactory,
  Column,
  GroupColumn,
  ICellRenderer,
  IDataRow,
  IOrderedGroup,
  IGroupCellRenderer,
  ISummaryRenderer,
  IRenderContext,
  IAggregationStrategy,
} from 'lineupjs';

function isAlwaysShowingGroupStrategy(strategy: IAggregationStrategy) {
  return strategy === 'group+item' || strategy === 'group+item+top' || strategy === 'group+top+item';
}

function isDummyGroup(group: IGroup) {
  return group.parent == null && group.name === defaultGroup.name;
}

function trimGroupName(name: string) {
  const last = name.lastIndexOf('∩');
  // render just the last group
  return last >= 0 ? name.slice(last + 1).trim() : name;
}

export default class CustomGroupCellRenderer implements ICellRendererFactory {
  readonly title: string = 'Default';

  canRender(col: Column): boolean {
    return col instanceof GroupColumn;
  }

  create(_col: Column, context: IRenderContext): ICellRenderer {
    return {
      template: `<div><div></div></div>`,
      update(node: HTMLElement, _row: IDataRow, i: number, group: IOrderedGroup) {
        const text = isDummyGroup(group) || i > 0 || isAlwaysShowingGroupStrategy(context.provider.getAggregationStrategy()) ? '' : `${trimGroupName(group.name)} (${group.order.length})`;
        (node.firstElementChild! as HTMLElement).textContent = text;
        node.title = text;
      },
      render(_ctx: CanvasRenderingContext2D, _row: IDataRow, i: number) {
        return i === 0;
      },
    };
  }

  createGroup(): IGroupCellRenderer {
    return {
      template: `<div><div></div></div>`,
      update(node: HTMLElement, group: IOrderedGroup) {
        const text = isDummyGroup(group) ? '' : `${trimGroupName(group.name)} (${group.order.length})`;
        (node.firstElementChild! as HTMLElement).textContent = text;
        node.title = text;
      },
    };
  }

  createSummary(): ISummaryRenderer {
    return {
      template: '<div></div>',
      update: () => undefined,
    };
  }
}
