import React, { useContext, useState } from 'react';
import Color from 'color';
import GEO_DATA from '../../../Assets/Data/MAP_TOPOLOGY_DATA.json';
import { ComposableMap, Geographies, Geography, ZoomableGroup } from 'react-simple-maps';
import ReactTooltip from 'react-tooltip';
import { FilterContext } from '../../../Contexts/FilterProvider';
import { FILTER_NAME } from '../../../Models/types';
import useAnalytics, { ANALYTICS_ACTION, ANALYTICS_CATEGORY } from '../../../Hooks/useAnalytics';

interface Props {
  width: number;
  height: number;
}

const countryColor = (isSelected: boolean, value?: number) => {
  const baseStyle = {
    fill: '',
    stroke: '#607D8B',
    strokeWidth: 0.5,
    outline: 'none',
  };
  if (isSelected) {
    baseStyle.fill = '#ff5964';
    return baseStyle;
  } else {
    baseStyle.fill = value ? Color('#2596ff').alpha(value / 100) : '#ECEFF1';
    return baseStyle;
  }
};

const MapWidget: React.FC<Props> = ({ width, height }) => {
  const { trackEvent } = useAnalytics();
  const { getFilterValuesWithFilterId, setFilterValuesWithFilterId, availableCountryFilters } = useContext(FilterContext);
  const [tooltipContent, setTooltipContent] = useState('');

  function handleCountryClick(country) {
    trackEvent(ANALYTICS_CATEGORY.STUDY, `Country: ${country.properties.NAME}`, ANALYTICS_ACTION.SET);
    setFilterValuesWithFilterId(FILTER_NAME.HEADQUARTERS, [country.properties.NAME]);
  }

  return availableCountryFilters && width ? (
    <div>
      <ComposableMap data-tip="" width={width} height={height}>
        <ZoomableGroup zoom={1}>
          <Geographies geography={GEO_DATA}>
            {({ geographies }) =>
              geographies.map((geo) => (
                <Geography
                  style={{
                    default: getFilterValuesWithFilterId(FILTER_NAME.HEADQUARTERS).includes(geo.properties.NAME)
                      ? countryColor(true)
                      : countryColor(false, availableCountryFilters?.allFilters.find((filter) => filter.label === geo.properties.NAME)?.count),
                  }}
                  key={geo.rsmKey}
                  geography={geo}
                  onClick={() => handleCountryClick(geo)}
                  onMouseEnter={() => {
                    const { NAME } = geo.properties;
                    setTooltipContent(`${NAME} (${availableCountryFilters?.allFilters.find((filter) => filter.label === geo.properties.NAME)?.count || 0})`);
                  }}
                  onMouseLeave={() => {
                    setTooltipContent('');
                  }}
                />
              ))
            }
          </Geographies>
        </ZoomableGroup>
      </ComposableMap>
      <ReactTooltip>{tooltipContent}</ReactTooltip>
    </div>
  ) : null;
};

export default MapWidget;
