import { LatestCompanyComment, Comment } from '../Models/types';
import { getLatestCommentsFromDB, getCommentsFromDB, createCommentInDB } from './api';

const useCommentsAPI = () => {
  const getLatestComments = async (studyId: number, studyUserGroupId: number): Promise<LatestCompanyComment[]> => {
    try {
      const latestComments: LatestCompanyComment[] = [];
      const commentsResult = await getLatestCommentsFromDB(studyId, studyUserGroupId);
      commentsResult.forEach((comment) => {
        const newLatestComment: LatestCompanyComment = {
          companyId: comment.company_ID,
          text: comment.text,
        };
        latestComments.push(newLatestComment);
      });
      return latestComments;
    } catch (e) {
      return [];
    }
  };

  const getComments = async (studyId: number, studyUserGroupId: number, companyId: string): Promise<Comment[]> => {
    try {
      const comments: Comment[] = [];
      const commentsResult = await getCommentsFromDB(studyId, studyUserGroupId, companyId);
      commentsResult.forEach((comment) => {
        const newComment: Comment = {
          createdDate: comment.created_date,
          email: comment.email,
          id: comment.comment_ID,
          text: comment.text,
          userFirstName: comment.first_name,
          userId: comment.user_ID,
          userLastName: comment.last_name,
        };
        comments.push(newComment);
      });
      return comments;
    } catch (e) {
      return [];
    }
  };

  const createComment = async (userId: number, text: string, studyId: number, studyUserGroupId: number, companyId: string): Promise<void> => {
    try {
      await createCommentInDB(userId, text, studyId, studyUserGroupId, companyId);
    } catch (e) {
      throw e;
    }
  };

  return {
    getLatestComments,
    getComments,
    createComment,
  };
};

export default useCommentsAPI;
