import React, { useContext } from 'react';
import { StudyContext } from '../../Contexts/StudyProvider';
import { ScatterPlot } from '@nivo/scatterplot';
import { useHistory } from 'react-router-dom';

interface scatterDataGroupItem {
  x: number;
  y: number;
}

interface scatterDataGroup {
  id: string;
  data: Array<scatterDataGroupItem>;
}

interface scatterData extends Array<scatterDataGroup> {}

const companyNode = (nodes, companies) => {
  const { node, x, y, size, onMouseEnter, onMouseMove, onMouseLeave, onClick } = nodes;
  const company = companies.find((com) => com.id === node.data.serieId);
  if (!company || !company.logoURL) return null;

  return (
    <g
      transform={`translate(${x},${y - size})`}
      style={{ cursor: 'pointer' }}
    >
      <image
        href={company.logoURL}
        width={size}
        height={size}
        onMouseEnter={onMouseEnter}
        onMouseMove={onMouseMove}
        onMouseLeave={onMouseLeave}
        onClick={onClick}
      ></image>
      <rect width={size} height={size} stroke="black" strokeWidth={0.5} fill="none"></rect>
    </g>
  );
};

const companyNodeTooltip = ({ node }, companies) => {
  const company = companies.find((com) => com.id === node.data.serieId);

  if (!company || !company.professionalEmployeeEstimate || !company.foundedYear) return null;

  return (
    <div
      style={{
        color: '#fff',
        background: '#222',
        padding: '1rem',
      }}
    >
      <strong>{company.name}</strong> ({company.foundedYear})
      <br />
      <strong>{company.professionalEmployeeEstimate}</strong>
      <small> estimated employees</small>
    </div>
  );
};

const ScatterChart = ({ width }) => {
  const history = useHistory();
  const { filteredStudyCompanies, selectedStudy } = useContext(StudyContext);

  // Handler to navigate to company page on company node click
  function onCompanyNodeClick(node) {
    if (!node) return;

    const company = filteredStudyCompanies.find((com) => com.id === node.data.serieId);
    if (!company || !company.name || !selectedStudy || !selectedStudy.id) return;

    history.push(`/study/${selectedStudy.id}/company/${company.name}`);
  }

  // Format the filtered company list into the suitable format for
  // the scatter chat. Each company creates it's own group. The
  // y values are logged (base 2) to scale the chart.
  const data: scatterData = filteredStudyCompanies
    .filter((company) => typeof company.foundedYear === 'number' && typeof company.professionalEmployeeEstimate === 'number')
    .map((company) => ({
      id: company.id,
      data: [
        {
          x: company.foundedYear as number,
          y: Math.round(Math.log2(company.professionalEmployeeEstimate as number)),
        },
      ],
    }));

  return (
    <ScatterPlot
      data={data}
      width={width}
      height={500}
      margin={{ top: 80, right: 50, bottom: 60, left: 80 }}
      xScale={{ type: 'linear', min: 'auto', max: 'auto' }}
      yScale={{ type: 'linear', min: 'auto', max: 'auto' }}
      useMesh={false}
      blendMode="normal"
      onClick={onCompanyNodeClick}
      nodeSize={30}
      renderNode={(nodes) => companyNode(nodes, filteredStudyCompanies)}
      tooltip={(nodes) => companyNodeTooltip(nodes, filteredStudyCompanies)}
      axisBottom={{
        legend: 'Founded year',
        legendPosition: 'middle',
        legendOffset: 42,
      }}
      axisLeft={{
        legend: 'Employee estimate (log(2, Y))',
        legendPosition: 'middle',
        legendOffset: -40,
      }}
    />
  );
};

export default ScatterChart;
