import React from 'react';
import './card-company-section.scss';

interface Props {
  columns: number;
  title: string;
  icon: React.FC;
  fixedHeight?: number;
}

const CardCompanySection: React.FC<Props> = ({ children, columns, title, icon, fixedHeight }) => {
  return (
    <div className={`bx--col-lg-${columns}`}>
      <div className="card-company-section" style={{ height: fixedHeight ? `${fixedHeight}px` : '100%' }}>
        <div className="card-company-section__header">
          <div style={{ marginRight: '5px' }}>{React.createElement(icon)}</div>
          {title}
        </div>
        <div className="card-company-section__body" style={{ height: fixedHeight ? fixedHeight - 45 : '100%', overflowY: fixedHeight ? 'scroll' : 'hidden', overflowX: 'hidden' }}>
          {children}
        </div>
      </div>
    </div>
  );
};

export default CardCompanySection;
