import React from 'react';
import { Checkbox, OverflowMenu, OverflowMenuItem } from 'carbon-components-react';
import './filter-checkbox.scss';
import useAnalytics, { ANALYTICS_ACTION, ANALYTICS_CATEGORY } from '../../Hooks/useAnalytics';

const filterBarMaxWidthRatio = 2;

interface Props {
  benchmark: number | undefined;
  filterParent: string;
  baseFilterValue: { count: number; label: string } | undefined; // The original, unfiltered value
  currentFilterValue: { count: number; label: string } | undefined; // The current filtered companies values
  handleChange: (value, id, event) => void;
  checked: boolean;
  options?: { label: string; clickHandler: Function; isDanger: boolean }[];
}

const FilterCheckbox: React.FC<Props> = ({ baseFilterValue, currentFilterValue, filterParent, benchmark, handleChange, checked, options }) => {
  const { trackEvent } = useAnalytics();
  return (
    <div className="filter-checkbox">
      <span className="filter-count">{baseFilterValue?.count}</span>
      <span className="filter-bar" style={{ width: `${(baseFilterValue!.count / benchmark!) * 100 * filterBarMaxWidthRatio}px`, background: '#0062ff26' }} />
      {currentFilterValue && (
        <span className="filter-bar" style={{ width: `${(currentFilterValue!.count / benchmark!) * 100 * filterBarMaxWidthRatio}px`, background: checked ? '#FF5964' : '#0062ff26' }} />
      )}
      <Checkbox
        checked={checked}
        id={`${baseFilterValue?.label}-${filterParent}`}
        labelText={baseFilterValue?.label}
        filterparent={filterParent}
        filtervalue={baseFilterValue?.label}
        onChange={(value, id, event) => {
          handleChange(value, id, event);
          trackEvent(ANALYTICS_CATEGORY.STUDY, `${filterParent} - ${baseFilterValue?.label}`, ANALYTICS_ACTION.CLICKED);
        }}
      />
      {options && (
        <OverflowMenu>
          {options.map((option) => {
            if (option.isDanger) return <OverflowMenuItem key={option.label} itemText={option.label} onClick={() => option.clickHandler(baseFilterValue?.label)} hasDivider isDelete />;
            else return <OverflowMenuItem key={option.label} itemText={option.label} onClick={option.clickHandler} />;
          })}
        </OverflowMenu>
      )}
    </div>
  );
};

export default FilterCheckbox;
