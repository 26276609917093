import React, { useContext } from 'react';
import Hyphenated from 'react-hyphen';
import { FILTER_NAME } from '../../../Models/types';
import { FilterContext } from '../../../Contexts/FilterProvider';
import { ARCHITECTURE_VIEW_COLORS } from '../../../Constants/color.constants';
import useAnalytics, { ANALYTICS_ACTION, ANALYTICS_CATEGORY } from '../../../Hooks/useAnalytics';

export interface ReferenceArchitectureItem {
  id: number;
  name: string;
  children: ReferenceArchitectureItemCellProps[];
  maxCount: number;
}

export interface ReferenceArchitectureItemCellProps {
  id: number;
  name: string;
  count: number;
  children: ReferenceArchitectureItemCellProps[];
}

function getRefArchitectureCellColor(count: number, maxCount: number): string {
  return ARCHITECTURE_VIEW_COLORS[Math.floor((count * (ARCHITECTURE_VIEW_COLORS.length - 1)) / maxCount)];
}

export const MarketReferenceArchitectureViewItem: React.FC<ReferenceArchitectureItem> = ({ name, maxCount, children }) => {
  const { setFilterValuesWithFilterId, getFilterValuesWithFilterId } = useContext(FilterContext);
  const { trackEvent } = useAnalytics();

  function handleHeaderClick(cellValue: number[]) {
    trackEvent(ANALYTICS_CATEGORY.STUDY, `Market Reference Architecture: ${cellValue}`, ANALYTICS_ACTION.SET);
    setFilterValuesWithFilterId(FILTER_NAME.REFERENCE_ARCHITECTURE, cellValue);
  }

  function handleCellClick(cellValue: number) {
    trackEvent(ANALYTICS_CATEGORY.STUDY, `Market Reference Architecture: ${cellValue}`, ANALYTICS_ACTION.SET);
    setFilterValuesWithFilterId(FILTER_NAME.REFERENCE_ARCHITECTURE, [cellValue]);
  }

  return (
    <div className="architecture-view__item">
      <div className="ux-architecture-view__item-title-wrapper">
        <div className="ux-architecture-view__item-title">
          <Hyphenated>{name}</Hyphenated>
        </div>
      </div>
      <table className="architecture-view__row-container">
        <tbody>
          {children.map((item, index) => (
            <tr className="architecture-view__cell-container" key={index}>
              <td className="architecture-view__cell _head" onClick={() => handleHeaderClick(item.children.map((item) => item.id))}>
                <Hyphenated>{item.name}</Hyphenated>
              </td>
              {item.children.map((item, index) => (
                <td
                  key={index}
                  className={getFilterValuesWithFilterId(FILTER_NAME.REFERENCE_ARCHITECTURE).find((value) => item.id === value) ? 'architecture-view__cell _active' : 'architecture-view__cell'}
                  style={{ background: getRefArchitectureCellColor(item.count, maxCount) }}
                  onClick={() => handleCellClick(item.id)}
                >
                  <Hyphenated>{item.name}</Hyphenated>
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
