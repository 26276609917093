import { CompanyMetadata } from '../Models/types';

export interface MinMaxYearValue {
  min: number;
  max: number;
}

export function getMinAndMaxMomentumValue(data: CompanyMetadata[]): MinMaxYearValue {
  if (!data.length) return { min: 0, max: 0 };
  let min = Number(data[0].overallMomentum);
  let max = Number(data[0].overallMomentum);

  data.forEach((item) => {
    if (Number(item.overallMomentum) < min) {
      min = Number(item.overallMomentum);
    }
    if (Number(item.overallMomentum) > max) {
      max = Number(item.overallMomentum);
    }
  });
  return { min, max };
}

export function getEmployeeValue(data: CompanyMetadata[]): Map<string, number> {
  const employeesMap = new Map<string, number>();
  data.forEach(({ size }) => {
    if (size) {
      const emplValue = size.split(' ').slice(-1)[0];
      if (employeesMap.get(emplValue)) {
        employeesMap.set(emplValue, (employeesMap.get(emplValue) || 0) + 1);
      } else {
        employeesMap.set(emplValue, 1);
      }
    }
  });

  return employeesMap;
}
