import React from 'react';

import './events-list.scss';
import { SkeletonPlaceholder } from 'carbon-components-react';
import EventListItem from './EventListItem';
import { NewsEvent } from '../../Models/types';

interface Props {
  isLoading: boolean;
  events: NewsEvent[];
  filteredEvents: NewsEvent[];
  maxHeight: number;
  isInNewsEventsView: boolean;
  handleGetArticlesForNewsEvent: (event: NewsEvent) => void;
}

const EventsList: React.FC<Props> = ({ isLoading, events, handleGetArticlesForNewsEvent, filteredEvents, maxHeight, isInNewsEventsView }) => {
  return !isLoading ? (
    <>
      {events.length > 0 ? (
        <>
          {filteredEvents.length > 0 ? (
            <div style={{ maxHeight: isInNewsEventsView ? '38vh' : `${maxHeight}px` }} className="events-list margin-top-large">
              {filteredEvents &&
                filteredEvents
                  .sort((a, b) => new Date(b.startDate).getTime() - new Date(a.startDate).getTime())
                  .map((event) => <EventListItem handleGetArticlesForNewsEvent={handleGetArticlesForNewsEvent} event={event} />)}
            </div>
          ) : (
            <div style={{ maxHeight: isInNewsEventsView ? '38vh' : `${maxHeight}px` }} className="events-list margin-top-large">
              {events &&
                events
                  .sort((a, b) => new Date(b.startDate).getTime() - new Date(a.startDate).getTime())
                  .map((event) => <EventListItem handleGetArticlesForNewsEvent={handleGetArticlesForNewsEvent} event={event} />)}
            </div>
          )}
        </>
      ) : (
        <div className="margin-top-large">No Events.</div>
      )}
    </>
  ) : (
    <>
      <SkeletonPlaceholder style={{ width: '100%' }} className="chart-skeleton-placeholder__placeholder--large margin-top-large" />
    </>
  );
};

export default EventsList;
