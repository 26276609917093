import React, { useContext, useEffect, useState } from 'react';
import { Study } from '../Models/types';
import { UserContext } from './UserProvider';
import useStudyAPI from '../Data/useStudyAPI';

type ContextProps = {
  studies?: Study[];
};

export const StudiesContext = React.createContext<ContextProps>({
  studies: [],
});

const StudiesProvider: React.FC = ({ children }) => {
  const { currentUser } = useContext(UserContext);
  const { getAllStudies } = useStudyAPI(currentUser!);
  const [studies, setStudies] = useState<Study[]>([]);

  async function getStudies() {
    const studiesResult = await getAllStudies();
    setStudies(studiesResult);
  }

  useEffect(() => {
    getStudies();
  }, []);

  return (
    <StudiesContext.Provider
      value={{
        studies,
      }}
    >
      {children}
    </StudiesContext.Provider>
  );
};

export default StudiesProvider;
