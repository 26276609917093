import React from 'react';
import './momentum-score.scss';

interface Props {
  score?: number;
  width: number;
  height: number;
  size: 'small' | 'large';
}

const momentumScoreBoxStyle = (value) => {
  if (value >= 0 && value <= 19) return '6c757d24';
  if (value >= 20 && value <= 29) return '6c757d24';
  if (value >= 30 && value <= 39) return '6c757d24';
  if (value >= 40 && value <= 49) return '9bf88f66';
  if (value >= 50 && value <= 59) return '4afb5966';
  if (value >= 60 && value <= 69) return '41fdbe66';
  if (value >= 70 && value <= 79) return '3cf2fe66';
  if (value >= 80 && value <= 89) return '39ccfe66';
  if (value >= 90 && value <= 100) return '3491ff66';
};

const MomentumScore: React.FC<Props> = ({ score, width, height, size }) => {
  return (
    <div className="momentum-score-box" style={{ fontSize: `${size === 'small' ? 16 : 32}px`, width, height, background: `#${momentumScoreBoxStyle(score)}` }}>
      {score}
    </div>
  );
};

export default MomentumScore;
