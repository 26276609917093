import React, { useState } from 'react';
import { Stage, Layer, Text } from 'react-konva';
import BubbleNode from './BubbleNode';
import BubbleTooltip from './BubbleTooltip';

interface BubbleCanvasProps {
  width: any;
  height: any;
  data: any;
  clusterNames: any;
  clickHandler: any;
}

const BubbleCanvas: React.FC<BubbleCanvasProps> = (props) => {
  const layerRef = React.useRef(null);
  const [tooltip, setTooltip] = useState<any>(false);

  const showTooltipHandler = (pos) => {
    setTooltip({ pos: pos.pos, text: pos.text });
  };

  return (
    <Stage width={props.width} height={props.height}>
      <Layer ref={layerRef}>
        {props.data.map((d) => (
          <BubbleNode clickHandler={props.clickHandler} data={d} key={d.data.label} showTooltip={showTooltipHandler} />
        ))}
        {props.clusterNames.map((d) => (
          <Text text={d.data.Label} key={'cluster_' + d.data.Label} x={d.x - 40} y={d.y - d.r - 10} />
        ))}
      </Layer>
      <Layer>
        <BubbleTooltip text={tooltip.text} pos={tooltip.pos} />
      </Layer>
    </Stage>
  );
};

export default BubbleCanvas;
