import { useContext, useEffect } from 'react';
import { UserContext } from '../Contexts/UserProvider';
import { useCookies } from 'react-cookie';
import { COOKIE_KEY_NAME } from '../Helpers/constants';

export enum PREFERENCE_KEY {
  activeWidgets = 'activeWidgets',
  isWidgetsHidden = 'isWidgetsHidden',
}

const usePreferences = () => {
  const { currentUser, updateAILSAPreferences, invalidateSession } = useContext(UserContext);
  const [cookies, setCookie] = useCookies([COOKIE_KEY_NAME]);

  // This checks that the user has logged in after we added the preferences field to the database
  // If not, they're logged out so they have to log in again
  useEffect(() => {
    if (currentUser?.preferences === undefined) {
      invalidateSession();
    }
  }, [currentUser]);

  async function setPreference(key: PREFERENCE_KEY, value: any) {
    const currentUserCopy = { ...currentUser };
    currentUserCopy['preferences']![key] = value;
    setCookie(COOKIE_KEY_NAME, currentUserCopy, { path: '/', domain: process.env.REACT_APP_DOMAIN });
    updateAILSAPreferences();
  }

  return {
    preferences: currentUser?.preferences,
    setPreference,
  };
};

export default usePreferences;
