import React from 'react';
import UserAvatar from '../../UserAvatar/UserAvatar';
import moment from 'moment';
import ReactTooltip from 'react-tooltip';

interface Props {
  userFirstName: string;
  userLastName: string;
  userEmail: string;
  text: string;
  postedDate: string;
}

const CommentListItem: React.FC<Props> = ({ userFirstName, userLastName, postedDate, text, userEmail }) => {
  return (
    <li tabIndex={1} className="comment-list-item">
      <div className="comment-list-item__header">
        <UserAvatar firstName={userFirstName} lastName={userLastName} />
        <div className="comment-list-item__username" data-tip={userEmail}>
          <strong>
            {userFirstName} {userLastName}
          </strong>
        </div>
        <div className="comment-list-item__date-posted" data-tip={moment(postedDate).format('dddd, Do MMMM YYYY, hh:mm a')}>
          {moment(postedDate).fromNow()}
        </div>
        <ReactTooltip />
      </div>
      {text}
    </li>
  );
};

export default CommentListItem;
