import { signin, acceptTermsInDB } from './api';
import { CurrentUser } from '../Models/types';
import { mapAILSAPreferencesWithJSON } from './objectMapper';

const useAuthAPI = () => {
  const signinWithEmailAndPassword = async (email: string, password: string): Promise<CurrentUser> => {
    try {
      const signinResult = await signin(email, password);
      return {
        id: signinResult.id,
        email: signinResult.email,
        firstName: signinResult.first_name,
        lastName: signinResult.last_name,
        isNewUser: signinResult.is_new_user,
        isTermsAgreed: signinResult.is_terms_agreed,
        studyGroupName: signinResult.study_user_group_name,
        studyGroupId: signinResult.study_user_group_id,
        preferences: mapAILSAPreferencesWithJSON(signinResult.ailsa_preferences),
      };
    } catch (e) {
      throw e;
    }
  };

  const acceptTermsWithUserId = async (userId: number) => {
    try {
      await acceptTermsInDB(userId);
    } catch (e) {
      throw e;
    }
  };

  return {
    signinWithEmailAndPassword,
    acceptTermsWithUserId,
  };
};

export default useAuthAPI;
