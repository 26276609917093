import { getEmployeeFilterInitialState } from './filter.helper';
import { getMinAndMaxMomentumValue } from './data.helper';
import * as d3 from 'd3';
import { CompanyMetadata, StudyFilterId } from '../Models/types';
import { LEGEND_COLORS, MOMENTUM_COLORS, UNKNOWN_COLOR } from '../Constants/color.constants';

export function getDomain(attr, data: CompanyMetadata[]): string[] | number[] | any[] | undefined {
  switch (attr) {
    case StudyFilterId.EMPLOYEES:
      const allEmployeesValues = getEmployeeFilterInitialState(data, data).map((d) => d.name);
      const unknown = allEmployeesValues.find((d) => d.includes('TBD'));
      const cutTBD = allEmployeesValues.filter((d) => !d.includes('TBD'));
      unknown && cutTBD.unshift('n/a');
      return cutTBD;
    case StudyFilterId.MOMENTUM:
      const bound = getMinAndMaxMomentumValue(data);
      const unknownM = data.find((d) => !d.overallMomentum);
      const isAllUnknown = data.every((d) => !d.overallMomentum);
      let min = 0;
      let max = Math.ceil(bound.max / 100) * 100;
      if (min > max) min = [max, (max = min)][0];
      const middle = Math.round((max - min) / 2);
      if (isAllUnknown) return ['n/a'];
      return unknownM ? ['n/a', min, middle, max] : [min, middle, max];
  }
}

export function getColorFunction(attr, data) {
  if (attr === StudyFilterId.EMPLOYEES) {
    const currentDomain = getDomain(attr, data) || [];
    return function colorFunc(d) {
      const entity = d.data || d;
      const x = entity.size ? entity.size : entity;
      const ordinal = d3.scalePoint().domain(currentDomain).range([0, 1]);
      const isFirsUnknown = currentDomain[0] === 'n/a';
      const linear = d3
        .scaleLinear()
        .domain([0, 1])
        .range([LEGEND_COLORS[isFirsUnknown ? 0 : 1], LEGEND_COLORS[LEGEND_COLORS.length - 1]])
        .unknown(UNKNOWN_COLOR);
      return linear(ordinal(x));
    };
  }
  if (attr === StudyFilterId.MOMENTUM) {
    const domain = getDomain(attr, data) || [];
    return function colorFunc(d) {
      const entity = d.data || d;
      const x = entity.overallMomentum ? entity.overallMomentum : entity;
      const isFirsUnknown = domain[0] === 'n/a';
      return d3
        .scaleLinear([domain[isFirsUnknown ? 1 : 0], domain[domain.length - 1]], [MOMENTUM_COLORS[1], MOMENTUM_COLORS[MOMENTUM_COLORS.length - 1]])
        .unknown(UNKNOWN_COLOR)
        .clamp(1)(x);
    };
  }
  return function () {
    return null;
  };
}

export function getSizeFunction(attr, data, h) {
  const count = data?.length || 1;
  const baseR = h / (count / 2.2);
  const minR = baseR;
  const maxR = baseR * 1.8;

  if (attr === StudyFilterId.EMPLOYEES) {
    let currentDomain = getDomain(attr, data) || [];
    return function sizeFunc(d) {
      const entity = d.data || d;
      const x = entity.size ? entity.size : entity;

      if (currentDomain[0] === 'n/a') {
        currentDomain = currentDomain.slice(1);
      }
      const ordinal = d3.scalePoint().domain(currentDomain).range([0, 1]);
      const linear = d3.scaleLinear().domain([0, 1]).range([minR, maxR]).unknown(maxR);
      return linear(ordinal(x));
    };
  }
  if (attr === StudyFilterId.MOMENTUM) {
    const domain = getDomain(attr, data) || [];
    return function sizeFunc(d) {
      const entity = d.data || d;
      const x = entity.overallMomentum ? entity.overallMomentum : entity;
      const isFirsUnknown = domain[0] === 'n/a';
      return d3
        .scaleLinear([domain[isFirsUnknown ? 1 : 0], domain[domain.length - 1]], [minR, maxR])
        .unknown(maxR)
        .clamp(1)(x);
    };
  }
  return function () {
    return null;
  };
}
