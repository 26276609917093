import React from 'react';
import './demo-table-view-banner.scss';
import RequestDemoButton from './RequestDemoButton';

interface Props {
  position: 'sticky' | 'fixed' | 'absolute';
}

const DemoBanner: React.FC<Props> = ({ position }) => {
  return (
    <div className="demo-wrapper" style={{ position }}>
      <h1>View all 30,000+ companies.</h1>
      <p className="margin-bottom-large">When you purchase an AILSA subscription, you can access all of our studies and get unlimited search results.</p>
      <RequestDemoButton />
    </div>
  );
};

export default DemoBanner;
