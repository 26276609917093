import { Favourite } from '../Models/types';
import { getFavouritesFromDB, createFavouriteInDB, deleteFavouriteFromDB } from './api';

const useCommentsAPI = () => {
  const getFavourites = async (studyId: number, studyUserGroupId: number): Promise<Favourite[]> => {
    try {
      const favourites: Favourite[] = [];
      const favouritesResult = await getFavouritesFromDB(studyId, studyUserGroupId);
      favouritesResult.forEach((favourite) => {
        const newFavourite: Favourite = {
          id: favourite.ID,
          createdDate: favourite.created_date,
          companyId: favourite.company_ID,
          userFirstName: favourite.user_first_name,
          userLastName: favourite.user_last_name,
        };
        favourites.push(newFavourite);
      });
      return favourites;
    } catch (e) {
      return [];
    }
  };

  const createFavourite = async (userId: number, studyId: number, studyUserGroupId: number, companyId: string): Promise<void> => {
    try {
      await createFavouriteInDB(userId, studyId, studyUserGroupId, companyId);
    } catch (e) {
      throw e;
    }
  };

  const deleteFavourite = async (companyId: string, studyId: number, studyUserGroupId: number): Promise<void> => {
    try {
      await deleteFavouriteFromDB(companyId, studyId, studyUserGroupId);
    } catch (e) {
      throw e;
    }
  };

  return {
    getFavourites,
    createFavourite,
    deleteFavourite,
  };
};

export default useCommentsAPI;
