import { CompanyMetadataResult } from '../Models/dataTypes';
import { AILSAPreferences, CompanyMetadata, Competitor } from '../Models/types';
import moment from 'moment';

export function mapCompanyObjectWithJSON(companyJSON: CompanyMetadataResult[]): CompanyMetadata[] {
  const listOfCompanies: CompanyMetadata[] = [];
  companyJSON.forEach((companyResult) => {
    const newCompanyMetadata: CompanyMetadata = {
      logoURL: companyResult.Image ? companyResult.Image : 'N/A',
      IPODate: isNaN(moment(companyResult.IPO_date).year()) ? 'N/A' : moment(companyResult.IPO_date).format('DD-MM-YYYY'),
      cluster: companyResult.Cluster ? companyResult.Cluster : 'N/A',
      description: companyResult.description ? companyResult.description : 'N/A',
      foundedYear: companyResult.founded_date ? moment(companyResult.founded_date).year() : 'N/A',
      totalFundingAmount: companyResult.total_funding_amount ? companyResult.total_funding_amount : 'N/A',
      fundingRounds: companyResult.funding_rounds ? companyResult.funding_rounds : 'N/A',
      competitors: companyResult.competitors ? (companyResult.competitors.length > 0 ? mapCompetitorObjectWithJSON(companyJSON, companyResult.competitors) : []) : [],
      hqCountry: companyResult.HQ_country ? companyResult.HQ_country : 'N/A',
      id: companyResult.Label ? companyResult.Label : 'N/A',
      name: companyResult.name ? companyResult.name : 'N/A',
      size: companyResult.employees ? companyResult.employees.split(' ').pop()! : 'N/A',
      tags: companyResult.Tags && companyResult.Tags.length > 0 ? companyResult.Tags : ['N/A'],
      revenue: companyResult.revenue ? companyResult.revenue : 'N/A',
      overallMomentum: companyResult.momentum ? Math.floor(companyResult.momentum.overall) : undefined,
      referenceArchitectureIds: companyResult?.reference_architecture_ids || [],
      ngrams: companyResult?.ngrams || [],
      userTags: [], // default value
      latestComment: undefined,
      isStarredByForestreet: companyResult?.star || false,
      isFavourite: false, // default value
      professionalEmployeeEstimate: companyResult?.professional_employee_estimate ? companyResult?.professional_employee_estimate : undefined,
      momentum: {
        funding: companyResult.momentum ? Math.floor(companyResult.momentum.funding) : 0,
        mediaAnalysis: companyResult.momentum ? Math.floor(companyResult.momentum.media_analysis) : 0,
        revenueEstimates: companyResult.momentum ? Math.floor(companyResult.momentum.revenue_estimates) : 0,
        webTraffic: companyResult.momentum ? Math.floor(companyResult.momentum.web_traffic) : 0,
      },
    };
    listOfCompanies.push(newCompanyMetadata);
  });
  return listOfCompanies;
}

function mapCompetitorObjectWithJSON(companyJSON: CompanyMetadataResult[], competitorJSON: { score: number; name: string }[]): Competitor[] {
  const listOfCompetitors: Competitor[] = [];
  competitorJSON.forEach((competitorResult) => {
    const foundCompetitorCompanyInCompanyJSON = companyJSON.find((companyResult) => companyResult.Label === competitorResult.name);
    if (foundCompetitorCompanyInCompanyJSON) {
      const newCompetitorResult: Competitor = {
        score: competitorResult.score,
        id: foundCompetitorCompanyInCompanyJSON.Label ? foundCompetitorCompanyInCompanyJSON.Label : 'N/A',
        description: foundCompetitorCompanyInCompanyJSON.description ? foundCompetitorCompanyInCompanyJSON.description : 'N/A',
        website: foundCompetitorCompanyInCompanyJSON.Label ? foundCompetitorCompanyInCompanyJSON.Label : 'N/A',
        foundedYear: foundCompetitorCompanyInCompanyJSON.founded_date ? foundCompetitorCompanyInCompanyJSON.founded_date : 'N/A',
        hqCountry: foundCompetitorCompanyInCompanyJSON.HQ_country ? foundCompetitorCompanyInCompanyJSON.HQ_country : 'N/A',
        logoURL: foundCompetitorCompanyInCompanyJSON.Image ? foundCompetitorCompanyInCompanyJSON.Image : 'N/A',
        size: foundCompetitorCompanyInCompanyJSON.employees ? foundCompetitorCompanyInCompanyJSON.employees : 'N/A',
        name: foundCompetitorCompanyInCompanyJSON.name ? foundCompetitorCompanyInCompanyJSON.name : 'N/A',
        revenue: foundCompetitorCompanyInCompanyJSON.revenue ? foundCompetitorCompanyInCompanyJSON.revenue : 'N/A',
        overallMomentum: foundCompetitorCompanyInCompanyJSON.momentum ? Math.floor(foundCompetitorCompanyInCompanyJSON.momentum.overall) : undefined,
        isStarred: foundCompetitorCompanyInCompanyJSON.star,
      };
      listOfCompetitors.push(newCompetitorResult);
    } else {
      return [];
    }
  });
  return listOfCompetitors;
}

export function mapAILSAPreferencesWithJSON(ailsaPreferencesString: string): AILSAPreferences {
  const ailsaPreferencesJSON = JSON.parse(ailsaPreferencesString);
  return {
    activeWidgets: ailsaPreferencesJSON.activeWidgets,
    isWidgetsHidden: ailsaPreferencesJSON.isWidgetsHidden,
  };
}
