import React from 'react';
import moment from 'moment';
import { NewsEventArticle } from '../../Models/types';
import './event-article.scss';
import { StructuredListRow, StructuredListCell, Tooltip } from 'carbon-components-react';
import SourceIcon from '../SourceIcon/SourceIcon';

interface Props {
  article: NewsEventArticle;
}

const EventArticle: React.FC<Props> = ({ article }) => {
  function formatURL() {
    if (!/^https?:\/\//i.test(article.url)) {
      article.url = 'http://' + article.url;
    }
    return article.url;
  }

  return (
    <>
      <StructuredListRow tabIndex={0}>
        <StructuredListCell>
          {article.source !== 'factiva' ? (
            <a href={formatURL()} target="_blank" rel="noopener noreferrer">
              {article.title}
            </a>
          ) : (
            article.title
          )}
          <Tooltip direction="bottom" tabIndex={0} triggerText={`, ${moment(article.datePublished).format('YYYY-MM-DD')}, ${article.publication}`}>
            <p>{article.summary}</p>
          </Tooltip>
          <SourceIcon source={article.source} />
        </StructuredListCell>
      </StructuredListRow>
    </>
  );
};

export default EventArticle;
