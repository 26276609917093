import React, { useContext } from 'react';
import './widgets-container.scss';
import AutoScale from 'react-auto-scale';
import { WidgetId } from '../../../Models/types';
import { MarketReferenceArchitecture } from '../../ReferenceArchitecture/MarketReferenceArchitecture/MarketReferenceArchitecture';
import FoamTreeWidget from '../../FoamTree/FoamTreeWidget';
import { StudyContext } from '../../../Contexts/StudyProvider';
import MapWidget from '../MapWidget/MapWidget';
import ScatterChart from '../../ScatterChartWidget/ScatterChart';
import Widget from '../Widget/Widget';

export interface WidgetContainerProps {
  handleAddWidgetToActiveWidgets?: (widgetId: WidgetId) => void;
  handleRemoveWidgetFromActiveWidgets?: (widgetId: WidgetId) => void;
  isActiveWidgets: boolean;
  isEditingWidgets: boolean;
  initialWidth: number;
  widgetIds: WidgetId[];
  sidebarWidth: number;
}

export const WidgetsContainer: React.FC<WidgetContainerProps> = ({
  handleAddWidgetToActiveWidgets,
  handleRemoveWidgetFromActiveWidgets,
  isEditingWidgets,
  isActiveWidgets,
  widgetIds,
  initialWidth,
  sidebarWidth,
}) => {
  const { clusteredNGrams, referenceArchitecture, filteredStudyCompanies } = useContext(StudyContext);

  // Check if sufficient data exists in some (1+) of the displayed companies
  // in order to show the scatter chart
  // TODO: Look at removing this variable to better optimize performance.
  const shouldShowScatter =
    filteredStudyCompanies.filter((company) => {
      return typeof company.foundedYear === 'number' && typeof company.professionalEmployeeEstimate === 'number';
    }).length > 1;

  function getWidgetById(id: WidgetId) {
    switch (id) {
      case WidgetId.MARKET_REFERENCE_ARCHITECTURE:
        return referenceArchitecture && sidebarWidth ? (
          <Widget
            id={id}
            isActive={isActiveWidgets}
            isEditing={isEditingWidgets}
            handleAddWidget={(widgetId) => handleAddWidgetToActiveWidgets!(widgetId)}
            handleRemoveWidget={(widgetId) => handleRemoveWidgetFromActiveWidgets!(widgetId)}
          >
            <div key={id} className="widgets-container__item-wrapper">
              <div style={{ width: sidebarWidth - 73 }} className="widgets-container__item">
                <AutoScale>
                  <MarketReferenceArchitecture />
                </AutoScale>
              </div>
            </div>
          </Widget>
        ) : null;
      case WidgetId.FOAM_TREE:
        return clusteredNGrams.length && sidebarWidth ? (
          <Widget
            id={id}
            isActive={isActiveWidgets}
            isEditing={isEditingWidgets}
            handleAddWidget={(widgetId) => handleAddWidgetToActiveWidgets!(widgetId)}
            handleRemoveWidget={(widgetId) => handleRemoveWidgetFromActiveWidgets!(widgetId)}
          >
            <div key={id} className="widgets-container__item-wrapper">
              <div style={{ width: sidebarWidth - 73 }} className="widgets-container__item">
                <FoamTreeWidget width={sidebarWidth - 73 || initialWidth} data={clusteredNGrams} />
              </div>
            </div>
          </Widget>
        ) : null;
      case WidgetId.MAP:
        return (
          sidebarWidth && (
            <Widget
              id={id}
              isActive={isActiveWidgets}
              isEditing={isEditingWidgets}
              handleAddWidget={(widgetId) => handleAddWidgetToActiveWidgets!(widgetId)}
              handleRemoveWidget={(widgetId) => handleRemoveWidgetFromActiveWidgets!(widgetId)}
            >
              <div key={id} className="widgets-container__item-wrapper">
                <div style={{ width: sidebarWidth - 73, height: sidebarWidth }} className="widgets-container__item">
                  <MapWidget width={sidebarWidth - 73} height={sidebarWidth} />
                </div>
              </div>
            </Widget>
          )
        );
      case WidgetId.SCATTER_CHART:
        return shouldShowScatter && sidebarWidth ? (
          <Widget
            id={id}
            isActive={isActiveWidgets}
            isEditing={isEditingWidgets}
            handleAddWidget={(widgetId) => handleAddWidgetToActiveWidgets!(widgetId)}
            handleRemoveWidget={(widgetId) => handleRemoveWidgetFromActiveWidgets!(widgetId)}
          >
            <div key={id} className="widgets-container__item-wrapper">
              <div style={{ width: sidebarWidth - 73, overflow: 'visible' }} className="widgets-container__item">
                <ScatterChart width={sidebarWidth - 73} />
              </div>
            </div>
          </Widget>
        ) : null;
      default:
        return null;
    }
  }

  return !widgetIds.length ? null : <div className="widgets-container__container _not-resizable">{widgetIds.map((id) => getWidgetById(id))}</div>;
};
