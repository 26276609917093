import React, { useState, useEffect } from 'react';
import { SkeletonPlaceholder } from 'carbon-components-react';
import { Charts, ChartContainer, ChartRow, YAxis, Resizable, EventChart, Baseline } from 'react-timeseries-charts';
import { TimeRange, TimeRangeEvent, TimeSeries } from 'pondjs';
import { categoryColors } from '../../Helpers/colors';
import { NewsEvent } from '../../Models/types';

const baselineStyle = {
  line: {
    stroke: 'steelblue',
    strokeWidth: 2,
    opacity: 0.5,
    strokeDasharray: '1,3',
  },
  label: {
    fill: 'steelblue',
  },
};
function getStyleForEvent(event, state) {
  let color = categoryColors[event.get('category')];
  switch (state) {
    case 'normal':
      return {
        fill: color,
      };
    case 'hover':
      return {
        fill: color,
        opacity: 0.4,
      };
    case 'selected':
      return {
        fill: color,
      };
  }
}

interface Props {
  isLoading: boolean;
  events: NewsEvent[];
  filteredEvents: NewsEvent[];
}

const EventTimeline: React.FC<Props> = ({ isLoading, events, filteredEvents }) => {
  const [eventSeries, setEventSeries] = useState<TimeSeries | undefined>(undefined);
  const [brushTimeRange, setBrushTimeRange] = useState<TimeRange | undefined>(undefined);

  function changeTimeRange(brushTimeRange) {
    setBrushTimeRange(brushTimeRange);
  }

  useEffect(() => {
    if (isLoading) return;
    // Check if using filteredEvents or Non-filtered events
    let sortedEventsIntoTimeRange;
    let eventTimeSeries;

    if (filteredEvents.length) {
      sortedEventsIntoTimeRange = filteredEvents
        .sort((a, b) => new Date(a.startDate).getTime() - new Date(b.startDate).getTime())
        .map(({ startDate, endDate, ...data }) => new TimeRangeEvent(new TimeRange(new Date(startDate), new Date(endDate)), data));
      eventTimeSeries = new TimeSeries({ name: 'events', events: sortedEventsIntoTimeRange });
    } else {
      sortedEventsIntoTimeRange = events
        .sort((a, b) => new Date(a.startDate).getTime() - new Date(b.startDate).getTime())
        .map(({ startDate, endDate, ...data }) => new TimeRangeEvent(new TimeRange(new Date(startDate), new Date(endDate)), data));
      eventTimeSeries = new TimeSeries({ name: 'events', events: sortedEventsIntoTimeRange });
    }

    setEventSeries(eventTimeSeries);
    setBrushTimeRange(eventTimeSeries.timerange());
  }, [isLoading, filteredEvents, events]);

  return !isLoading ? (
    <>
      {events.length > 0 ? (
        <>
          <Resizable>
            <ChartContainer enablePanZoom onTimeRangeChanged={changeTimeRange} timeRange={brushTimeRange}>
              <ChartRow height="400" trackerInfoHeight={25}>
                <YAxis id="y" label="Impact" min={-10} max={10} format="00" />
                <Charts>
                  <EventChart axis="y" series={eventSeries} style={getStyleForEvent} label={(e) => e.get('title')} size={10} />
                  <Baseline axis="y" value={0} label="Events above this line increase risk" position="right" style={baselineStyle} />
                </Charts>
              </ChartRow>
            </ChartContainer>
          </Resizable>
        </>
      ) : (
        <>No Events</>
      )}
    </>
  ) : (
    <>
      <SkeletonPlaceholder className="chart-skeleton-placeholder__placeholder--large" />
    </>
  );
};

export default React.memo(EventTimeline);
