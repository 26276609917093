import React from 'react';
import './ac-header.scss';
import { Header, HeaderName, SkipToContent, HeaderGlobalBar, HeaderGlobalAction } from 'carbon-components-react';
import { Exit20 } from '@carbon/icons-react';
import ForestreetLogo from '../../Assets/logo.svg';
import { UserContext } from '../../Contexts/UserProvider';
import UserAvatar from '../UserAvatar/UserAvatar';
import RequestDemoButton from '../Demo/RequestDemoButton';

const HeaderBar = () => {
  return (
    <Header aria-label="AILSA by Forestreet">
      <SkipToContent />
      <HeaderName href="/" prefix="" style={{ fontSize: '18px' }}>
        <img src={ForestreetLogo} className="logo" alt="Forestreet logo" />
        AILSA
      </HeaderName>
      <UserContext.Consumer>
        {({ signOut, currentUser }) => (
          <HeaderGlobalBar>
            {process.env.REACT_APP_IS_DEMO === 'false' ? (
              <>
                <UserAvatar firstName={currentUser?.firstName} lastName={currentUser?.lastName} />
                <HeaderGlobalAction aria-label="App Switcher" onClick={signOut}>
                  <Exit20 />
                </HeaderGlobalAction>
              </>
            ) : (
              <RequestDemoButton />
            )}
          </HeaderGlobalBar>
        )}
      </UserContext.Consumer>
    </Header>
  );
};

export default HeaderBar;
