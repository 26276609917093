import React, { useEffect, useContext, useState } from 'react';
import EventsList from '../EventsList/EventsList';
import { CompanyMetadata, NewsEvent } from '../../Models/types';
import { MultiSelect } from 'carbon-components-react';
import EventTimeline from '../EventTimeline/EventTimeline';
import { StudyContext } from '../../Contexts/StudyProvider';

interface Props {
  data: CompanyMetadata[];
}

const MarketExplorerEvents: React.FC<Props> = ({ data }) => {
  const { events, isLoadingEvents, getEvents, getArticlesWithIdForNewsEvent } = useContext(StudyContext);
  const [availableCategoryFilters, setAvailableCategoryFilters] = useState<{ id: number; text: string }[]>([]);
  const [chosenCategoryFilters, setChosenCategoryFilters] = useState<{ id: number; text: string }[]>([]);
  const [filteredEvents, setFilteredEvents] = useState<NewsEvent[]>([]);

  useEffect(() => {
    if (!chosenCategoryFilters.length) {
      setFilteredEvents([]);
    }
    const filteredEventsWithCategories = events.filter((newsEvent) => chosenCategoryFilters.map((filter) => filter.text).includes(newsEvent.category!));
    setFilteredEvents(filteredEventsWithCategories);
  }, [chosenCategoryFilters]);

  useEffect(() => {
    const foundCategories = events.map((newsEvent) => newsEvent.category).filter((category) => category !== undefined);
    const uniqueCategories = new Set(foundCategories);
    const arrayFromSet = Array.from(uniqueCategories);
    const dictionaryOfCategories = arrayFromSet.map((item, index) => {
      return {
        id: index,
        text: item!,
      };
    });
    setAvailableCategoryFilters(dictionaryOfCategories);
  }, [events]);

  useEffect(() => {
    getEvents();
  }, [data]);

  return (
    <div style={{ paddingTop: '20px' }}>
      <MultiSelect items={availableCategoryFilters} itemToString={(item) => (item ? item.text : '')} onChange={(e) => setChosenCategoryFilters(e.selectedItems)} />
      <EventTimeline isLoading={isLoadingEvents} events={events} filteredEvents={filteredEvents} />
      <EventsList isInNewsEventsView={true} maxHeight={38} isLoading={isLoadingEvents} events={events} filteredEvents={filteredEvents} handleGetArticlesForNewsEvent={getArticlesWithIdForNewsEvent} />
    </div>
  );
};

export default MarketExplorerEvents;
