import React, { useEffect, useMemo, useState } from 'react';
import './bubble-chart-wrapper.scss';
import AutoSizer from 'react-virtualized-auto-sizer';
import * as d3 from 'd3';
import BubbleCanvas from './BubbleCanvas';
import { useHistory, useParams } from 'react-router-dom';
import { CompanyMetadata, StudyFilterId } from '../../../Models/types';
import useDebounce from '../../../Hooks/useDebounce';
import { getColorFunction, getSizeFunction } from '../../../Helpers/legend.helper';
import { ColorCodeModel, SizeCodeModel } from '../../../Models/types';
import { Select, SelectItem, Toggle } from 'carbon-components-react';
import LegendColor from '../Legend/legendColor';
import LegendSize from '../Legend/legendSize';
import DemoBanner from '../../Demo/DemoBanner';

export interface BubbleChartProps {
  data: CompanyMetadata[];
  filteredData: CompanyMetadata[];
  imagesChecked: boolean;
}
const COLOR_CODES: ColorCodeModel[] = [
  { name: 'Color not set', attr: null },
  { name: 'Employees', attr: StudyFilterId.EMPLOYEES },
  { name: 'Momentum', attr: StudyFilterId.MOMENTUM },
];
const SIZE_CODES: ColorCodeModel[] = [
  { name: 'Size not set', attr: null },
  { name: 'Employees', attr: StudyFilterId.EMPLOYEES },
  { name: 'Momentum', attr: StudyFilterId.MOMENTUM },
];

const BubbleChart: React.FC<BubbleChartProps> = (props) => {
  const [sizeCode, setSizeCode] = useState<SizeCodeModel>(SIZE_CODES[0]);
  const [colorCode, setColorCode] = useState<ColorCodeModel>(COLOR_CODES[0]);
  const [size, setSize] = useState();
  const [isBubblesClustered, setIsBubblesClustered] = useState(false);

  const colorFunction = useMemo(() => getColorFunction(colorCode.attr, props.data), [colorCode]);
  const sizeFunction = useMemo(() => getSizeFunction(sizeCode.attr, props.data, size), [sizeCode, size]);

  const changeColorCode = (name: string) => {
    const code = COLOR_CODES.find((version) => version.name === name);
    setColorCode(code!);
  };

  const changeSizeCode = (name: string) => {
    const code = SIZE_CODES.find((version) => version.name === name);
    setSizeCode(code!);
  };

  return (
    <div className="bubble-chart__wrapper">
      <div style={{ marginLeft: '10px' }}>
        <div className="ux-main__view-content__color-container">
          <Select
            labelText={null}
            light
            value={colorCode && colorCode.name}
            onChange={(e) => changeColorCode(e.target.value)}
            style={{
              width: 'auto',
              borderBottom: 'none',
            }}
          >
            {COLOR_CODES.map((code) => (
              <SelectItem value={code.name} text={code.name} />
            ))}
          </Select>
          <LegendColor code={colorCode} filteredData={props.filteredData} data={props.data} />
        </div>
        <div className="ux-main__view-content__size-container">
          <Select
            labelText={false}
            light
            value={sizeCode && sizeCode!.name}
            onChange={(e) => changeSizeCode(e.target.value)}
            style={{
              width: 'auto',
              borderBottom: 'none',
            }}
          >
            {SIZE_CODES.map((size) => (
              <SelectItem value={size.name} text={size.name} />
            ))}
          </Select>
          <LegendSize code={sizeCode} filteredData={props.filteredData} data={props.data} sizeFunc={sizeFunction} />
        </div>
        <div className="bubble-chart__control">
          <Toggle aria-label="toggle button" defaultToggled id="toggle-1" labelText="Clustered" toggled={isBubblesClustered} onToggle={() => setIsBubblesClustered(!isBubblesClustered)} />
        </div>
      </div>
      <div className="bubble-chart__container">
        <AutoSizer>
          {({ height, width }) => (
            <BubbleChartContainer
              height={height}
              colorFunction={colorFunction}
              sizeFunction={sizeFunction}
              width={width}
              {...props}
              data={props.filteredData}
              color={colorCode}
              size={sizeCode}
              onSizeChange={setSize}
              isClustered={isBubblesClustered}
            />
          )}
        </AutoSizer>
      </div>
      {process.env.REACT_APP_IS_DEMO === 'true' && <DemoBanner position="fixed" />}
    </div>
  );
};

export interface BubbleChartContainerProps {
  data: CompanyMetadata[];
  imagesChecked: boolean;
  width: number;
  height: number;
  color: ColorCodeModel;
  size: SizeCodeModel;
  onSizeChange?: Function;
  colorFunction: Function;
  sizeFunction: Function;
  isClustered: boolean;
}

export const BubbleChartContainer: React.FC<BubbleChartContainerProps> = ({ data, isClustered, width: widthFromProps, height, color, size, onSizeChange, colorFunction, sizeFunction }) => {
  const history = useHistory();
  const { studyId } = useParams<{ studyId: string }>();
  const [onlyLeaves, setOnlyLeaves] = useState([]);
  const [clusterLevel, setClusterLevel] = useState([]);
  const [width, setWidth] = useState(widthFromProps);
  const debounceWidth = useDebounce(width, 200);

  useEffect(() => {
    const { onlyLeaves, clusterLevel } = calculatePacking();
    setOnlyLeaves(onlyLeaves);
    setClusterLevel(clusterLevel);
    onSizeChange && onSizeChange(width > debounceWidth ? height : debounceWidth);
  }, [debounceWidth, color, size, data, height, sizeFunction, isClustered]);

  useEffect(() => {
    setWidth(widthFromProps);
  }, [widthFromProps]);

  const calculatePacking = () => {
    // I've used NO_GROUP below to force the bubbles to all group together under a common banner which doesn't exist.
    const rootMap = isClustered ? d3.group(data, (d) => d.cluster) : d3.group(data, (d) => d.NO_GROUP);
    const rootArr = Array.from(rootMap, ([key, value]) => ({
      header: key,
      children: value,
      Label: key,
      id: key,
      count: value.length,
    }));

    const colorFunc = colorFunction;
    const sizeFunc = sizeFunction;

    const sortedArr = rootArr.sort((a, b) => d3.descending(a.header, b.header));

    const root = {
      id: 'root',
      Label: 'root',
      children: sortedArr,
    };

    const pack = (data) =>
      d3
        .pack()
        .size([width, height])
        .radius(sizeFunc(0) === null ? null : (d) => sizeFunc(d))
        .padding(3)(d3.hierarchy(data).sum((d) => 1));
    const circlePack = pack(root);
    const onlyLeaves = circlePack
      .descendants()
      .filter((d) => d.height === 0)
      .map((d) => {
        d.color = colorFunc(d);
        return d;
      });
    const clusterLevel = circlePack.descendants().filter((d) => d.height === 1);
    return { onlyLeaves, clusterLevel };
  };

  function handleClickBubble(companyName) {
    history.push(`/study/${studyId}/company/${companyName}`);
  }

  return <BubbleCanvas data={onlyLeaves} clusterNames={clusterLevel} width={width} height={height} clickHandler={handleClickBubble} />;
};

export default BubbleChart;
