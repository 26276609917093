import { DEFAULT_COLOR, ECompareValueType, ICompareValue, IDataRow, IGroup, SetColumn, toolbar } from 'lineupjs';
import type { ISequence } from 'lineupjs/build/src/internal';

@toolbar('sortGroupBy')
export default class CustomSetColumn extends SetColumn {
  group(row: IDataRow): IGroup {
    const v = this.getSet(row);
    const cardinality = v.size;
    const categories = this.categories.filter((c) => v.has(c));
    const maxCardinality = this.categories.length;

    // by cardinality and then by intersection

    const g: IGroup = {
      name: categories.length === 0 ? 'None' : categories.map((d) => d.name).join(', '),
      color: categories.length === 1 ? categories[0].color : DEFAULT_COLOR,
    };

    g.parent = {
      name: `${(maxCardinality - cardinality).toString().padStart(3, '0')} ∩ Degree ${cardinality}`,
      color: DEFAULT_COLOR,
      subGroups: [g],
    };

    return g;
  }

  toCompareGroupValue(rows: ISequence<IDataRow>, group: IGroup): ICompareValue[] {
    const degree = rows.reduce((degree, row) => Math.max(degree, this.getSet(row).size), 0);
    // by degree then by the group name
    return [degree, group.name];
  }

  toCompareGroupValueType() {
    return [ECompareValueType.COUNT, ECompareValueType.STRING];
  }
}
