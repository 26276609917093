import React from 'react';
import './table.scss';
import { TableContainer, DataTable, Table, TableHead, TableRow, TableExpandRow, TableExpandedRow, TableHeader, TableExpandHeader, TableBody, TableCell } from 'carbon-components-react';
import { Competitor } from '../../Models/types';
import { CompetitorScoreCell, LogoCell, LinkCell, MomentumCell, WebsiteCell } from './CellTypes';

const headers = [
  {
    key: 'logoURL',
    header: '',
  },
  {
    key: 'name',
    header: 'Name',
  },
  {
    key: 'score',
    header: 'Similarity Score',
  },
  {
    key: 'overallMomentum',
    header: 'Momentum',
  },
  {
    key: 'size',
    header: 'Size',
  },
  {
    key: 'hqCountry',
    header: 'HQ',
  },
];

interface Props {
  data?: Competitor[];
}

const TableCompanyCompetitors: React.FC<Props> = ({ data }) => {
  return data ? (
    <>
      <div className="table-market-explorer-wrapper">
        <DataTable responsive isSortable rows={data} headers={headers} size="short">
          {({ rows, headers, getTableProps, getHeaderProps, getRowProps }) => (
            <TableContainer>
              <Table {...getTableProps()}>
                <TableHead>
                  <TableRow>
                    <TableExpandHeader />
                    {headers.map((header) => (
                      <TableHeader {...getHeaderProps({ header })}>{header.header}</TableHeader>
                    ))}
                  </TableRow>
                </TableHead>
                {data.length > 0 ? (
                  <TableBody>
                    {rows.map((row) => {
                      return (
                        <>
                          <TableExpandRow {...getRowProps({ row })}>
                            {row.cells.map((cell) => {
                              if (cell.info.header === 'name') {
                                return <LinkCell cell={cell} />;
                              }
                              if (cell.info.header === 'score') {
                                return <CompetitorScoreCell cell={cell} />;
                              }
                              if (cell.info.header === 'logoURL') {
                                return <LogoCell cell={cell} isFavourite={false} isStarredByForestreet={false} />;
                              }
                              if (cell.info.header === 'overallMomentum') {
                                return <MomentumCell cell={cell} />;
                              }
                              if (cell.info.header === 'id') {
                                return <WebsiteCell cell={cell} />;
                              }
                              return <TableCell key={cell.id}>{cell.value}</TableCell>;
                            })}
                          </TableExpandRow>
                          <TableExpandedRow colSpan={headers.length + 1}>
                            <strong>
                              <h6>Description</h6>
                            </strong>
                            <div>{data.filter((competitor) => competitor.id === row.id)[0].description}</div>
                          </TableExpandedRow>
                        </>
                      );
                    })}
                  </TableBody>
                ) : (
                  <p>No competitors.</p>
                )}
              </Table>
            </TableContainer>
          )}
        </DataTable>
      </div>
    </>
  ) : (
    <>No Competitor Data</>
  );
};

export default TableCompanyCompetitors;
