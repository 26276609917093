import React, { useContext } from 'react';
import classNames from 'classnames';
import { Star16, StarFilled16 } from '@carbon/icons-react';
import './favourite-button.scss';
import { StudyContext } from '../../Contexts/StudyProvider';

interface Props {
  companyId?: string;
  isFavourite: boolean;
  isStarredByForestreet: boolean;
}

const FavouriteButton: React.FC<Props> = ({ companyId, isFavourite, isStarredByForestreet }) => {
  const { toggleFavourite } = useContext(StudyContext);

  return companyId ? (
    <span
      className={classNames({
        'favourite-button': true,
        'filled-favourite': isFavourite,
        'filled-forestreet-favourite': !isFavourite && isStarredByForestreet,
      })}
    >
      {isFavourite && <StarFilled16 onClick={() => toggleFavourite(companyId)} />}
      {!isFavourite && isStarredByForestreet && <StarFilled16 onClick={() => toggleFavourite(companyId)} />}
      {!isFavourite && !isStarredByForestreet && <Star16 onClick={() => toggleFavourite(companyId)} />}
    </span>
  ) : null;
};

export default FavouriteButton;
