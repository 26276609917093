import React, { useState, useEffect, useContext } from 'react';
import useTagsAPI from '../Data/useTagsAPI';
import { Tag } from '../Models/types';
import { StudyContext } from './StudyProvider';
import { deleteTagFromDB } from '../Data/api';
import useAnalytics, { ANALYTICS_ACTION, ANALYTICS_CATEGORY } from '../Hooks/useAnalytics';

type ContextProps = {
  isLoadingTags: boolean;
  isCreatingTag: boolean;
  isDeletingTag: boolean;
  createNewTag: (label: string, hexColor: string) => void;
  getTags: () => void;
  availableTags: Tag[];
  assignTag: (tagId: number, companyId: string) => void;
  unassignTag: (tagId: number, companyId: string) => void;
  deleteTag: (tagLabel: string) => void;
};

export const TagsContext = React.createContext<ContextProps>({
  isLoadingTags: true,
  isCreatingTag: false,
  isDeletingTag: false,
  createNewTag: () => {},
  getTags: () => {},
  availableTags: [],
  assignTag: () => {},
  unassignTag: () => {},
  deleteTag: () => {},
});

interface Props {
  studyId: number;
  studyUserGroupId: number;
}

const TagsProvider: React.FC<Props> = ({ children, studyId, studyUserGroupId }) => {
  const { trackEvent } = useAnalytics();
  const { createNewUserTag, getAllTags, assignTagToCompany, unassignTagFromCompany } = useTagsAPI();
  const { getCompanies } = useContext(StudyContext);
  const [availableTags, setAvailableTags] = useState<Tag[]>([]);
  const [isCreatingTag, setIsCreatingTag] = useState(false);
  const [isDeletingTag, setIsDeletingTag] = useState(false);
  const [isLoadingTags, setIsLoadingTags] = useState(true);

  async function createNewTag(label: string, hexColor: string) {
    trackEvent(ANALYTICS_CATEGORY.STUDY, `Tag: ${label}`, ANALYTICS_ACTION.CREATE);
    setIsCreatingTag(true);
    try {
      await createNewUserTag(studyId, studyUserGroupId, label, hexColor);
      setIsCreatingTag(false);
    } catch (e) {
      setIsCreatingTag(false);
    } finally {
      await getTags();
    }
  }

  async function getTags() {
    setIsLoadingTags(true);
    try {
      const tagsResult = await getAllTags(studyId, studyUserGroupId);
      setAvailableTags(tagsResult);
    } catch (e) {
      console.log(e);
    }
    setIsLoadingTags(false);
  }

  async function assignTag(tagId: number, companyId: string) {
    try {
      trackEvent(ANALYTICS_CATEGORY.STUDY, `Tag to company with companyId ${companyId}`, ANALYTICS_ACTION.ASSIGN);
      await assignTagToCompany(tagId, companyId);
      // Locally assign the tag to the company without doing a server refresh
      await getCompanies();
    } catch (e) {
      console.log(e);
    }
  }

  async function unassignTag(tagId: number, companyId: string) {
    try {
      trackEvent(ANALYTICS_CATEGORY.STUDY, `Tag from company with companyId ${companyId}`, ANALYTICS_ACTION.UNASSIGN);
      await unassignTagFromCompany(tagId, companyId);
      // Locally assign the tag to the company without doing a server refresh
      await getCompanies();
    } catch (e) {
      console.log(e);
    }
  }

  async function deleteTag(tagLabel: string) {
    // Passing tag label as a parameter here due to how Filters work. Ideally I would have passed Id, but I don't have access to it.
    // Therefore, we have to find the tagId using the tagLabel from the available tags. It's fine though because you can't create a tag
    // with the same name as another tag in the same study ID or study user group ID
    const foundTagId = availableTags.find((tag) => tag.label === tagLabel)?.id;
    setIsDeletingTag(true);
    try {
      trackEvent(ANALYTICS_CATEGORY.STUDY, `Tag: ${tagLabel}`, ANALYTICS_ACTION.DELETE);
      await deleteTagFromDB(foundTagId!);
      await getCompanies();
    } catch (e) {
      console.log(e);
    }
    setIsDeletingTag(false);
  }

  async function init() {
    await getTags();
  }

  useEffect(() => {
    if (!studyId || !studyUserGroupId) return;
    init();
  }, [studyId, studyUserGroupId]);

  return (
    <TagsContext.Provider
      value={{
        isCreatingTag,
        isLoadingTags,
        isDeletingTag,
        createNewTag,
        getTags,
        availableTags,
        assignTag,
        unassignTag,
        deleteTag,
      }}
    >
      {children}
    </TagsContext.Provider>
  );
};

export default TagsProvider;
