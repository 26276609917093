import React, { useEffect, useRef } from 'react';
import { Circle, Group, Text } from 'react-konva';

interface Props {
  key: any;
  data: any;
  clickHandler: Function;
  showTooltip: Function;
}

const BubbleNode: React.FC<Props> = (props) => {
  const shapeRef = useRef<any>(null);
  const textRef = useRef<any>(null);
  const { key, data, showTooltip, clickHandler } = props;

  const updateCircle = () => {
    const label = props.data.data.name;

    if (!textRef.current.x()) {
      textRef.current.move({
        x: props.data.x,
        y: props.data.y,
      });
    }
    textRef.current.width((props.data.r - 2) * 2);
    textRef.current.align('center');
    textRef.current.offsetX(props.data.r - 1);
    textRef.current.offsetY(4);
    textRef.current.to({
      x: props.data.x,
      y: props.data.y,
      opacity: 1,
      duration: 0.5,
    });
    if (shapeRef) {
      if (!shapeRef.current.x() && !props.data.color) {
        const imageObj = new Image();
        imageObj.src = props.data.data.logoURL || '';

        imageObj.onload = function () {
          textRef.current && textRef.current.text('');
          shapeRef.current && shapeRef.current.fillPatternImage(imageObj);
          shapeRef.current && shapeRef.current.fillPatternOffset({ x: -imageObj.width / 2, y: -imageObj.height / 2 });
        };
        imageObj.onerror = function () {
          textRef.current && textRef.current.text(label);
          shapeRef.current && shapeRef.current.fill(props.data.color || '#fff');
        };
        shapeRef.current.move({
          x: props.data.x,
          y: props.data.y,
        });
        shapeRef.current.radius(0);
        shapeRef.current.opacity(0);
        shapeRef.current.to({
          radius: props.data.r,
          opacity: 1,
          duration: 0.5,
        });
      }

      shapeRef.current.to({
        x: props.data.x,
        y: props.data.y,
        radius: props.data.r,
        duration: 0.5,
      });
      if (props.data.color !== null) {
        shapeRef.current.fill(props.data.color);
        textRef.current.text(label);
      }
      if (shapeRef.current.getAttr('fill')) {
        const fill = props.data.color || '#fff';
        shapeRef.current && shapeRef.current.fill(fill);
        shapeRef.current && shapeRef.current.fillPriority('color');
        textRef.current.text(label);
      } else {
        shapeRef.current && shapeRef.current.fillPriority('pattern');
        textRef.current.text('');
      }
      if (shapeRef.current.getAttr('fillPatternImage') && props.data.color === null) {
        shapeRef.current && shapeRef.current.fillPriority('pattern');
        textRef.current.text('');
      }
    }
  };

  useEffect(() => {
    updateCircle();
  }, [data, data.data, shapeRef]);

  const onMouseMoveHandler = (e) => {
    const mousePos = e.currentTarget.getStage().getPointerPosition();
    showTooltip({
      pos: mousePos,
      text: data.data.id,
    });
  };

  const onMouseOutHandler = () => {
    showTooltip(false);
  };

  const onClickHandler = () => {
    clickHandler(data.data.name);
  };

  const circleProps = {
    key: key,
    ref: shapeRef,
    stroke: '#000',
    strokeWidth: 0.5,
    onmousemove: onMouseMoveHandler,
    onmouseout: onMouseOutHandler,
    onclick: onClickHandler,
    perfectDrawEnabled: false,
    radius: 0,
  };

  return (
    <Group>
      <Circle {...circleProps} />
      <Text ref={textRef} opacity={0} wrap={'none'} listening={false} />
    </Group>
  );
};

export default BubbleNode;
