import React from 'react';
import './user-tag.scss';
import Color from 'color';

interface Props {
  label: string;
  hexColor: string;
}

const UserTag: React.FC<Props> = ({ label, hexColor }) => {
  return hexColor ? (
    <div
      className="user-tag"
      style={{
        color: Color(`${hexColor}`).darken(0.8).hex(),
        background: Color(`${hexColor}`).lighten(0.3).hex(),
      }}
    >
      {label}
    </div>
  ) : null;
};

export default UserTag;
