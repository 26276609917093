import React from 'react';
import './user-avatar.scss';

interface Props {
  firstName?: string;
  lastName?: string;
}

const UserAvatar: React.FC<Props> = ({ firstName, lastName }) => {
  return (
    <div className="user-avatar">
      <div className="circle">
        {firstName?.slice(0, 1)}
        {lastName?.slice(0, 1)}
      </div>
    </div>
  );
};

export default UserAvatar;
