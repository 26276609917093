import React, { useState } from 'react';
import './company-view.scss';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Select from 'react-select';
import { Breadcrumb, BreadcrumbItem, Tag, Tooltip, ComposedModal, ModalHeader, ModalBody } from 'carbon-components-react';
import GaugeChart from 'react-gauge-chart';
import CompanyProvider, { CompanyContext } from '../../Contexts/CompanyProvider';
import { Link } from 'react-router-dom';
import { StudyContext } from '../../Contexts/StudyProvider';
import { Finance16, Activity16, Bullhorn16, ChartRelationship16, Calendar16, Enterprise16, Location16, Product16, Wikis16 } from '@carbon/icons-react';
import EventsList from '../../Components/EventsList/EventsList';
import EventTimeline from '../../Components/EventTimeline/EventTimeline';
import CompanyReferenceArchitecture from '../../Components/ReferenceArchitecture/CompanyReferenceArchitecture/CompanyReferenceArchitecture';
import { dollarCurrencyFormatter } from '../../Helpers/utils';
import MomentumScore from '../../Components/MomentumScore/MomentumScore';
import CardCompanySection from '../../Components/Cards/CardCompanySection/CardCompanySection';
import TableCompanyCompetitors from '../../Components/Tables/TableCompanyCompetitors';
import LoadingSkeletonView from '../../Components/LoadingSkeleton/LoadingSkeletonView';
import CompetingReferenceArchitectureLegend from '../../Components/ReferenceArchitecture/CompanyReferenceArchitecture/CompetingReferenceArchitectureLegend';
import { CompanyMetadata } from '../../Models/types';
import useAnalytics, { ANALYTICS_ACTION, ANALYTICS_CATEGORY } from '../../Hooks/useAnalytics';

const CompanyView = () => {
  const { trackEvent } = useAnalytics();
  const [openDescriptionModal, setOpenDescriptionModal] = useState(false);
  const [openTagsModal, setOpenTagsModal] = useState(false);
  const [selectedCompetitor, setSelectedCompetitor] = useState<CompanyMetadata | undefined>(undefined);

  return (
    <StudyContext.Consumer>
      {({ selectedStudy, studyCompanies }) => (
        <div className="company-view">
          <CompanyProvider>
            <CompanyContext.Consumer>
              {({ isLoading, isLoadingEvents, company, referenceArchitecture, competingReferenceArchitecture, getCompetingReferenceArchitecture, events, getArticlesWithIdForNewsEvent }) => (
                <>
                  <div className="bx--grid bx--grid--full-width no-gutter main-view-layout">
                    {isLoading ? (
                      <LoadingSkeletonView />
                    ) : (
                      <>
                        <ComposedModal open={openDescriptionModal} onClose={() => setOpenDescriptionModal(false)}>
                          <ModalHeader />
                          <ModalBody>
                            <p style={{ fontSize: '.8rem' }} className="bx--modal-content__text">
                              {company?.description}
                            </p>
                          </ModalBody>
                        </ComposedModal>
                        <ComposedModal open={openTagsModal} onClose={() => setOpenTagsModal(false)}>
                          <ModalHeader />
                          <ModalBody>
                            <p className="bx--modal-content__text">
                              {company?.tags.map((tag) => (
                                <Tag type="cool-gray" title={tag}>
                                  {tag}
                                </Tag>
                              ))}
                            </p>
                          </ModalBody>
                        </ComposedModal>
                        <div className="company-view__navigation-bar">
                          <div className="bx--row">
                            <Breadcrumb noTrailingSlash isCurrentPage>
                              <BreadcrumbItem>
                                <Link
                                  to={{
                                    pathname: `/studies`,
                                  }}
                                >
                                  Studies
                                </Link>
                              </BreadcrumbItem>
                              <BreadcrumbItem>
                                <Link
                                  to={{
                                    pathname: `/study/${selectedStudy?.id}/explorer`,
                                  }}
                                >
                                  {selectedStudy?.name}
                                </Link>
                              </BreadcrumbItem>
                              <BreadcrumbItem>
                                <>{company?.name}</>
                              </BreadcrumbItem>
                            </Breadcrumb>
                          </div>
                        </div>
                        <div className="bx--row margin-bottom-large">
                          <div className="bx--col-lg-16">
                            <div className="company-information__logo-name">
                              <div className="company-information__logo">
                                <LazyLoadImage
                                  alt=""
                                  height={100}
                                  width={100}
                                  src={company?.logoURL}
                                  className="company-view-logo"
                                  placeholder={<div className="company-view-logo__image-placeholder">h1</div>}
                                />
                              </div>
                              <div>
                                <h2 className="main-view__heading">{company?.name}</h2>
                                <h3 className="main-view__subheading">
                                  <a target="_blank" href={`https://${company?.id}`} rel="noopener noreferrer">
                                    {company?.id}
                                  </a>
                                </h3>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="bx--row margin-bottom-large">
                          <CardCompanySection title="About" icon={Enterprise16} columns={3}>
                            <p style={{ marginBottom: '5px', fontSize: '0.8rem' }}>{company?.description.slice(0, 200)}...</p>
                            <a href="#" onClick={() => setOpenDescriptionModal(true)}>
                              Read more
                            </a>
                            <ul className="company-view__about-facts">
                              <li>
                                <Location16 /> {company?.hqCountry}
                              </li>
                              <li>
                                <Enterprise16 /> {company?.size}
                              </li>
                              <li>
                                <Calendar16 /> {company?.foundedYear}
                              </li>
                              <li>
                                <Wikis16 />{' '}
                                <a target="_blank" rel="noopener noreferrer" href={`http://${company?.id}`}>
                                  {company?.id}
                                </a>
                              </li>
                            </ul>
                          </CardCompanySection>
                          <CardCompanySection title="Financials" icon={Finance16} columns={3}>
                            <div className="bx--row">
                              <div className="bx--col-lg-8">
                                <Tooltip direction="bottom" tabIndex={0} triggerText="Revenue Estimate (USD)">
                                  <p>The estimated annual revenue.</p>
                                </Tooltip>
                                <br />
                                {company?.revenue}
                              </div>
                              <div className="bx--col-lg-8">
                                <Tooltip direction="bottom" tabIndex={0} triggerText="Funding Rounds">
                                  <p>Total number of funding rounds completed.</p>
                                </Tooltip>
                                <br />
                                {company?.fundingRounds}
                              </div>
                              <div className="bx--col-lg-8 margin-top-large">
                                <Tooltip direction="bottom" tabIndex={0} triggerText="Total Funding (USD)">
                                  <p>Total amount raised across all funding rounds.</p>
                                </Tooltip>
                                <br />
                                {company?.totalFundingAmount === 'N/A' ? 'N/A' : dollarCurrencyFormatter.format(company?.totalFundingAmount as number)}
                              </div>
                              <div className="bx--col-lg-8 margin-top-large">
                                <Tooltip direction="bottom" tabIndex={0} triggerText="IPO Date">
                                  <p>Date the company went public onto a stock market.</p>
                                </Tooltip>
                                <br />
                                {company?.IPODate}
                              </div>
                            </div>
                          </CardCompanySection>
                          <CardCompanySection title="Classification" icon={Product16} columns={3}>
                            <Tooltip direction="bottom" tabIndex={0} triggerText="Services">
                              <p>Services provided by this company that we have identified using our algorithm. These are a common group of services that are shared by other companies.</p>
                            </Tooltip>
                            <br />
                            {company?.tags.slice(0, company?.tags.length > 6 ? 6 : company?.tags.length).map((tag) => (
                              <Tag type="cool-gray" title={tag}>
                                {tag}
                              </Tag>
                            ))}
                            {company?.tags && company.tags.length > 6 && (
                              <a href="#" onClick={() => setOpenTagsModal(true)}>
                                Show all
                              </a>
                            )}
                            <div style={{ marginTop: '10px' }}>
                              <Tooltip direction="bottom" tabIndex={0} triggerText="Cluster">
                                <p>Using our algorithms, we have identified that this entity falls within a cluster with other similar companies.</p>
                              </Tooltip>
                              <br />
                              {company?.cluster}
                            </div>
                          </CardCompanySection>
                          <CardCompanySection title="Competitors" icon={ChartRelationship16} columns={7} fixedHeight={300}>
                            <TableCompanyCompetitors data={company?.competitors} />
                          </CardCompanySection>
                        </div>
                        <div className="bx--row margin-bottom-large">
                          <CardCompanySection title="Momentum" icon={Activity16} columns={16} fixedHeight={260}>
                            <div className="bx--row">
                              <div className="bx--col-lg-16">
                                <div className="bx--row" style={{ display: 'flex', justifyContent: 'space-evenly' }}>
                                  <div>
                                    <h3 className="main-view__subheading">Overall</h3>
                                    <Tooltip direction="bottom" tabIndex={0} triggerText="How is this scored?">
                                      <p>Overall Momentum is a calculation of a company's web traffic, media analysis, revenue, and funding scores, combined.</p>
                                    </Tooltip>
                                    <MomentumScore score={company?.overallMomentum} width={120} height={120} size="large" />
                                  </div>
                                  <div>
                                    <h3 className="main-view__subheading">Web Traffic</h3>
                                    <Tooltip direction="bottom" tabIndex={0} triggerText="How is this scored?">
                                      <p>
                                        Web Traffic is scored using the quarterly compound growth rate in website traffic, average webpages viewed per user, and current monthly peak website traffic.
                                      </p>
                                    </Tooltip>
                                    <GaugeChart
                                      id="web-traffic-gauge-chart"
                                      textColor="#000000"
                                      formatTextValue={(value) => `${value}`}
                                      nrOfLevels={30}
                                      colors={['#FF5F6D', '#FFC371']}
                                      arcWidth={0.3}
                                      percent={company!.momentum.webTraffic / 100}
                                    />
                                  </div>
                                  <div>
                                    <h3 className="main-view__subheading">Media Analysis</h3>
                                    <Tooltip direction="bottom" tabIndex={0} triggerText="How is this scored?">
                                      <p>Media Analysis is scored by calculating the quarterly compound growth rate in social media mentions.</p>
                                    </Tooltip>
                                    <GaugeChart
                                      id="media-analysis-gauge-chart"
                                      textColor="#000000"
                                      formatTextValue={(value) => `${value}`}
                                      nrOfLevels={30}
                                      colors={['#F9DC5C', '#337cff']}
                                      arcWidth={0.3}
                                      percent={company!.momentum.mediaAnalysis / 100}
                                    />
                                  </div>
                                  <div>
                                    <h3 className="main-view__subheading">Revenue Estimate</h3>
                                    <Tooltip direction="bottom" tabIndex={0} triggerText="How is this scored?">
                                      <p>Revenue Estimate is scored by calculating the annual compound growth rate in the companies estimated revenue.</p>
                                    </Tooltip>
                                    <GaugeChart
                                      id="revenue-estimates-gauge-chart"
                                      textColor="#000000"
                                      formatTextValue={(value) => `${value}`}
                                      nrOfLevels={30}
                                      colors={['#337CFF', '#542E71']}
                                      arcWidth={0.3}
                                      percent={company!.momentum.revenueEstimates / 100}
                                    />
                                  </div>
                                  <div>
                                    <h3 className="main-view__subheading">Funding</h3>
                                    <Tooltip direction="bottom" tabIndex={0} triggerText="How is this scored?">
                                      <p>Funding is scored by calculating the growth in funding capital raised in a rolling 24 month cycle.</p>
                                    </Tooltip>
                                    <GaugeChart
                                      id="funding-gauge-chart"
                                      textColor="#000000"
                                      formatTextValue={(value) => `${value}`}
                                      nrOfLevels={30}
                                      colors={['#1EFFBC', '#20BF55']}
                                      arcWidth={0.3}
                                      percent={company!.momentum.funding / 100}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </CardCompanySection>
                        </div>
                        <div className="bx--row margin-bottom-large">
                          <CardCompanySection title="Services" icon={Enterprise16} columns={8}>
                            <p
                              style={{
                                margin: '10px 0 10px 0',
                                fontSize: '.8rem',
                              }}
                            >
                              Viewing the services of {company?.name}
                            </p>
                            <CompanyReferenceArchitecture data={referenceArchitecture} />
                          </CardCompanySection>
                          <CardCompanySection title={`Advantages and Disadvantages of ${company?.name} vs ${selectedCompetitor?.name || 'Competitor'}`} icon={Enterprise16} columns={8}>
                            {company?.competitors && competingReferenceArchitecture && (
                              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', width: '100%' }}>
                                Compare services with another company:
                                <Select
                                  className="competitor-list"
                                  classNamePrefix="select"
                                  getOptionLabel={(option) => option.label}
                                  getOptionValue={(option) => option.id}
                                  isClearable={false}
                                  isSearchable
                                  options={studyCompanies.map((company) => {
                                    return {
                                      id: company.id,
                                      label: company.name,
                                    };
                                  })}
                                  onChange={(e) => {
                                    const foundSelectedCompetitor = studyCompanies.find((company) => company.id === e.id);
                                    trackEvent(ANALYTICS_CATEGORY.STUDY, `Compare Reference Architecture between: ${company?.id} and ${foundSelectedCompetitor!.id}`, ANALYTICS_ACTION.SET);
                                    setSelectedCompetitor(foundSelectedCompetitor);
                                    getCompetingReferenceArchitecture(company?.id, foundSelectedCompetitor!.id);
                                  }}
                                />
                              </div>
                            )}
                            <CompanyReferenceArchitecture data={competingReferenceArchitecture} />
                            {selectedCompetitor && <CompetingReferenceArchitectureLegend primaryCompanyName={company?.name} competitorCompanyName={selectedCompetitor.name} />}
                          </CardCompanySection>
                        </div>
                        <div className="bx--row">
                          <CardCompanySection title="News Events" icon={Bullhorn16} columns={16}>
                            {isLoadingEvents ? (
                              <LoadingSkeletonView />
                            ) : (
                              <div className="bx--row">
                                <div className="bx--col-lg-10 margin-top-large">
                                  <EventTimeline events={events} filteredEvents={[]} isLoading={isLoading} />
                                </div>
                                <div className="bx--col-lg-6 margin-top-large">
                                  <EventsList
                                    isLoading={isLoading}
                                    events={events}
                                    filteredEvents={[]}
                                    maxHeight={400}
                                    isInNewsEventsView={false}
                                    handleGetArticlesForNewsEvent={getArticlesWithIdForNewsEvent}
                                  />
                                </div>
                              </div>
                            )}
                          </CardCompanySection>
                        </div>
                      </>
                    )}
                  </div>
                </>
              )}
            </CompanyContext.Consumer>
          </CompanyProvider>
        </div>
      )}
    </StudyContext.Consumer>
  );
};

export default CompanyView;
