import React, { useState } from 'react';
import ResizePanel from 'react-resize-panel';
import { Button } from 'carbon-components-react';
import { WidgetsContainer } from '../WidgetsContainer/WidgetsContainer';
import './widget-drawer.scss';
import { useResizeDetector } from 'react-resize-detector';
import usePreferences, { PREFERENCE_KEY } from '../../../Hooks/usePreferences';
import { WidgetId } from '../../../Models/types';

const DEFAULT_WIDTH = 400;
const ALL_WIDGET_IDS = [WidgetId.FOAM_TREE, WidgetId.MAP, WidgetId.SCATTER_CHART, WidgetId.MARKET_REFERENCE_ARCHITECTURE];

const WidgetDrawer = ({ isLoading }) => {
  const { preferences, setPreference } = usePreferences();
  const { width: sidebarWidth, ref: sidebarRef } = useResizeDetector<HTMLDivElement>({ refreshMode: 'debounce', refreshRate: 200 });
  const [isEditingWidgets, setIsEditingWidgets] = useState(false);
  const [activeWidgets, setActiveWidgets] = useState<WidgetId[]>(preferences?.activeWidgets ? (preferences.activeWidgets as WidgetId[]) : []);
  const [inactiveWidgets, setInactiveWidgets] = useState<WidgetId[]>(ALL_WIDGET_IDS.filter((widgetId) => !preferences!.activeWidgets.includes(widgetId)) as WidgetId[]);

  function addWidgetToActiveWidgets(widgetId: WidgetId) {
    const activeWidgetsCopy = [...activeWidgets];
    const inactiveWidgetsCopy = [...inactiveWidgets];
    activeWidgetsCopy.push(widgetId);
    const filteredOutWidgets = inactiveWidgetsCopy.filter((inactiveWidgetId) => inactiveWidgetId !== widgetId);
    setActiveWidgets(activeWidgetsCopy);
    setInactiveWidgets(filteredOutWidgets);
  }

  function removeWidgetFromActiveWidgets(widgetId: WidgetId) {
    const activeWidgetsCopy = [...activeWidgets];
    const inactiveWidgetsCopy = [...inactiveWidgets];
    inactiveWidgetsCopy.push(widgetId);
    const filteredOutWidgets = activeWidgetsCopy.filter((activeWidgetId) => activeWidgetId !== widgetId);
    setInactiveWidgets(inactiveWidgetsCopy);
    setActiveWidgets(filteredOutWidgets);
  }

  async function updateActiveWidgets() {
    await setPreference(PREFERENCE_KEY.activeWidgets, activeWidgets);
  }

  return (
    <>
      <ResizePanel direction="w" style={{ width: '400px' }} handleClass="custom-handle" borderClass="custom-resize-border">
        <div ref={sidebarRef} className="sidebar panel">
          <div className="button-header">
            <Button
              kind="ghost"
              onClick={() => {
                if (isEditingWidgets) updateActiveWidgets();
                setIsEditingWidgets(!isEditingWidgets);
              }}
            >
              {isEditingWidgets ? 'Finish Editing' : 'Edit Widgets'}
            </Button>
          </div>
          <div className="widget-drawer__wrapper">
            {!isLoading ? (
              <div className="widget-drawer__widgets-container">
                <WidgetsContainer
                  isActiveWidgets={true}
                  isEditingWidgets={isEditingWidgets}
                  handleRemoveWidgetFromActiveWidgets={removeWidgetFromActiveWidgets}
                  initialWidth={DEFAULT_WIDTH}
                  widgetIds={activeWidgets}
                  sidebarWidth={sidebarWidth!}
                />
              </div>
            ) : null}
          </div>
        </div>
      </ResizePanel>
      {isEditingWidgets && (
        <div style={{ width: '400px' }}>
          <div ref={sidebarRef} className="inactive-widgets-sidebar">
            <div className="widget-drawer__wrapper">
              {!isLoading ? (
                <div className="widget-drawer__widgets-container">
                  <WidgetsContainer
                    isActiveWidgets={false}
                    handleAddWidgetToActiveWidgets={addWidgetToActiveWidgets}
                    isEditingWidgets={isEditingWidgets}
                    initialWidth={DEFAULT_WIDTH}
                    widgetIds={inactiveWidgets}
                    sidebarWidth={sidebarWidth!}
                  />
                </div>
              ) : null}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default WidgetDrawer;
