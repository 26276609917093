import React from 'react';
import { ArrowRight16 } from '@carbon/icons-react';
import { Button } from 'carbon-components-react';

const RequestDemoButton = () => {
  function openDemoFormInNewTab() {
    const win = window.open('https://services.forestreet.com/demo-request', '_blank');
    win!.focus();
  }

  return (
    <Button renderIcon={ArrowRight16} kind="primary" tabIndex={0} type="submit" onClick={openDemoFormInNewTab}>
      Request a Demo
    </Button>
  );
};

export default RequestDemoButton;
