import React from 'react';
import './widget.scss';
import { WidgetId } from '../../../Models/types';

interface Props {
  id: WidgetId;
  isActive: boolean;
  isEditing: boolean;
  handleAddWidget: (widgetId: WidgetId) => void;
  handleRemoveWidget: (widgetId: WidgetId) => void;
}

const Widget: React.FC<Props> = ({ id, isActive, isEditing, handleAddWidget, handleRemoveWidget, children }) => {
  return (
    <>
      {isEditing && isActive && <RemoveWidgetButton handleClick={() => handleRemoveWidget(id)} />}
      {isEditing && !isActive && <AddWidgetButton handleClick={() => handleAddWidget(id)} />}
      {children}
    </>
  );
};

export default Widget;

const AddWidgetButton = ({ handleClick }) => {
  return (
    <div className="widget-button add-widget-button" onClick={handleClick}>
      +
    </div>
  );
};

const RemoveWidgetButton = ({ handleClick }) => {
  return (
    <div className="widget-button remove-widget-button" onClick={handleClick}>
      -
    </div>
  );
};
