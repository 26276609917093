import React, { useContext, useMemo } from 'react';
import { MarketReferenceArchitectureViewItem, ReferenceArchitectureItem } from './MarketReferenceArchitectureViewItem';
import './market-reference-architecture.scss';
import { getAggregatedRefArchData } from '../../../Helpers/architecture.helper';
import { StudyContext } from '../../../Contexts/StudyProvider';

// This adds a new property to the reference architecture item called count.
// It's used to color the background dynamically when filtering
function calculateCount(architectureView: ReferenceArchitectureItem[]): number {
  let result = 0;
  architectureView.forEach((item) => {
    item.children.forEach((item) => {
      item.children.forEach((item) => {
        if (item.count > result) result = item.count;
      });
    });
  });
  return result;
}

export const MarketReferenceArchitecture: React.FC = () => {
  const { studyCompanies, filteredStudyCompanies, referenceArchitecture } = useContext(StudyContext);

  const architectureViewData = useMemo(() => {
    return getAggregatedRefArchData(studyCompanies, filteredStudyCompanies, referenceArchitecture?.children!);
  }, [studyCompanies, filteredStudyCompanies, referenceArchitecture]);

  return (
    <div className="architecture-view__item-container">
      {architectureViewData.map((referenceArchitectureItem, index) => (
        <MarketReferenceArchitectureViewItem {...referenceArchitectureItem} key={index} maxCount={calculateCount(architectureViewData)} />
      ))}
    </div>
  );
};
