import React from 'react';
import LoadingSkeletonView from '../../LoadingSkeleton/LoadingSkeletonView';
import { Modal } from 'carbon-components-react';
import { TagsContext } from '../../../Contexts/TagsProvider';

interface Props {
  tagLabel?: string;
  isOpen: boolean;
  handleClose: Function;
}

const DeleteTagModal: React.FC<Props> = ({ tagLabel, isOpen, handleClose }) => {
  async function handleDeleteTag(callback) {
    await callback(tagLabel);
    handleClose();
  }

  return (
    <>
      <TagsContext.Consumer>
        {({ deleteTag, isDeletingTag }) => (
          <>
            <Modal
              open={isOpen}
              danger
              onRequestClose={handleClose}
              onBlur={handleClose}
              onSecondarySubmit={handleClose}
              onRequestSubmit={() => handleDeleteTag(deleteTag)}
              size="sm"
              primaryButtonText="Yes, Delete"
              secondaryButtonText="Cancel"
            >
              {isDeletingTag ? (
                <>
                  <LoadingSkeletonView />
                </>
              ) : (
                <>
                  <div className="bx--row">
                    <div className="bx--col-lg-16">
                      <h4 className="bx--modal-content__text">
                        Are you sure you want to delete tag: <strong>{tagLabel}</strong>? Doing so will remove this tag from all companies and all users.
                      </h4>
                    </div>
                  </div>
                </>
              )}
            </Modal>
          </>
        )}
      </TagsContext.Consumer>
    </>
  );
};

export default DeleteTagModal;
