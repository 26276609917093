import { updateAILSAPreferencesInDB } from './api';
import { AILSAPreferences } from '../Models/types';

const useUsersAPI = () => {
  const updatePreferences = async (userId: number, preferences: AILSAPreferences): Promise<void> => {
    try {
      await updateAILSAPreferencesInDB(userId, preferences);
    } catch (e) {
      throw e;
    }
  };

  return {
    updatePreferences,
  };
};

export default useUsersAPI;
