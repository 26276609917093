import { useContext, useEffect } from 'react';
import mixpanel from 'mixpanel-browser';
import ReactGA from 'react-ga';
import { useCookies } from 'react-cookie';
import { COOKIE_KEY_NAME, DEMO_COOKIE_KEY_NAME } from '../Helpers/constants';
import { StudyContext } from '../Contexts/StudyProvider';
import { CompanyContext } from '../Contexts/CompanyProvider';

export enum ANALYTICS_CATEGORY {
  STUDIES = 'STUDIES',
  STUDY = 'STUDY',
  COMPANY = 'COMPANY',
}

export enum ANALYTICS_ACTION {
  CLICKED = 'CLICKED',
  SET = 'SET',
  CREATE = 'CREATE',
  ASSIGN = 'ASSIGN',
  UNASSIGN = 'UNASSIGN',
  DELETE = 'DELETE',
  STAR = 'STAR',
  UNSTAR = 'UNSTAR',
}

const useAnalytics = () => {
  const { selectedStudy } = useContext(StudyContext);
  const { company } = useContext(CompanyContext);
  const [cookies] = useCookies([COOKIE_KEY_NAME]);
  const [demoCookies] = useCookies([DEMO_COOKIE_KEY_NAME]);

  function trackPageview() {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }

  // Tracking a new event in Google Analytics requires a category and an action.
  // In order to ensure we're using a set group of categories, these are defined as an ENUM.
  //An Event has the following components. An Event hit includes a value for each component, and these values are displayed in your reports.
  // Category
  // Action
  // Label (optional, but recommended)
  // Value (optional)
  // READ THIS if you're struggling on how to name your event: https://support.google.com/analytics/answer/1033068?hl=en

  // NOTE: If the event category is of type STUDY, then it prepends the current study name to the category by default.
  // If the event category is of type COMPANY then it prepends the current study AND the company name to the category by default.

  function trackEvent(category: ANALYTICS_CATEGORY, label: string, action: ANALYTICS_ACTION, value?: number) {
    // Calculate and set up the category name dynamically:
    let categoryString;
    if (category === ANALYTICS_CATEGORY.STUDY) {
      categoryString = `STUDY: ${selectedStudy?.name}`;
    } else if (category === ANALYTICS_CATEGORY.COMPANY) {
      categoryString = `STUDY: ${selectedStudy?.name} COMPANY: ${company?.name}`;
    } else {
      categoryString = category;
    }

    // Mixpanel Analytics
    mixpanel.track(category, { label: label, action: action, value: value });

    // Google Analytics
    // dimension1 is a user's email address
    // dimension 2 is a user's user group name

    ReactGA.event({
      category: categoryString,
      action,
      label,
      value,
      dimension1: process.env.REACT_APP_IS_DEMO === 'true' ? demoCookies.DEMO_AILSA_USER.email : cookies.AILSA_USER.email,
      dimension2: process.env.REACT_APP_IS_DEMO === 'true' ? demoCookies.DEMO_AILSA_USER.studyGroupName : cookies.AILSA_USER.studyGroupName,
    });
  }

  // When using the useAnalytics hook, we init Google Analytics with the current user
  // and the easiest way to find the currentUser inside this hook is by looking in cookies.
  useEffect(() => {
    const userId = process.env.REACT_APP_IS_DEMO === 'true' ? demoCookies.DEMO_AILSA_USER.id : cookies.AILSA_USER.id;
    const userEmailAddress = process.env.REACT_APP_IS_DEMO === 'true' ? demoCookies.DEMO_AILSA_USER.email : cookies.AILSA_USER.email;
    const userStudyGroupId = process.env.REACT_APP_IS_DEMO === 'true' ? demoCookies.DEMO_AILSA_USER.studyGroupId : cookies.AILSA_USER.studyGroupId;
    const userStudyGroupName = process.env.REACT_APP_IS_DEMO === 'true' ? demoCookies.DEMO_AILSA_USER.studyGroupName : cookies.AILSA_USER.studyGroupName;

    // Mixpanel Analytics
    mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN, { api_host: 'https://api-eu.mixpanel.com' }, '');
    mixpanel.identify(userId);
    mixpanel.people.set({
      $email: userEmailAddress, // only reserved properties need the $
      USER_ID: userId, // use human-readable names
      STUDY_GROUP_ID: userStudyGroupId, // ...or numbers
      STUDY_GROUP_NAME: userStudyGroupName, // ...or numbers
    });

    // Google Analytics
    ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_CODE!, {
      debug: false,
      gaOptions: {
        userId: userId,
      },
    });
    ReactGA.set({
      userId: userId,
      dimension1: userEmailAddress,
      dimension2: userStudyGroupName,
    });
  }, []);

  return {
    trackEvent,
    trackPageview,
  };
};

export default useAnalytics;
