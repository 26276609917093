import React, { useContext, useState, useEffect } from 'react';
import { StudyContext } from './StudyProvider';
import useCommentsAPI from '../Data/useCommentsAPI';
import { UserContext } from './UserProvider';
import { Comment } from '../Models/types';
import { toast } from 'react-toastify';
import useAnalytics, { ANALYTICS_ACTION, ANALYTICS_CATEGORY } from '../Hooks/useAnalytics';

type ContextProps = {
  isCompanyCommentsModalOpenWithCompanyId: string | undefined;
  isLoadingComments: boolean;
  isCreatingComment: boolean;
  showCompanyCommentsModalWithCompanyId: (companyId: string) => void;
  hideCompanyCommentsModal: () => void;
  getCommentsForCompanyWithId: () => void;
  createNewComment: (text: string) => void;
  companyComments: Comment[];
};

export const CommentsContext = React.createContext<ContextProps>({
  isCompanyCommentsModalOpenWithCompanyId: undefined,
  isLoadingComments: true,
  isCreatingComment: false,
  showCompanyCommentsModalWithCompanyId: () => {},
  hideCompanyCommentsModal: () => {},
  getCommentsForCompanyWithId: () => {},
  companyComments: [],
  createNewComment: () => {},
});

interface Props {
  studyId: number;
  studyUserGroupId: number;
}

const CommentsProvider: React.FC<Props> = ({ children, studyId, studyUserGroupId }) => {
  const { trackEvent } = useAnalytics();
  const { getComments, createComment } = useCommentsAPI();
  const { currentUser } = useContext(UserContext);
  const { getCompanies } = useContext(StudyContext);
  const [isCompanyCommentsModalOpenWithCompanyId, setIsCompanyCommentsModalOpenWithCompanyId] = useState<string | undefined>(undefined);
  const [isLoadingComments, setIsLoadingComments] = useState(true);
  const [isCreatingComment, setIsCreatingComment] = useState(false);
  const [companyComments, setCompanyComments] = useState<Comment[]>([]);

  async function getCommentsForCompanyWithId() {
    setIsLoadingComments(true);
    const commentsResult = await getComments(studyId, studyUserGroupId, isCompanyCommentsModalOpenWithCompanyId!);
    setCompanyComments(commentsResult);
    setIsLoadingComments(false);
  }

  async function createNewComment(text: string) {
    setIsCreatingComment(true);
    try {
      trackEvent(ANALYTICS_CATEGORY.STUDY, `New comment: ${text}`, ANALYTICS_ACTION.CREATE);
      await createComment(currentUser!.id, text, studyId, studyUserGroupId, isCompanyCommentsModalOpenWithCompanyId!);
      await getCommentsForCompanyWithId();
      await getCompanies();
    } catch (e) {
      toast.error(e.message);
    }
    setIsCreatingComment(false);
  }

  useEffect(() => {
    // When the modal hides, reset the loading states back to default.
    if (isCompanyCommentsModalOpenWithCompanyId) {
      setIsCreatingComment(false);
      setIsLoadingComments(true);
    }
  }, [isCompanyCommentsModalOpenWithCompanyId]);

  return (
    <CommentsContext.Provider
      value={{
        isCompanyCommentsModalOpenWithCompanyId,
        isLoadingComments,
        isCreatingComment,
        showCompanyCommentsModalWithCompanyId: (companyId) => setIsCompanyCommentsModalOpenWithCompanyId(companyId),
        hideCompanyCommentsModal: () => setIsCompanyCommentsModalOpenWithCompanyId(undefined),
        getCommentsForCompanyWithId,
        createNewComment,
        companyComments,
      }}
    >
      {children}
    </CommentsContext.Provider>
  );
};

export default CommentsProvider;
