import React, { useEffect, useState } from 'react';
import { AvailableCheckboxFilter, CompanyMetadata, FILTER_NAME } from '../Models/types';
import useAnalytics, { ANALYTICS_ACTION, ANALYTICS_CATEGORY } from '../Hooks/useAnalytics';

type ContextProps = {
  availableCountryFilters?: AvailableCheckboxFilter;
  setAvailableCountryFilters?: Function;
  numberOfFiltersActive: number;
  setFilterValuesWithFilterId: (filterId: string, values: any[]) => void;
  getFilterValuesWithFilterId: (filterId: string) => any[];
  resetAllFilters: () => void;
};

export const FilterContext = React.createContext<ContextProps>({
  availableCountryFilters: undefined,
  setAvailableCountryFilters: () => {},
  numberOfFiltersActive: 0,
  setFilterValuesWithFilterId: () => {},
  getFilterValuesWithFilterId: () => [],
  resetAllFilters: () => {},
});

type Props = {
  studyCompanies: CompanyMetadata[];
  updateCompaniesWithFilteredCompanies: (filteredStudyCompanies: CompanyMetadata[]) => void;
};

const FilterProvider: React.FC<Props> = ({ children, studyCompanies, updateCompaniesWithFilteredCompanies }) => {
  const { trackEvent } = useAnalytics();
  const [availableCountryFilters, setAvailableCountryFilters] = useState<AvailableCheckboxFilter | undefined>(undefined);
  const [numberOfFiltersActive, setNumberOfFiltersActive] = useState<number>(0);
  const [userTagsFilterValues, setUserTagsFilterValues] = useState<string[]>([]);
  const [foamTreeFilterValues, setFoamTreeFilterValues] = useState<string[]>([]);
  const [tagsFilterValues, setTagsFilterValues] = useState<string[]>([]);
  const [headquartersFilterValues, setHeadquartersFilterValues] = useState<string[]>([]);
  const [sizeFilterValues, setSizeFilterValues] = useState<string[]>([]);
  const [revenueFilterValues, setRevenueFilterValues] = useState<string[]>([]);
  const [clusterFilterValues, setClusterFilterValues] = useState<string[]>([]);
  const [foundedDateFilterValues, setFoundedDateFilterValues] = useState<number[]>([]);
  const [momentumScoreFilterValues, setMomentumScoreFilterValues] = useState<number[]>([0, 100]);
  const [estimatedEmployeesFilterValues, setEstimatedEmployeesFilterValues] = useState<number[]>([]);
  const [referenceArchitectureFilterValues, setReferenceArchitectureFilterValues] = useState<number[]>([]);
  const [favouritesFilterValues, setFavouritesFilterValues] = useState<string[]>([]);

  function mergeFilterValues(currentValues, newValues): any[] {
    // First detect if the new value(s) are already in the currentValues, if so, remove them.
    const isNewValuesAlreadyInCurrentValues = newValues.some((newValue) => currentValues.includes(newValue));
    if (isNewValuesAlreadyInCurrentValues) {
      return currentValues.filter((currentValue) => !newValues.includes(currentValue));
    }
    // If not, add them.
    return [...currentValues, ...newValues];
  }

  function setFilterValuesWithFilterId(filterId: string, values: any[]) {
    if (filterId === FILTER_NAME.USER_TAGS) {
      setUserTagsFilterValues((currentValues) => mergeFilterValues(currentValues, values));
    }
    if (filterId === FILTER_NAME.FOAMTREE) {
      setFoamTreeFilterValues((currentValues) => mergeFilterValues(currentValues, values));
    }
    if (filterId === FILTER_NAME.TAGS) {
      setTagsFilterValues((currentValues) => mergeFilterValues(currentValues, values));
    }
    if (filterId === FILTER_NAME.HEADQUARTERS) {
      setHeadquartersFilterValues((currentValues) => mergeFilterValues(currentValues, values));
    }
    if (filterId === FILTER_NAME.SIZE) {
      setSizeFilterValues((currentValues) => mergeFilterValues(currentValues, values));
    }
    if (filterId === FILTER_NAME.REVENUE) {
      setRevenueFilterValues((currentValues) => mergeFilterValues(currentValues, values));
    }
    if (filterId === FILTER_NAME.CLUSTER) {
      setClusterFilterValues((currentValues) => mergeFilterValues(currentValues, values));
    }
    if (filterId === FILTER_NAME.FOUNDED_DATE) {
      setFoundedDateFilterValues(values);
    }
    if (filterId === FILTER_NAME.MOMENTUM_SCORE) {
      setMomentumScoreFilterValues(values);
    }
    if (filterId === FILTER_NAME.REFERENCE_ARCHITECTURE) {
      setReferenceArchitectureFilterValues((currentValues) => mergeFilterValues(currentValues, values));
    }
    if (filterId === FILTER_NAME.FAVOURITES) {
      setFavouritesFilterValues((currentValues) => mergeFilterValues(currentValues, values));
    }
    if (filterId === FILTER_NAME.EMPLOYEE_ESTIMATE) {
      setEstimatedEmployeesFilterValues(values);
    }
  }

  // This retrieves the values of the specified filter, in an array.
  // It's important to understand that the result is always returned in an array.

  function getFilterValuesWithFilterId(filterId: string): any[] {
    if (filterId === FILTER_NAME.USER_TAGS) {
      return userTagsFilterValues;
    }
    if (filterId === FILTER_NAME.FOAMTREE) {
      return foamTreeFilterValues;
    }
    if (filterId === FILTER_NAME.TAGS) {
      return tagsFilterValues;
    }
    if (filterId === FILTER_NAME.HEADQUARTERS) {
      return headquartersFilterValues;
    }
    if (filterId === FILTER_NAME.SIZE) {
      return sizeFilterValues;
    }
    if (filterId === FILTER_NAME.REVENUE) {
      return revenueFilterValues;
    }
    if (filterId === FILTER_NAME.CLUSTER) {
      return clusterFilterValues;
    }
    if (filterId === FILTER_NAME.FOUNDED_DATE) {
      return foundedDateFilterValues;
    }
    if (filterId === FILTER_NAME.MOMENTUM_SCORE) {
      return momentumScoreFilterValues;
    }
    if (filterId === FILTER_NAME.REFERENCE_ARCHITECTURE) {
      return referenceArchitectureFilterValues;
    }
    if (filterId === FILTER_NAME.FAVOURITES) {
      return favouritesFilterValues;
    }
    if (filterId === FILTER_NAME.EMPLOYEE_ESTIMATE) {
      return estimatedEmployeesFilterValues;
    }
    return [];
  }

  function resetAllFilters() {
    trackEvent(ANALYTICS_CATEGORY.STUDY, 'Reset Filters', ANALYTICS_ACTION.CLICKED);
    setUserTagsFilterValues([]);
    setFoamTreeFilterValues([]);
    setTagsFilterValues([]);
    setHeadquartersFilterValues([]);
    setSizeFilterValues([]);
    setRevenueFilterValues([]);
    setClusterFilterValues([]);
    setFoundedDateFilterValues([]);
    setMomentumScoreFilterValues([]);
    setFavouritesFilterValues([]);
    setEstimatedEmployeesFilterValues([]);
  }

  // Custom Filters
  const orMatch = (valueToCheckExists, filterValues) => {
    if (Array.isArray(valueToCheckExists)) {
      return !!valueToCheckExists.some((value) => filterValues.includes(value));
    }
    return filterValues.includes(valueToCheckExists);
  };

  const andMatch = (array1, array2) => {
    return array2.every((value) => array1.includes(value));
  };

  const betweenMatch = (valueToCheckIsBetween, minMaxValues) => {
    return valueToCheckIsBetween === 'N/A' || !valueToCheckIsBetween || (valueToCheckIsBetween >= minMaxValues[0] && valueToCheckIsBetween <= minMaxValues[1]);
  };

  // The big filter effect function! When any of the filters change value, re-run one big filter of the
  // study companies with the current set of filter values.
  // These filters are stored in a separate custom hook called useFilters to promote code cleanliness.
  // Don't forget to add the new state value to the dependencies at the bottom of the effect.

  useEffect(() => {
    // 1. Take a copy of the current study companies
    let activeFilters = 0;
    let copyOfStudyCompanies = [...studyCompanies];

    if (foamTreeFilterValues.length) {
      activeFilters++;
      copyOfStudyCompanies = copyOfStudyCompanies.filter((company) => orMatch(company.ngrams, foamTreeFilterValues));
    }

    if (tagsFilterValues.length) {
      activeFilters++;
      copyOfStudyCompanies = copyOfStudyCompanies.filter((company) => andMatch(company.tags, tagsFilterValues));
    }

    if (headquartersFilterValues.length) {
      activeFilters++;
      copyOfStudyCompanies = copyOfStudyCompanies.filter((company) => orMatch(company.hqCountry, headquartersFilterValues));
    }

    if (sizeFilterValues.length) {
      activeFilters++;
      copyOfStudyCompanies = copyOfStudyCompanies.filter((company) => orMatch(company.size, sizeFilterValues));
    }

    if (revenueFilterValues.length) {
      activeFilters++;
      copyOfStudyCompanies = copyOfStudyCompanies.filter((company) => orMatch(company.revenue, revenueFilterValues));
    }

    if (clusterFilterValues.length) {
      activeFilters++;
      copyOfStudyCompanies = copyOfStudyCompanies.filter((company) => orMatch(company.cluster, clusterFilterValues));
    }

    const minFoundedDate = Math.min(...studyCompanies.map((company) => (company.foundedYear === 'N/A' ? 9999 : parseInt(company.foundedYear as string))));
    const maxFoundedDate = Math.max(...studyCompanies.map((company) => (company.foundedYear === 'N/A' ? 0 : parseInt(company.foundedYear as string))));
    if (foundedDateFilterValues.length && (foundedDateFilterValues[0] !== minFoundedDate || foundedDateFilterValues[1] !== maxFoundedDate)) {
      activeFilters++;
      copyOfStudyCompanies = copyOfStudyCompanies.filter((company) => betweenMatch(company.foundedYear, foundedDateFilterValues));
    }

    if (momentumScoreFilterValues.length && (momentumScoreFilterValues[0] !== 0 || momentumScoreFilterValues[1] !== 100)) {
      activeFilters++;
      copyOfStudyCompanies = copyOfStudyCompanies.filter((company) => betweenMatch(company.overallMomentum, momentumScoreFilterValues));
    }

    if (referenceArchitectureFilterValues.length) {
      activeFilters++;
      copyOfStudyCompanies = copyOfStudyCompanies.filter((company) => andMatch(company.referenceArchitectureIds, referenceArchitectureFilterValues));
    }

    if (favouritesFilterValues.length) {
      activeFilters++;
      if (favouritesFilterValues.includes('User Starred')) {
        copyOfStudyCompanies = copyOfStudyCompanies.filter((company) => company.isFavourite);
      }
      if (favouritesFilterValues.includes('Forestreet Starred')) {
        copyOfStudyCompanies = copyOfStudyCompanies.filter((company) => company.isStarredByForestreet);
      }
    }

    if (userTagsFilterValues.length) {
      activeFilters++;
      copyOfStudyCompanies = copyOfStudyCompanies.filter((company) =>
        andMatch(
          company.userTags?.map((tag) => tag.label),
          userTagsFilterValues
        )
      );
    }

    // Estimated Employees is a bit different. If the value range selected goes up to 1000, then it means it's 1000+, which should include ALL values above 1000.
    const minEstimatedEmployees = Math.min(...studyCompanies.filter((company) => company.professionalEmployeeEstimate !== undefined).map((company) => company.professionalEmployeeEstimate!));
    const maxEstimatedEmployees = Math.max(...studyCompanies.filter((company) => company.professionalEmployeeEstimate !== undefined).map((company) => company.professionalEmployeeEstimate!));
    if (estimatedEmployeesFilterValues.length && (estimatedEmployeesFilterValues[0] !== minEstimatedEmployees || estimatedEmployeesFilterValues[1] !== 1000)) {
      activeFilters++;
      if (estimatedEmployeesFilterValues[1] === 1000) {
        setEstimatedEmployeesFilterValues([estimatedEmployeesFilterValues[0], maxEstimatedEmployees]);
      }
      copyOfStudyCompanies = copyOfStudyCompanies
        .filter((company) => company.professionalEmployeeEstimate !== undefined)
        .filter((company) => betweenMatch(company.professionalEmployeeEstimate, estimatedEmployeesFilterValues));
    }

    // End result
    setNumberOfFiltersActive(activeFilters);
    updateCompaniesWithFilteredCompanies(copyOfStudyCompanies);
  }, [
    foamTreeFilterValues,
    tagsFilterValues,
    headquartersFilterValues,
    sizeFilterValues,
    revenueFilterValues,
    clusterFilterValues,
    foundedDateFilterValues,
    momentumScoreFilterValues,
    referenceArchitectureFilterValues,
    userTagsFilterValues,
    estimatedEmployeesFilterValues,
    favouritesFilterValues,
  ]);

  return (
    <FilterContext.Provider
      value={{
        availableCountryFilters,
        setAvailableCountryFilters,
        numberOfFiltersActive,
        setFilterValuesWithFilterId,
        getFilterValuesWithFilterId,
        resetAllFilters,
      }}
    >
      {children}
    </FilterContext.Provider>
  );
};

export default FilterProvider;
