import React, { useEffect } from 'react';
import { Route, Redirect, useHistory } from 'react-router-dom';
import HeaderBar from '../Components/HeaderBar/HeaderBar';
import CompanyView from '../Views/CompanyView/CompanyView';
import MarketExplorerView from '../Views/MarketExplorerView/MarketExplorerView';
import useAnalytics from '../Hooks/useAnalytics';

const DemoRoutes = () => {
  const history = useHistory();
  const { trackPageview } = useAnalytics();

  useEffect(() => {
    history.listen(() => {
      trackPageview();
    });
  }, [history]);

  return (
    <>
      <HeaderBar />
      <Route path="/" exact component={() => <Redirect to="/study/2/explorer" />} />
      <Route path="/studies" exact component={() => <Redirect to="/study/2/explorer" />} />
      <Route path="/study" exact component={() => <Redirect to="/study/2/explorer" />} />
      <Route path="/study/:studyId/explorer" component={MarketExplorerView} />
      <Route path="/study/:studyId/company/:companyId" component={CompanyView} />
    </>
  );
};

export default DemoRoutes;
