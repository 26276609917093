import React, { FC, useRef, useEffect, CSSProperties } from 'react';
import LineUp, { builder, buildStringColumn, buildCategoricalColumn, buildRanking, LocalDataProvider, buildColumn, buildDateColumn, ICategory, buildActionsColumn, createGroupDesc } from 'lineupjs';
import { CompanyMetadata } from '../../Models/types';
import './lineup-widget.scss';
import { useParams } from 'react-router-dom';
import { schemeBlues } from 'd3-scale-chromatic';
import CustomSetColumn from './internal/CustomSetColumn';
import CustomImageCellRenderer from './internal/CustomImageRenderer';
import CustomUpSetCellRenderer from './internal/CustomUpSetRenderer';
import CustomGroupCellRenderer from './internal/CustomGroupRenderer';
import DemoBanner from '../Demo/DemoBanner';

interface Props {
  style?: CSSProperties;
  className?: string;
  rows: CompanyMetadata[];
}

function classNames(...classNames: (boolean | null | undefined | string)[]) {
  return classNames.filter(Boolean).join(' ');
}

const LineUpWidget: FC<Props> = ({ rows, style, className }) => {
  const { studyId } = useParams<{ studyId: string }>();
  const ref = useRef<HTMLDivElement>(null);
  const lineupRef = useRef<LineUp>();
  const providerRef = useRef<LocalDataProvider>();

  useEffect(() => {
    const b = builder([]).sidePanel(false);
    // b.disableAdvancedRankingFeatures()
    // b.disableAdvancedModelFeatures()
    // b.disableAdvancedUIFeatures()
    b.registerRenderer('image', new CustomImageCellRenderer());
    b.registerRenderer('upset', new CustomUpSetCellRenderer());
    b.registerRenderer('group', new CustomGroupCellRenderer());

    const lineup = b.build(ref.current!);

    lineupRef.current = lineup;
    providerRef.current = lineup.data as LocalDataProvider;
    return () => {
      lineup.destroy();
    };
  }, [ref, lineupRef, providerRef]);

  useEffect(() => {
    // create a new provider with the real data

    // compute tags
    const tags = Array.from(new Set(rows.map((row) => row.tags).flat()))
      .sort()
      .map((tag) => ({ name: tag, color: 'lightgray' }));

    const sizeScale = schemeBlues[6];
    const sizes: Partial<ICategory>[] = [
      {
        name: '1-10',
        value: 0.0001,
        color: sizeScale[0],
      },
      {
        name: '10-50',
        value: 0.001,
        color: sizeScale[1],
      },
      {
        name: '50-250',
        value: 0.005,
        color: sizeScale[2],
      },
      {
        name: '250-1,000',
        value: 0.025,
        color: sizeScale[3],
      },
      {
        name: '1,000-10,000',
        value: 0.1,
        color: sizeScale[4],
      },
      {
        name: '10,000+',
        value: 1,
        color: sizeScale[5],
      },
    ];

    const b = builder(rows);

    // use my set column implementation
    b.registerColumnType('set', CustomSetColumn);

    b
      // .singleSelection()
      .column(buildColumn('link', 'logoURL').width(50).label('Logo').renderer('image', 'image', 'image'))
      .column(buildColumn('link', 'name').custom('pattern', `/study/${studyId}/company/$\{escapedValue}`).label('Name').renderer('link', 'link', 'string'))
      .column(buildCategoricalColumn('tags', tags).asSet().width(200).label('Tags'))
      .column(buildDateColumn('foundedYear').format('%Y').label('Year Founded'))
      .column(buildCategoricalColumn('size', sizes).width(100).label('Size'))
      .column(buildStringColumn('revenue').width(100).label('Revenue'))
      .column(buildStringColumn('hqCountry').label('HQ Located'))
      .column(buildCategoricalColumn('cluster').label('Cluster'));

    b.ranking(buildRanking().selection().aggregate().sortBy('tags', false).column('*'));

    // .column(
    //   buildActionsColumn()
    //     .action({
    //       name: 'Test',
    //       className: 'action-test',
    //       action: (row) => {
    //         console.log(row);
    //       },
    //     })
    //     .groupAction({
    //       name: 'Test',
    //       className: 'action-test',
    //       action: (row) => {
    //         console.log(row);
    //       },
    //     })
    // );
    // b.aggregationStrategy('group+top+item');
    // b.showTopN(3);
    // build a ranking
    // b.ranking(buildRanking().selection().aggregate().group().groupBy('tags').column('*'));

    const data = b.buildData();
    // increase group width
    data.getFirstRanking().children[2].setWidth(200);
    providerRef.current = data;
    lineupRef.current!.setDataProvider(data);

    // Add custom CSS to the table scroll if in demo mode
    if (process.env.REACT_APP_IS_DEMO === 'true') {
      const lineUpScrollDiv = document.getElementsByClassName('le-body') as HTMLCollectionOf<HTMLElement>;
      lineUpScrollDiv[0].style.overflowY = 'hidden';
    }
  }, [lineupRef, providerRef, rows, studyId]);

  return (
    <>
      <div ref={ref} style={style} className={classNames('lineup-widget', className)} />
      {process.env.REACT_APP_IS_DEMO === 'true' && <DemoBanner position="sticky" />}
    </>
  );
};

export default LineUpWidget;
