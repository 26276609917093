import React from 'react';
import { Text, Label, Tag } from 'react-konva';
import { TagConfig } from 'konva/types/shapes/Label';
import { TextConfig } from 'konva/types/shapes/Text';

const tagProps: TagConfig = {
  fill: 'white',
  pointerDirection: 'up',
  pointerWidth: 10,
  pointerHeight: 10,
  lineJoin: 'round',
};

interface Props {
  text: string;
  pos: any;
}

const BubbleTooltip: React.FC<Props> = ({ text, pos }) => {
  const textProps: TextConfig = {
    text: text,
    fontFamily: 'Arial',
    fontSize: 20,
    padding: 5,
    textFill: 'white',
    fill: 'black',
    alpha: 0.75,
    visible: !!pos,
  };

  return (
    <Label x={pos ? pos.x : 0} y={pos ? pos.y + 15 : 0}>
      <Tag {...tagProps} />
      <Text {...textProps} />
    </Label>
  );
};
export default BubbleTooltip;
