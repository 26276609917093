import { getExtractedStudiesFromBucket, getExtractedStudyFromBucket } from './api';
import { CurrentUser, Study, StudyDetails } from '../Models/types';
import { mapCompanyObjectWithJSON } from './objectMapper';

const useStudyAPI = (currentUser: CurrentUser) => {
  const getAllStudies = async (): Promise<Study[]> => {
    const studies: Study[] = [];
    try {
      const studiesResult = await getExtractedStudiesFromBucket(currentUser.studyGroupName);
      studiesResult.forEach((result) => {
        const newStudy: Study = {
          id: result.id,
          name: result.name,
          subtitle: result.subtitle,
          dateCreated: result.date_created,
          companyCount: result.company_count,
          headerImageURL: result.header_image_url,
          description: result.description,
          latestCompanyOutput: result.latest_company_output,
        };
        studies.push(newStudy);
      });
      return studies;
    } catch (e) {
      console.log(e);
      return [];
    }
  };

  const getStudyWithId = async (id: string): Promise<Study | undefined> => {
    try {
      const studiesResult = await getExtractedStudiesFromBucket(currentUser.studyGroupName);
      const result = studiesResult.find((study) => study.id === parseInt(id));
      if (result) {
        return {
          id: result.id,
          name: result.name,
          subtitle: result.subtitle,
          dateCreated: result.date_created,
          companyCount: result.company_count,
          headerImageURL: result.header_image_url,
          description: result.description,
          latestCompanyOutput: result.latest_company_output,
        };
      } else {
        return undefined;
      }
    } catch (e) {
      console.log(e);
      return undefined;
    }
  };

  const getStudyDetails = async (url): Promise<StudyDetails | undefined> => {
    try {
      const studyMetadataResult = await getExtractedStudyFromBucket(url);
      const listOfCompanies = mapCompanyObjectWithJSON(studyMetadataResult.versions[0].elements);
      return {
        clusteredNGrams: studyMetadataResult.clustered_ngrams || [],
        referenceArchitecture: studyMetadataResult.reference_architecture.children.length ? studyMetadataResult.reference_architecture : undefined,
        companies: listOfCompanies,
      };
    } catch (e) {
      console.log(e);
    }
    return undefined;
  };

  return {
    getAllStudies,
    getStudyWithId,
    getStudyDetails,
  };
};

export default useStudyAPI;
