import React, { useEffect, useRef, useContext } from 'react';
import { FoamTree } from '@carrotsearch/foamtree';
import { FILTER_NAME } from '../../Models/types';
import { FilterContext } from '../../Contexts/FilterProvider';
import useAnalytics, { ANALYTICS_ACTION, ANALYTICS_CATEGORY } from '../../Hooks/useAnalytics';

interface Group {
  __id: number;
  label: string;
  selected: boolean;
}

interface GroupEvent {
  __id?: number;
  group?: {
    __id: number;
    groups: Group[];
    label: string;
    selected: boolean;
  };
}

interface Props {
  width: number;
  data: any[];
}

const FoamTreeWidget: React.FC<Props> = ({ width, data }) => {
  const { trackEvent } = useAnalytics();
  const { setFilterValuesWithFilterId, getFilterValuesWithFilterId } = useContext(FilterContext);
  const foamTreeRef = useRef<FoamTree>(undefined);

  function onGroupClick(event) {
    event.preventDefault();
    // Function which handles the left-click action.
    // You navigate around using left click, and double click to zoom in on a group.
    // If you left click on a group with NO groups, then it will filter on that ngram.
    const newGroupEvent: GroupEvent = {
      __id: event.group && event.group.__id,
      group: event.group,
    };
    // User clicked in the gutter, outside a group.
    if (newGroupEvent.group === null) return;
    if (newGroupEvent.group?.groups === undefined) {
      // Check if the group is being selected or deselected
      const selectedGroupIds = foamTreeRef.current.get('selection').groups.map((selectedGroup) => selectedGroup.__id);
      let isSelecting = true;
      if (selectedGroupIds.includes(newGroupEvent.group?.__id)) {
        isSelecting = false;
      }
      foamTreeRef.current.set('selection', {
        groups: newGroupEvent.group,
        selected: isSelecting,
      });
      trackEvent(ANALYTICS_CATEGORY.STUDY, `FoamTree: ${newGroupEvent.group?.label!}`, ANALYTICS_ACTION.SET);
      setFilterValuesWithFilterId(FILTER_NAME.FOAMTREE, [newGroupEvent.group?.label!]);
    }
  }

  // This effect is needed to update foamTree with values coming from the filter
  useEffect(() => {
    const activeNGramFilters = getFilterValuesWithFilterId(FILTER_NAME.FOAMTREE);
    if (foamTreeRef.current && !activeNGramFilters.length) {
      foamTreeRef.current.set('selection', { all: true, selected: false });
    }
  }, [getFilterValuesWithFilterId(FILTER_NAME.FOAMTREE)]);

  useEffect(() => {
    foamTreeRef.current = new FoamTree({
      id: 'visualization',
      relaxationVisible: true,
      dataObject: {
        groups: data,
      },
      onGroupClick,
    });
  }, []);

  useEffect(() => {
    if (!foamTreeRef.current) return;
    foamTreeRef.current!.resize();
  }, [width]);

  return width ? <div id="visualization" style={{ width: `${width}px`, height: '300px' }} /> : null;
};

export default FoamTreeWidget;
