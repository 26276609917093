import React from 'react';

import moment from 'moment';
import './event-tile.scss';
import { NewsEvent } from '../../Models/types';
import { Tag, StructuredListWrapper, StructuredListHead, StructuredListRow, StructuredListCell, StructuredListBody, Accordion, AccordionItem } from 'carbon-components-react';
import { Fire16, Star16 } from '@carbon/icons-react';
import EventArticle from './EventArticle';
import SourceIcon from '../SourceIcon/SourceIcon';
import { categoryColors } from '../../Helpers/colors';

interface Props {
  event: NewsEvent;
  handleGetArticlesForNewsEvent: (event: NewsEvent) => void;
}

const EventListItem: React.FC<Props> = ({ event, handleGetArticlesForNewsEvent }) => {
  return (
    <>
      <Accordion align="start" className="event-tile">
        <div className="actions-bar">
          <h1>{event.title}</h1>
        </div>
        <AccordionItem
          onHeadingClick={() => !event.articles.length && handleGetArticlesForNewsEvent(event)}
          title={
            <div>
              <small>{event.description}</small>
              <h6 className="centered">
                {`${moment(event.startDate).format('MM/DD/YYYY')} - ${moment(event.endDate).format('MM/DD/YYYY')}`}
                <div className="padded">
                  <Fire16 style={{ fill: event.impact <= 0 ? '#457b9d' : '#e63946' }} />
                  {event.impact.toFixed(2)}
                </div>
                <div className="padded">
                  <Star16 /> {event.starRating}
                </div>
                <div className="padded">
                  <SourceIcon source={event.source} />
                </div>
                <div className="countries" style={{ marginLeft: '10px' }}>
                  {event.countries.join(', ')}
                </div>
              </h6>
              {event.category && (
                <Tag
                  title="Category"
                  style={{
                    background: categoryColors[event.category],
                    color: 'white',
                  }}
                >
                  {event.category}
                </Tag>
              )}
              <div className="companies">{event.companies.join(', ')}</div>
            </div>
          }
        >
          <StructuredListWrapper ariaLabel="Structured list">
            <StructuredListHead>
              <StructuredListRow head tabIndex={0}>
                <StructuredListCell head>Articles ({event.articles?.length})</StructuredListCell>
                <StructuredListCell head />
              </StructuredListRow>
            </StructuredListHead>
            <StructuredListBody>
              {event.articles && event.articles.length > 0 && event.articles.map((article) => <EventArticle article={article} />)}
              {event.articles?.length === 0 && <StructuredListCell>This event does not have any articles associated with it.</StructuredListCell>}
            </StructuredListBody>
          </StructuredListWrapper>
        </AccordionItem>
      </Accordion>
    </>
  );
};

export default EventListItem;
