import React, { Dispatch, useCallback, useContext, useEffect, useState } from 'react';
import { CompanyMetadata, NewsEvent, RefArchitecture, Study } from '../Models/types';
import { useParams } from 'react-router-dom';
import { ClusteredNGram, StudyReferenceArchitecture } from '../Models/dataTypes';
import { UserContext } from './UserProvider';
import useStudyAPI from '../Data/useStudyAPI';
import useNewsAPI from '../Data/useNewsAPI';
import useTagsAPI from '../Data/useTagsAPI';
import useCommentsAPI from '../Data/useCommentsAPI';
import useFavouritesAPI from '../Data/useFavouritesAPI';
import useAnalytics, { ANALYTICS_ACTION, ANALYTICS_CATEGORY } from '../Hooks/useAnalytics';

type ContextProps = {
  isLoading: boolean;
  setIsLoading: Dispatch<React.SetStateAction<boolean>>;
  selectedStudy?: Study;
  rootReferenceArchitectureId?: number;
  studyCompanies: CompanyMetadata[];
  filteredStudyCompanies: CompanyMetadata[];
  getEvents: Function;
  events: NewsEvent[];
  getArticlesWithIdForNewsEvent: (event: NewsEvent) => void;
  isLoadingEvents: boolean;
  updateCompaniesWithFilteredCompanies: (filteredStudyCompanies: CompanyMetadata[]) => void;
  referenceArchitecture?: RefArchitecture;
  clusteredNGrams: ClusteredNGram[];
  getCompanies: Function;
  toggleFavourite: (companyId: string) => void;
};

export const StudyContext = React.createContext<ContextProps>({
  isLoading: true,
  setIsLoading: () => {},
  selectedStudy: undefined,
  rootReferenceArchitectureId: undefined,
  studyCompanies: [],
  filteredStudyCompanies: [],
  referenceArchitecture: undefined,
  clusteredNGrams: [],
  getEvents: () => {},
  events: [],
  getArticlesWithIdForNewsEvent: () => {},
  isLoadingEvents: true,
  updateCompaniesWithFilteredCompanies: () => {},
  getCompanies: () => {},
  toggleFavourite: () => {},
});

const StudyProvider: React.FC = ({ children }) => {
  const { trackEvent } = useAnalytics();
  const { currentUser } = useContext(UserContext);
  let { studyId } = useParams<{ studyId: string }>();
  const { getStudyWithId, getStudyDetails } = useStudyAPI(currentUser!);
  const { getEventsForCompaniesWithIds, getArticlesWithArticleIds } = useNewsAPI();
  const { getAllAssignedTags } = useTagsAPI();
  const { getLatestComments } = useCommentsAPI();
  const { getFavourites, createFavourite, deleteFavourite } = useFavouritesAPI();
  const [selectedStudy, setSelectedStudy] = useState<Study | undefined>(undefined);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingEvents, setIsLoadingEvents] = useState(true);
  const [rootReferenceArchitectureId, setRootReferenceArchitectureId] = useState<number | undefined>(undefined);
  const [referenceArchitecture, setReferenceArchitecture] = useState<StudyReferenceArchitecture>();
  const [studyCompanies, setStudyCompanies] = useState<CompanyMetadata[]>([]);
  const [clusteredNGrams, setClusteredNGrams] = useState<ClusteredNGram[]>([]);
  const [filteredStudyCompanies, setFilteredStudyCompanies] = useState(studyCompanies);
  const [events, setEvents] = useState<NewsEvent[]>([]);

  function updateCompaniesWithFilteredCompanies(filteredCompanies: CompanyMetadata[]) {
    setFilteredStudyCompanies(filteredCompanies);
  }

  const setStudyAsSelectedWithId = useCallback(
    async (id: string) => {
      const studyResult = await getStudyWithId(id);
      setSelectedStudy(studyResult);
    },
    [getStudyWithId, setSelectedStudy]
  );

  function sortCompanies(companies: CompanyMetadata[]): CompanyMetadata[] {
    const companiesSortedByForestreetFavourite = companies.sort((a, b) => {
      if (a.isStarredByForestreet === b.isStarredByForestreet) return 0;
      if (a.isStarredByForestreet) return -1;
      if (b.isStarredByForestreet) return 1;
      return 0;
    });
    const companiesSortedByUserFavourite = companiesSortedByForestreetFavourite.sort((a, b) => {
      if (a.isFavourite === b.isFavourite) return 0;
      if (a.isFavourite) return -1;
      if (b.isFavourite) return 1;
      return 0;
    });
    return companiesSortedByUserFavourite;
  }

  const getCompanies = useCallback(async () => {
    setIsLoading(true);

    // First, grab the companies from the JSON file stored in S3
    const studyMetadataResult = await getStudyDetails(selectedStudy?.latestCompanyOutput);

    // Because we want to display data from two different sources (S3 and the MySQL DB), we need to augment the JSON data with the dynamic user data from the DB.
    // So, this function pulls user data from the MySQL database via the Forestreet APIs
    const mergedCompaniesWithUserData = await getUserDataAndMergeWithJSONExtractedCompanies(studyMetadataResult!.companies);

    // Sort companies by various different priorities
    const sortedCompanies = sortCompanies(mergedCompaniesWithUserData);

    // Set the states:
    setRootReferenceArchitectureId(studyMetadataResult?.referenceArchitecture?.id);
    setReferenceArchitecture(studyMetadataResult?.referenceArchitecture);
    setStudyCompanies(sortedCompanies);
    setClusteredNGrams(studyMetadataResult!.clusteredNGrams);
    setIsLoading(false);
  }, [selectedStudy, setRootReferenceArchitectureId, setStudyCompanies]);

  async function getEvents() {
    setIsLoadingEvents(true);
    const eventsResult = await getEventsForCompaniesWithIds(filteredStudyCompanies.map((company) => company.id));
    setEvents(eventsResult);
    setIsLoadingEvents(false);
  }

  async function getArticlesWithIdForNewsEvent(newsEvent: NewsEvent) {
    const articlesResult = await getArticlesWithArticleIds(newsEvent.articleIds);
    // Find the event to update its articles
    const copyOfNewsEvents = [...events];
    const foundEventToUpdate = copyOfNewsEvents.find((event) => event.id === newsEvent.id);
    if (foundEventToUpdate) foundEventToUpdate.articles = articlesResult;
    setEvents(copyOfNewsEvents);
  }

  async function getUserDataAndMergeWithJSONExtractedCompanies(studyCompanies: CompanyMetadata[]): Promise<CompanyMetadata[]> {
    const [assignedTags, latestComments, favourites] = await Promise.all([
      getAllAssignedTags(selectedStudy!.id, currentUser!.studyGroupId),
      getLatestComments(selectedStudy!.id, currentUser!.studyGroupId),
      getFavourites(selectedStudy!.id, currentUser!.studyGroupId),
    ]);
    const copyOfStudyCompanies = [...studyCompanies];
    copyOfStudyCompanies.forEach((studyCompany) => {
      // Assign the userTags to each company
      studyCompany.userTags = assignedTags
        .filter((assignedTag) => assignedTag.companyId === studyCompany.id)
        .map((tag) => {
          return {
            id: tag.companyTagId,
            label: tag.label,
            hexColor: tag.hexColor,
          };
        });
      studyCompany.latestComment = latestComments.find((latestComment) => latestComment.companyId === studyCompany.id);
      studyCompany.isFavourite = favourites.filter((favourite) => favourite.companyId === studyCompany.id).length > 0;
    });
    return copyOfStudyCompanies;
  }

  async function toggleFavourite(companyId) {
    const copyOfStudyCompanies = [...studyCompanies];
    const companyToFind = copyOfStudyCompanies.find((company) => company.id === companyId)!;
    const isCompanyFavouritedAlready = companyToFind.isFavourite;
    companyToFind.isFavourite = !companyToFind.isFavourite;
    setStudyCompanies(copyOfStudyCompanies);
    if (isCompanyFavouritedAlready) {
      trackEvent(ANALYTICS_CATEGORY.STUDY, `Unstar company: ${companyId}`, ANALYTICS_ACTION.UNSTAR);
      await deleteFavourite(companyId, selectedStudy!.id, currentUser!.studyGroupId);
    } else {
      trackEvent(ANALYTICS_CATEGORY.STUDY, `Star company: ${companyId}`, ANALYTICS_ACTION.STAR);
      await createFavourite(currentUser!.id, selectedStudy!.id, currentUser!.studyGroupId, companyId);
    }
  }

  useEffect(() => {
    if (!studyId) return;
    setStudyAsSelectedWithId(studyId);
  }, [studyId]);

  useEffect(() => {
    if (!selectedStudy) return;
    // The first function that's executed in the provider.
    // This grabs all the initially required data for a study (Study from JSON, Tags from DB, etc) and stores it in the various provider states.
    // This is necessary as we load data from different data sources: a JSON file in S3 and the MySQL database and therefore must keep track of their loading states.
    getCompanies();
  }, [selectedStudy]);

  return (
    <StudyContext.Provider
      value={{
        isLoading,
        setIsLoading,
        selectedStudy,
        rootReferenceArchitectureId,
        studyCompanies,
        filteredStudyCompanies,
        clusteredNGrams,
        referenceArchitecture: referenceArchitecture!,
        getEvents,
        getArticlesWithIdForNewsEvent,
        events,
        isLoadingEvents,
        updateCompaniesWithFilteredCompanies,
        getCompanies,
        toggleFavourite,
      }}
    >
      {children}
    </StudyContext.Provider>
  );
};

export default StudyProvider;
