import React, { useState, useMemo } from 'react';
import './table.scss';
import { TableContainer, DataTable, Table, TableHead, TableRow, TableExpandRow, TableExpandedRow, TableHeader, TableExpandHeader, TableBody, TableCell, TextInput } from 'carbon-components-react';
import { CompanyMetadata } from '../../Models/types';
import { CommentsCell, LinkCell, LogoCell, MomentumCell, TotalFundingAmountCell, UserTagsCell, WebsiteCell } from './CellTypes';
import useDebounce from '../../Hooks/useDebounce';
import ReactTooltip from 'react-tooltip';
import DemoBanner from '../Demo/DemoBanner';

interface Props {
  data: CompanyMetadata[];
}

const headers = [
  {
    key: 'logoURL',
    header: '',
  },
  {
    key: 'name',
    header: 'Name',
  },
  {
    key: 'overallMomentum',
    header: 'Momentum',
  },
  {
    key: 'id',
    header: 'Website',
  },
  {
    key: 'foundedYear',
    header: 'Year Founded',
  },
  {
    key: 'size',
    header: 'Size',
  },
  {
    key: 'professionalEmployeeEstimate',
    header: 'Professional Employee Est.',
  },
  {
    key: 'revenue',
    header: 'Revenue',
  },
  {
    key: 'fundingRounds',
    header: 'Funding Rounds',
  },
  {
    key: 'totalFundingAmount',
    header: 'Funding Amount',
  },
  {
    key: 'IPODate',
    header: 'IPO Date',
  },
  {
    key: 'hqCountry',
    header: 'HQ Located',
  },
  {
    key: 'cluster',
    header: 'Cluster',
  },
  {
    key: 'userTags',
    header: 'User Tags',
  },
  {
    key: 'latestComment',
    header: 'Comments',
  },
];

const TableMarketExplorer: React.FC<Props> = ({ data }) => {
  const [searchInputValue, setSearchInputValue] = useState('');
  const debouncedSearchInputValue = useDebounce(searchInputValue, 500);
  const filteredCompaniesByName = useMemo(() => filterCompaniesByName(debouncedSearchInputValue, data), [debouncedSearchInputValue, data]);

  function filterCompaniesByName(name, companies) {
    if (!name) return companies;
    // Lowercase search term and company name so the search is case-insensitive.
    return companies.filter((company) => company.name.toLowerCase().includes(name.toLowerCase()));
  }

  return (
    <div className="table-market-explorer">
      <TextInput labelText="" value={searchInputValue} id="searchInput" placeholder="Search company by name..." onChange={(e) => setSearchInputValue(e.target.value)} />
      <DataTable responsive isSortable rows={filteredCompaniesByName} headers={headers} size="short">
        {({ rows, headers, getTableProps, getHeaderProps, getRowProps }) => (
          <TableContainer>
            <Table {...getTableProps()}>
              <TableHead>
                <TableRow>
                  <TableExpandHeader />
                  {headers.map((header) => (
                    <TableHeader {...getHeaderProps({ header })}>{header.header}</TableHeader>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row) => {
                  return (
                    <React.Fragment key={`${row.id}:row`}>
                      <TableExpandRow {...getRowProps({ row })}>
                        {row.cells.map((cell) => {
                          if (cell.info.header === 'name') {
                            return <LinkCell key={cell.id} cell={cell} />;
                          }
                          if (cell.info.header === 'logoURL') {
                            return (
                              <LogoCell
                                key={cell.id}
                                cell={cell}
                                isStarredByForestreet={data.find((company) => company.id === row.id)?.isStarredByForestreet!}
                                isFavourite={data.find((company) => company.id === row.id)?.isFavourite!}
                                companyId={data.find((company) => company.id === row.id)?.id}
                              />
                            );
                          }
                          if (cell.info.header === 'overallMomentum') {
                            return <MomentumCell key={cell.id} cell={cell} />;
                          }
                          if (cell.info.header === 'id') {
                            return <WebsiteCell key={cell.id} cell={cell} />;
                          }
                          if (cell.info.header === 'totalFundingAmount') {
                            return <TotalFundingAmountCell key={cell.id} cell={cell} />;
                          }
                          if (cell.info.header === 'userTags') {
                            return (
                              <UserTagsCell key={cell.id} cell={cell} companyId={data.filter((company) => company.id === row.id).length > 0 && data.filter((company) => company.id === row.id)[0].id} />
                            );
                          }
                          if (cell.info.header === 'latestComment') {
                            return (
                              <CommentsCell key={cell.id} cell={cell} companyId={data.filter((company) => company.id === row.id).length > 0 && data.filter((company) => company.id === row.id)[0].id} />
                            );
                          }
                          return <TableCell key={cell.id}>{cell.value}</TableCell>;
                        })}
                      </TableExpandRow>
                      <TableExpandedRow colSpan={headers.length + 1}>
                        <strong>
                          <h6>Description</h6>
                        </strong>
                        <div>{data.filter((company) => company.id === row.id).length > 0 && data.filter((company) => company.id === row.id)[0].description}</div>
                      </TableExpandedRow>
                    </React.Fragment>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </DataTable>
      {data.length === 0 && <p>No companies match these filters.</p>}
      {process.env.REACT_APP_IS_DEMO === 'true' && <DemoBanner position="sticky" />}
      <ReactTooltip />
    </div>
  );
};

export default TableMarketExplorer;
