import React, { useContext, useState } from 'react';
import { Modal, TextInput } from 'carbon-components-react';
import { CirclePicker } from 'react-color';

import UserTag from '../UserTag/UserTag';
import { TagsContext } from '../../../Contexts/TagsProvider';
import LoadingSkeletonView from '../../LoadingSkeleton/LoadingSkeletonView';

interface Props {
  isOpen: boolean;
  handleClose: Function;
}

const CreateTagModal: React.FC<Props> = ({ isOpen, handleClose }) => {
  const { isCreatingTag } = useContext(TagsContext);
  const [tagLabel, setTagLabel] = useState('');
  const [color, setColor] = useState<string>('');

  function closeModal() {
    setTagLabel('');
    setColor('');
    handleClose();
  }

  async function createTag(callback) {
    await callback(tagLabel, color);
    closeModal();
  }

  return (
    <TagsContext.Consumer>
      {({ createNewTag }) => (
        <>
          <Modal
            open={isOpen}
            onRequestClose={closeModal}
            onBlur={closeModal}
            onSecondarySubmit={closeModal}
            onRequestSubmit={() => createTag(createNewTag)}
            size="sm"
            primaryButtonText="Create"
            secondaryButtonText="Cancel"
          >
            {isCreatingTag ? (
              <>
                <LoadingSkeletonView />
              </>
            ) : (
              <>
                <div className="bx--row">
                  <div className="bx--col-lg-16">
                    <p style={{ fontSize: '.8rem' }} className="bx--modal-content__text">
                      Create a new tag by entering a label and choosing a color.
                    </p>
                  </div>
                </div>
                <div className="bx--row">
                  <div className="bx--col-lg-16 margin-bottom-large">
                    <TextInput
                      value={tagLabel}
                      id="tagLabel"
                      labelText=""
                      onChange={(e) => setTagLabel(e.target.value)}
                      helperText="The text that will appear on the tag."
                      invalidText="A valid value is required"
                      placeholder="Tag label"
                    />
                  </div>
                  <div className="bx--col-lg-16 margin-bottom-large">
                    <CirclePicker width="100%" onChange={(e) => setColor(e.hex)} />
                  </div>
                  <div className="bx--col-lg-16">{tagLabel && <UserTag label={tagLabel} hexColor={color} />}</div>
                </div>
              </>
            )}
          </Modal>
        </>
      )}
    </TagsContext.Consumer>
  );
};

export default CreateTagModal;
