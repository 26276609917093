import { IGroupCellRenderer, IOrderedGroup, IRenderContext, ISetColumn, rendererClasses } from 'lineupjs';

export default class CustomUpSetCellRenderer extends rendererClasses.UpSetCellRenderer {
  createGroup(col: ISetColumn, context: IRenderContext): IGroupCellRenderer {
    const categories = col.categories;
    let templateRows = '';
    for (const cat of categories) {
      templateRows += `<div class="${'lu-upset-dot'}" title="${cat.label}"></div>`;
    }
    return {
      template: `<div>${templateRows}</div>`,
      update: (n: HTMLElement, group: IOrderedGroup) => {
        return context.tasks.groupCategoricalStats(col, group).then((r) => {
          if (typeof r === 'symbol') {
            return;
          }
          const groupCount = group.order.length;
          const hist = r.group.hist;

          Array.from(n.children).forEach((d, i) => {
            const v = hist[i];
            d.classList.toggle('lu-enabled', v.count > 0);
            d.classList.toggle('lu-upset-small', v.count > 0 && v.count < groupCount);
          });
        });
      },
    };
  }
}
