import React, { useContext, useEffect, useRef } from 'react';
import { Modal } from 'carbon-components-react';
import LoadingSkeletonView from '../../LoadingSkeleton/LoadingSkeletonView';
import { CommentsContext } from '../../../Contexts/CommentsProvider';
import CommentList from '../CommentsList/CommentList';
import NewCommentInput from '../CommentsList/NewCommentInput';

interface Props {
  isOpenWithCompanyId: string | undefined;
  handleClose: Function;
}

const CompanyCommentsModal: React.FC<Props> = ({ isOpenWithCompanyId, handleClose }) => {
  const { getCommentsForCompanyWithId, createNewComment } = useContext(CommentsContext);
  const modalBody = useRef<HTMLElement>(null);

  async function handleCreateComment(text: string) {
    await createNewComment(text);
  }

  function scrollToBottomOfCommentsListOnLoad() {
    const elementToScroll = document.getElementById(modalBody.current!['modalBodyId']);
    elementToScroll!.scrollTop = elementToScroll!.scrollHeight;
  }

  useEffect(() => {
    if (isOpenWithCompanyId) {
      getCommentsForCompanyWithId();
    }
  }, [isOpenWithCompanyId]);

  return (
    <CommentsContext.Consumer>
      {({ isLoadingComments, isCreatingComment, companyComments }) => (
        <Modal ref={modalBody} id="company-comments-modal" open={isOpenWithCompanyId !== undefined} onRequestClose={handleClose} onBlur={handleClose} size="sm" passiveModal>
          {isLoadingComments || isCreatingComment ? (
            <>
              <LoadingSkeletonView />
            </>
          ) : (
            <>
              <div className="bx--row">
                <div className="bx--col-lg-16">
                  <CommentList comments={companyComments} scrollToBottomOfList={scrollToBottomOfCommentsListOnLoad} />
                </div>
              </div>
              <div className="bx--row">
                <div className="bx--col-lg-16">
                  <NewCommentInput handleSave={handleCreateComment} />
                </div>
              </div>
            </>
          )}
        </Modal>
      )}
    </CommentsContext.Consumer>
  );
};

export default CompanyCommentsModal;
