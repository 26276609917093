/**
 * Index of Multidimensional Array
 * @param arr {!Array} - the input array
 * @param eventId {number} - the value to search
 * @return {number}
 */
export function getIndexOfEventId(arr, eventId) {
  for (let i = 0; i < arr.length; i++) {
    if (arr[i] === null) return 0;
    let index = arr[i].indexOf(eventId);
    if (index > -1) {
      return i;
    }
  }
}

export const dollarCurrencyFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 0,

  // These options are needed to round to whole numbers if that's what you want.
  //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
  //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
});
