import React, { useEffect } from 'react';
import './comment-list.scss';
import CommentListItem from './CommentListItem';
import { Comment } from '../../../Models/types';

interface Props {
  comments: Comment[];
  scrollToBottomOfList: Function;
}

const CommentList: React.FC<Props> = ({ comments, scrollToBottomOfList }) => {
  // When the comment list is populated with comments, trigger the scrollToBottomOfList
  // function in the parent component.
  useEffect(() => {
    if (!comments.length) return;
    scrollToBottomOfList();
  }, [comments]);

  return (
    <ul className="comment-list">
      {comments.map((comment) => (
        <CommentListItem text={comment.text} postedDate={comment.createdDate} userFirstName={comment.userFirstName} userLastName={comment.userLastName} userEmail={comment.email} />
      ))}
    </ul>
  );
};

export default CommentList;
