import React, { useContext, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { TableCell } from 'carbon-components-react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Launch16 } from '@carbon/icons-react';
import MomentumScore from '../MomentumScore/MomentumScore';
import { dollarCurrencyFormatter } from '../../Helpers/utils';
import Select from 'react-select';
import { TagsContext } from '../../Contexts/TagsProvider';
import UserTag from '../Tags/UserTag/UserTag';
import { CommentsContext } from '../../Contexts/CommentsProvider';
import FavouriteButton from '../FavouriteButton/FavouriteButton';

export const LinkCell = ({ cell }) => {
  let { studyId } = useParams<{ studyId: string }>();
  return (
    <TableCell key={cell.id}>
      <Link
        style={{ textDecoration: 'none' }}
        to={{
          pathname: `/study/${studyId}/company/${cell.value}`,
        }}
      >
        {cell.value}
      </Link>
    </TableCell>
  );
};

export const WebsiteCell = ({ cell }) => {
  return (
    <TableCell key={cell.id}>
      {cell.value}
      <Launch16 style={{ marginLeft: '5px', cursor: 'pointer' }} onClick={() => window.open(`https://${cell.value}`, '_blank')} />
    </TableCell>
  );
};

interface LogoCellProps {
  cell: any;
  companyId?: string;
  isFavourite: boolean;
  isStarredByForestreet: boolean;
}

export const LogoCell: React.FC<LogoCellProps> = ({ cell, companyId, isFavourite, isStarredByForestreet }) => {
  return (
    <TableCell key={cell.id}>
      <div className="logo-cell-inner">
        <span style={{ marginLeft: '5px' }}>
          <FavouriteButton companyId={companyId} isFavourite={isFavourite} isStarredByForestreet={isStarredByForestreet} />
        </span>
        <LazyLoadImage alt="" height={30} src={cell.value} width={30} className="table-market-explorer-image" placeholder={<div className="table-item__image-placeholder">...</div>} />
      </div>
    </TableCell>
  );
};

export const MomentumCell = ({ cell }) => {
  return (
    <TableCell key={cell.id}>
      <MomentumScore score={cell.value} width={30} height={30} size="small" />
    </TableCell>
  );
};

export const TotalFundingAmountCell = ({ cell }) => {
  if (cell.value === 'N/A') {
    return <TableCell key={cell.id} />;
  } else {
    return <TableCell key={cell.id}>{dollarCurrencyFormatter.format(cell.value)}</TableCell>;
  }
};

export const CompetitorScoreCell = ({ cell }) => {
  const competitorScoreRounded = Math.round(cell.value * 100);
  return (
    <div style={{ display: 'flex', alignItems: 'center', marginTop: '10px' }}>
      <div style={{ marginRight: '5px' }}>{competitorScoreRounded}</div>
      <progress value={competitorScoreRounded} max="100">
        {competitorScoreRounded}%
      </progress>
    </div>
  );
};

export const UserTagsCell = ({ cell, companyId }) => {
  const { availableTags, assignTag, unassignTag } = useContext(TagsContext);
  const [isEditing, setIsEditing] = useState(false);

  async function handleAssignTagToCompany(tagToAssign: { id: number; label: string }) {
    await assignTag(tagToAssign.id, companyId);
  }

  async function handleRemoveTagFromCompany(tagToUnassign: { id: number; label: string }) {
    await unassignTag(tagToUnassign.id, companyId);
  }

  return (
    <TableCell key={cell.id}>
      {isEditing ? (
        <div style={{ width: `300px` }}>
          <Select
            options={availableTags}
            getOptionLabel={(option) => option.label}
            getOptionValue={(option) => option.id}
            isClearable={false}
            value={cell.value ? cell.value : []}
            isMulti
            onBlur={() => setIsEditing(false)}
            onChange={(value, actionType) => {
              if (actionType.action === 'select-option') handleAssignTagToCompany(actionType.option);
              if (actionType.action === 'remove-value') handleRemoveTagFromCompany(actionType.removedValue);
            }}
          />
        </div>
      ) : (
        <div style={{ width: '200px' }} onClick={() => setIsEditing(true)}>
          {cell.value.length ? (
            cell.value?.map((userTag) => <UserTag key={`${cell.id}-user-tag:${userTag.label}`} label={userTag.label} hexColor={userTag.hexColor} />)
          ) : (
            <EmptyCellAction companyId={companyId} cell={cell} handleClick={() => setIsEditing(true)} label="Add a tag..." />
          )}
        </div>
      )}
    </TableCell>
  );
};

export const CommentsCell = ({ cell, companyId }) => {
  return (
    <CommentsContext.Consumer>
      {({ showCompanyCommentsModalWithCompanyId }) => (
        <TableCell style={{ border: 'none' }} key={cell.id}>
          {cell.value ? (
            <div onClick={() => showCompanyCommentsModalWithCompanyId(companyId)}>{cell.value.text.substring(0, 50)}...</div>
          ) : (
            <EmptyCellAction companyId={companyId} cell={cell} handleClick={showCompanyCommentsModalWithCompanyId} label="Add a comment..." />
          )}
        </TableCell>
      )}
    </CommentsContext.Consumer>
  );
};

export const EmptyCellAction = ({ cell, companyId, handleClick, label }) => {
  const [isHovering, setIsHovering] = useState(false);
  return (
    <div
      key={`${cell.id}-empty-cell:${label}`}
      onClick={() => handleClick(companyId)}
      onMouseOver={() => setIsHovering(true)}
      onMouseOut={() => setIsHovering(false)}
      style={{ border: 'none', padding: 0 }}
    >
      <div className="new-comment-empty">
        <div>{isHovering && <>{label}</>}</div>
      </div>
    </div>
  );
};
