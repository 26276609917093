import React from 'react';

import ForestreetIcon from '../../Assets/forestreet-icon.png';
import FactivaIcon from '../../Assets/factiva-icon.png';
import SNPIcon from '../../Assets/snp-icon.png';

import './source-icon.scss';

interface Props {
  source: string;
}

const SourceIcon: React.FC<Props> = ({ source }) => {
  const getImageForSource = (source: string) => {
    switch (source) {
      case 'forestreet':
        return ForestreetIcon;
      case 'factiva':
        return FactivaIcon;
      case 's&p':
        return SNPIcon;
    }
  };
  return (
    <img
      className="source-icon-small"
      src={getImageForSource(source)}
      alt="source"
    />
  );
};

export default SourceIcon;
