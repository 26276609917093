import { getEmployeeValue } from './data.helper';

export enum FilterOrder {
  ASC = 'ASC',
  DESC = 'DESC',
}

interface FilterItemProps {
  checked?: boolean;
  name: string;
  onChecked?: (value: boolean) => void;
  onHover?: () => boolean;
  value: number;
  initialValue: number;
  maxValue: number;
}

export interface FilterEmployeeOrderValue {
  value: number;
  name: string;
}

const FILTER_EMPLOYEES_ORDER = new Map<string, FilterEmployeeOrderValue>([
  ['1-10', { value: 0, name: '1-10' }],
  ['10-50', { value: 1, name: '10-50' }],
  ['50-250', { value: 2, name: '50-250' }],
  ['250-1,000', { value: 3, name: '250-1000' }],
  ['251-1,000', { value: 4, name: '251-1000' }],
  ['1,000-10,000', { value: 5, name: '1000-10000' }],
  ['10,000+', { value: 6, name: 'over 10000' }],
  ['TBD', { value: 7, name: 'TBD' }],
]);

export function getEmployeeFilterInitialState(data, filteredData): FilterItemProps[] {
  return getFiltersByMap(getEmployeeValue(data), getEmployeeValue(filteredData), sortEmployeeFilters);
}

function getFiltersByMap(valuesMap: Map<string, number>, filteredMap: Map<string, number>, sortFunction?: (first: FilterItemProps, second: FilterItemProps) => number): FilterItemProps[] {
  const maxValue = findMaxValue(Array.from(valuesMap.values()));

  const filters: FilterItemProps[] = [];
  for (const key of valuesMap.keys()) {
    filters.push({
      initialValue: valuesMap.get(key) || 0,
      value: filteredMap.get(key) || 0,
      name: key,
      maxValue,
    });
  }
  if (sortFunction) {
    return filters.sort(sortFunction);
  }

  return sortFilters(filters, FilterOrder.ASC);
}

function findMaxValue(array: number[]): number {
  return Math.max(...array);
}

// @ts-ignore
function sortEmployeeFilters(first: FilterItemProps, second: FilterItemProps): number {
  const firstFilter = FILTER_EMPLOYEES_ORDER.get(first.name);
  const secondFilter = FILTER_EMPLOYEES_ORDER.get(second.name);
  if (firstFilter && secondFilter) {
    // @ts-ignore
    return FILTER_EMPLOYEES_ORDER.get(first.name).value - FILTER_EMPLOYEES_ORDER.get(second.name).value || 0;
  }
}

function sortFilters(items: FilterItemProps[], order: FilterOrder): FilterItemProps[] {
  if (order === FilterOrder.ASC) {
    return items.sort((first: FilterItemProps, second: FilterItemProps) => second.initialValue - first.initialValue);
  } else {
    return items.sort((first: FilterItemProps, second: FilterItemProps) => first.initialValue - second.initialValue);
  }
}
